<template>
    <Header :initType="'type1'"/>
    <div class="wrap menu bot">
        <h6>메뉴</h6>
        <div class="container">
            <ul>
                <li class="flex cursor" @click="router.push('/Alarm')">
                    <img src="@/assets/images/alarm-menu.svg" alt="이미지">
                    <p>알림</p>
                    <img src="@/assets/images/arrow-right.svg" alt="arrow">
                </li>
                <li class="flex cursor" @click="router.push('/Notice')">
                    <img src="@/assets/images/notice-menu.svg" alt="이미지">
                    <p>공지사항</p>
                    <img src="@/assets/images/arrow-right.svg" alt="arrow">
                </li>
            </ul>
        </div>
    </div>
    <Footer />
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits,axios,store, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

onMounted(()=>{
    store.setBeforePage(route.path);
})

</script>
