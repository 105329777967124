<template>
    <Header :initType="'type1'" :initTitle="state.symbol + ' ' + 'Wallet'" />
    <div class="wrap detail " style="padding: 100px 0 0;">
        <div class="cover">
            <div class="chartBox" v-if="state.symbol !== 'EPIC'">
                <canvas ref="chartCanvas"></canvas>
            </div>
            <!-- <h6 class="title">{{ state.symbol }} Total Balance</h6> -->
            <div class="canvas-box">
                <div class="container">
                    <div class="flex">
                        <div class="krw">{{ state.symbol }}</div>
                        <div class="money">
                            <p>{{formatPrice(state.totalPoint)}}</p>
                        </div>
                    </div>
                    <p class="cyber">{{formatPrice(state.totalPrice)}}<span>KRW</span></p>
                </div>
                <p class="tool-tip">{{formatPrice(state.tokenPrice)}}원</p>
            </div>
        </div>
        <!-- <div class="asset flex container">
            <div class="box">
                <p class="box-name">보유 자산</p>
                <p class="symbol-price">{{state.totalPoint}} <span>{{state.symbol}}</span></p>
                <p class="won">{{formatPrice(state.totalPrice)}} KRW</p>
            </div>
            <div class="box">
                <p class="box-name">선택 상품 (출금)</p>
                <p class="symbol-price">{{state.selectedPurchase.amountTotal ? state.selectedPurchase.amountTotal:'0'}} <span>{{state.symbol}}</span></p>
                <p class="won">{{state.selectedPurchase.amountTotal ? formatPrice(state.selectedPurchase.amountTotal * state.tokenPrice) :"0"}} KRW</p>
            </div>
        </div> -->
        <div class="transaction">
            <div class="flex">
                <h6 class="title" :style="{color:state.tabIdx === 0 ? '#fff':''}" @click="tabChange(0)">전송 가능 상품</h6>
                <h6 class="title"  :style="{color:state.tabIdx === 1 ? '#fff':''}" @click="tabChange(1)">전송 내역</h6>
            </div>
            <ul class="container" style="background-color: transparent;" v-if="state.tabIdx === 0">
                <li class="product" v-for="(item,idx) in state.purchaseList" :key="idx" :class="{'selected': item === state.selectedPurchase}" @click="selectPurchase(item)">
                    <div class="flex">
                        <div class="flex top">
                            <img src="@/assets/images/EPUSDT.png" alt="" v-if="item.symbol === 'EPIC'">
                            <img src="@/assets/images/ETHUSDT.png" alt="" v-if="item.symbol === 'ETH'">
                        </div>
                        <div class="bottom">
                            <!-- <div class="flex" :class="[{'wait':item.state === 0 || item.state === 2}]">
                                <p>현재 상태</p>
                  7              <p v-if="item.state === 0">관리자 승인 대기중</p>
                                <p v-else-if="item.state === 1" style="color:yellow">관리자 승인</p>
                                <p v-else-if="item.state === 2">관리자 거절</p>
                                <p v-else-if="item.state === 3" style="color:yellowgreen">구매 완료</p>
                                <p v-else-if="item.state === 8" style="color:yellow">사인 필요</p>
                                <p v-else> --- </p>
                            </div> -->
                            <div class="flex">
                                <p>전송 가능 수량</p>
                                <p>{{item.amountTotal}} {{ item.symbol }}</p>
                            </div>
                            <div class="flex">
                                <p>입금 금액</p>
                                <p>{{ formatPrice(item.requestKrw) }} KRW</p>
                            </div>
                            <div class="flex">
                                <p>신청일:</p>
                                <p>{{item.createDate}}</p>
                            </div>
                            <div class="flex">
                                <p>승인일:</p>
                                <p>{{item.acceptDate ? item.acceptDate : '-'}}</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="container" style="background-color: transparent;" v-else>
                <li class="product" v-for="(item,idx) in state.userLog" :key="idx">
                    <div class="flex">
                        <!-- <div class="flex top">
                            <img src="@/assets/images/EPUSDT.png" alt="">
                        </div> -->
                        <div class="bottom">
                            <div class="flex">
                                <p>타입</p>
                                <p v-if="item.type === 21">[구매] {{ formatPrice(item.data3) }} 원</p>
                                <p v-else-if="item.type === 22">[출금] {{ item.data3 }}</p>
                                <p v-else-if="item.type === 23">[입금] {{ item.data3 }}</p>
                            </div>
                            <div class="flex">
                                <p>수량</p>
                                <p>{{item.amount}} {{ item.symbol }}</p>
                            </div>
                            <div class="flex">
                                <p>일자</p>
                                <p>{{item.createDate}}</p>
                            </div>
                            <div class="flex">
                                <p>주소</p>
                                <p @click="item.data2 ?  goAddress(item.data2):''" style="cursor: pointer; text-decoration: underline;">{{item.data2 ? item.data2.substring(0,16):''}}...</p>
                            </div>
                            <div class="flex" v-if="item.type === 21">
                                <p>Hash</p>
                                <p @click="item.data4 ?  goHash(item.data4):''" style="cursor: pointer; text-decoration: underline;">{{ item.data4 ? item.data4.substring(0,16):''}}...</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <vue-awesome-paginate
                v-if="state.tabIdx === 1"
                :total-items="state.tabIdx === 0 ? state.tab0PageData.totalRecordSize:state.tab1PageData.totalRecordSize"
                :items-per-page="state.tabIdx === 0 ? state.tab0PageData.pageSize:state.tab1PageData.pageSize"
                :max-pages-shown="5"
                v-model="state.currentPage"
                @click="onClickHandler(state.currentPage)"
            />
        </div>

        
        <!-- <div class="buttons flex" style="margin-top: 20px;">
            <div class="plus">
                <button @click=" store.setVisibleDialog({ title: '입금은 지원되지 않습니다.', message: '', okCancel: false, callback: () => { } })">
                    <img src="@/assets/images/plus.png" alt="" />
                </button>
                <p>받기</p>
            </div>
            <div>
                <button @click="checkWithdraw">
                    <img src="@/assets/images/minus.png" alt="minus">
                </button>
                <p>보내기</p>
            </div>
        </div> -->
    </div>
    <Footer />
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
import { Chart, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend,LineController,PointElement} from 'chart.js';
const { route, router, defineEmits,axios,store,onMounted, defineProps,  reactive, ref,watch,nextTick} = useBaseComponent();

Chart.register(CategoryScale, LinearScale, LineController, LineElement, Title, Tooltip, Legend,PointElement);


const chartCanvas = ref<HTMLCanvasElement | null>(null);

let state = reactive({
    symbol: route.query.symbol,
    tabIdx:0,
    purchaseList:[] as any,
    userLog:[] as any,
    selectedPurchase:'' as any,
    tab0PageData:{ } as any,
    tab1PageData:{ } as any,
    currentPage:1, //pageNo
    
    tokenInfo:[]as any,
    tokenPrice:0 as any,

    totalPrice:'',
    totalPoint:'',
    totalAmount:'',
    totalWalletInfo:[] as any,

    chartData:[] as any,
})

onMounted(() => {
    state.symbol = route.query.symbol;
    store.setWithdrawData('');
    getPurchaseOwner();
    getUserToken();
    getUserLog();
    getUserTokenInfo();
    store.setBeforePage(route.path);
    nextTick(()=> {

        getTokenChart();
    })
})

const getPurchaseOwner = async ()=> {
    let res = await axios.axiosMethods.purchaseCoinOwner({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:999,
        symbol:state.symbol,
    })
    // console.log(res)
    if(res.returnCode === 0){
        state.purchaseList = res.list;
        state.tab0PageData = res.page;
    } else {
        //
    }
}

const getUserLog = async () => {
    let res = await axios.axiosMethods.userLog({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:5,
        symbol:state.symbol,
    })
    if(res.returnCode === 0) {
        state.userLog = res.list;
        state.tab1PageData = res.page;
    }
}

const selectPurchase = (item:any) => {
    state.selectedPurchase = item;
    store.setWithdrawData(item);
    // 바로 전송으로
    router.push('/Withdraw')
}

const tabChange = (no:any) => {
    state.tabIdx = no;
    state.currentPage = 1;
}

const checkWithdraw = () => {
    if(store.TradeState.withdrawData) {
        router.push('/Withdraw')
    } else {
        store.setVisibleDialog({ title: '아래 전송할 상품을 선택하여주세요.', message: '', okCancel: false, callback: () => { } })
    }
}

const onClickHandler = (pageNo:number) => {
    state.currentPage = pageNo;
    if(state.tabIdx === 0) {
        getPurchaseOwner();
    } else {
        getUserLog();
    }

}

const goHash = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/tx/`+add)
}

const goAddress = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/address/`+add)
}

const getUserTokenInfo = async() => {
    let res =await axios.axiosMethods.tokenInfo({
        symbol:state.symbol,
    })
    if(res.returnCode === 0) {
        state.tokenInfo = res.data;

        state.tokenPrice = parseFloat(res.data.reduce((sum: any, item: any) => {
            return sum + (parseFloat(item.price));
        }, 0)).toFixed(0);
    }
}

const getUserToken = async() => {
    let res =await axios.axiosMethods.userToken({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        symbol:state.symbol,
    })
    if(res.returnCode === 0) {
        state.totalWalletInfo = res.list;
        state.totalPrice = parseFloat(res.list.reduce((sum: any, item: any) => {
            return sum + (parseFloat(item.price) * item.point);
        }, 0)).toFixed(0);
        state.totalWalletInfo.forEach((v:any) => {
            if(state.totalPrice && Number(state.totalPrice) > 0) {
                const itemTotal = parseFloat(v.price) * v.point;
                const percentage = (itemTotal / parseFloat(state.totalPrice)) * 100;
                // console.log(state.totalPrice);
                state.totalPoint = v.point;
                if(isNaN(v.percentage)){
                    v.percentage = percentage.toFixed(2); 
                } else {
                    v.percentage = '0';
                }
                v.requestKrw = itemTotal;
            }
            else {
                v.percentage = '0';
            }
        });
    }
}

const getTokenChart = async () => {
    let res = await axios.axiosMethods.tokenChart({
        symbol: state.symbol,
    });

    if (res.returnCode === 0) {
        let data = res.data[0].chart;
        if( typeof data !== 'object'){
            data= JSON.parse(res.data[0].chart);
            state.chartData = data.c
        }
    }
};


const createChart = () => {
  if (chartCanvas.value) {
    new Chart(chartCanvas.value, {
      type: 'line',
      data: {
        labels: state.chartData.map((_, index:any) => index.toString()), // X축 레이블 예시
        datasets: [
          {
            label: '',
            
            data: state.chartData, // Y축 데이터
            fill: false,
            borderColor: 'rgba(250,45,0, 1)',
            tension: 0.1,
            pointRadius: 0, // 포인트 제거
            pointHoverRadius: 0,
          },
        ],
      },
      options: {
        plugins:{
            legend:{
                display:false,
            }
        },
        responsive: true,  // 반응형 차트
        maintainAspectRatio: false,  // 비율 고정하지 않음
        scales: {
          x: {
            type: 'category', // CategoryScale 사용
            ticks: {
                display:false,
              autoSkip: true,
            },
            grid: {
              color: 'rgba(200, 200, 200, 0.0)', // X축 grid 선 색상 설정
            },
          },
          y: {
            type: 'linear', // LinearScale 사용
            beginAtZero: false,
            ticks: {
              font: {
                size: 11, // Y축 폰트 크기 설정
              },
            },
            grid: {
              color: 'rgba(200, 200, 200, 0.2)', // X축 grid 선 색상 설정
            },
          },
        },
      },
    });
  }
};

watch(() => state.chartData, () => {
  if (state.chartData.length > 0) {
    createChart();
  }
},{deep:true});


const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}


</script>
