<template>
    <Header :initType="'type2'" :initTitle="'이용약관 동의'" />
    <div class="wrap login terms">
        <div class="login-center">
            <div class="title">
                <p><span>이용약관</span>에<br> 동의해주세요. </p>
            </div>
            <div class="agree">
                <div class="accordion-item">
                    <h2 class="accordion-header flex">
                        <input class="checkbox form-check-input" type="checkbox" id="defaultCheck" v-model="state.allCheck"  @change="checkAll">
                        <label class="form-check-label" for="defaultCheck">이용약관에 모두 동의합니다.</label>
                    </h2>
                    <div class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ul>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck0" v-model="state.check0">
                                    <div class="flex">
                                        <label class="form-check-label cursor" for="defaultCheck0">만 19세 이상입니다. <span>(필수)</span></label><!---->
                                    </div>
                                </li>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck1" v-model="state.check1">
                                    <div class="flex">
                                        <label class="form-check-label cursor" for="defaultCheck1">국제자금세탁방지기구에서 발표한 국제기준 미이행/비협조 국가 또는 지역의 국민, 시민권자, 영주권자,
                                            거주자가 아니며, 해당 국가/지역 체류 중에는 서비스를 이용하지 않을 것을 보증합니다.
                                            <span>(필수)</span>
                                        </label><!---->
                                    </div>
                                </li>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck2" v-model="state.check2">
                                    <div class="flex" @click="router.push('/Service')">
                                        <label class="form-check-label cursor" for="defaultCheck2">서비스 이용약관 <span>(필수)</span>
                                        </label>
                                        <img src="@/assets/images/arrow-right.svg" alt="arrow">
                                    </div>
                                </li>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck3" v-model="state.check3">
                                    <div class="flex" @click="router.push('/Privacy')">
                                        <label class="form-check-label cursor" for="defaultCheck3">개인정보 수집/이용 동의 <span>(필수)</span>
                                        </label>
                                        <img src="@/assets/images/arrow-right.svg" alt="arrow">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="login-btn" :disabled="!state.allCheck" :class="{'on':state.allCheck}" @click="nextStep">다음</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive,watch } = useBaseComponent();


let state = reactive({
    allCheck:false,
    check0:false,
    check1:false,
    check2:false,
    check3:false,
})

watch(() => [
    state.check0, state.check1, state.check2, state.check3
], () => {
    state.allCheck = state.check0 && state.check1 && state.check2 && state.check3;
})

const checkAll = () => {
    if (state.allCheck) {
        state.check0 = true;
        state.check1 = true;
        state.check2 = true;
        state.check3 = true;
    } else {
        state.check0 = false;
        state.check1 = false;
        state.check2 = false;
        state.check3 = false;
    }
};

const nextStep = () => {
    router.push({
        path:'/CreateId',
        query:{pass:'check'}
    })
}

</script>
