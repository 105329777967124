<template>
    <div class="footer">
        <div class="row">
            <div class="col" @click="router.push('/')">
                <img class="icon-trade" :class="{'active':route.name === 'Home'}" src="@/assets/images/trade.svg" alt="">
                <p :class="{'active':route.name === 'Home'}">마켓</p>
            </div>
            <!-- <div class="col" @click="router.push('/Wallet')"> -->
            <div class="col" @click="router.push('/Detail?symbol=EPIC')">
                <img class="icon-wallet" :class="{'active':route.name === 'Detail'}" src="@/assets/images/wallet.svg" alt="">
                <p :class="{'active':route.name === 'Detail'}">지갑</p>
            </div>
            <div class="col" @click="router.push('/Trade')">
                <img class="icon-footer-logo" :class="{'active':route.name === 'Trade'}" src="@/assets/images/footer-logo.svg" alt="">
                <p :class="{'active':route.name === 'Trade'}">토큰구매</p>
            </div>
            <div class="col" @click="router.push('/My')">
                <img class="icon-my" :class="{'active':route.name === 'My'}" src="@/assets/images/my.svg" alt="">
                <p :class="{'active':route.name === 'My'}">마이페이지</p>
            </div>
            <div class="col" @click="router.push('/Menu')">
                <img class="icon-menu" :class="{'active':route.name === 'Menu'}" src="@/assets/images/menu.svg" alt="">
                <p :class="{'active':route.name === 'Menu'}">메뉴</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({

})
</script>
