import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrap login info" }
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = {
  class: "error",
  style: {"margin":"4px 0px 30px"}
}
const _hoisted_4 = { class: "error" }
const _hoisted_5 = { class: "error" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["disabled"]

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePw',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive,axios,store } = useBaseComponent();


let state = reactive({
    beforePwInput:''as any,
    pwInput:'' as any,
    pwCheckInput:'' as any,
    pwPass:false,
    pwError: ''as any,
    pwErrorBefore:'' as any,
})

const changePwTicket = async() => {
    let res = await axios.axiosMethods.changePwTicket({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        priorPassword:state.beforePwInput,
        newPassword:state.pwCheckInput,
    })
    if(res.returnCode === 0) {
        store.setVisibleDialog({ title: '변경되었습니다.', message: '', okCancel: false, callback: () => { router.go(-1)} })
    } else {
        store.setVisibleDialog({ title: '실패하였습니다.', message: res.description, okCancel: false, callback: () => { } })
    }
}

const pwCheck = () => {
    const pw = state.pwInput;
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^*])[a-zA-Z\d~!@#$%^*]{10,16}$/;
    if (pwPattern.test(pw)) {
        state.pwError = '';
    } else {
        state.pwError ='비밀번호는 10~16자리로, 영문자, 숫자, 특수문자를 모두 포함해야 합니다.';
    }
}

const newCheck = () => {
    const pw = state.beforePwInput;
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^*])[a-zA-Z\d~!@#$%^*]{10,16}$/;
    if (pwPattern.test(pw)) {
        state.pwErrorBefore = '';
    } else {
        state.pwErrorBefore ='비밀번호는 10~16자리로, 영문자, 숫자, 특수문자를 모두 포함해야 합니다.';
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '비밀번호 변경'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createElementVNode("p", null, "비밀번호", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: "10~16자리 입력",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).beforePwInput) = $event)),
            onInput: newCheck
          }, null, 544), [
            [_vModelText, _unref(state).beforePwInput]
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(state).pwErrorBefore), 1),
          _cache[4] || (_cache[4] = _createElementVNode("p", null, "새 비밀번호", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: "10~16자리 입력",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).pwInput) = $event)),
            onInput: pwCheck
          }, null, 544), [
            [_vModelText, _unref(state).pwInput]
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(state).pwError), 1),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "비밀번호 확인", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: "10~16자리 입력",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).pwCheckInput) = $event))
          }, null, 512), [
            [_vModelText, _unref(state).pwCheckInput]
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_unref(state).pwCheckInput.length > 0 && _unref(state).pwInput !== _unref(state).pwCheckInput)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, "비밀번호를 확인해주세요."))
              : _createCommentVNode("", true)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "ps first" }, "비밀번호는 영문자, 숫자, 특수문자(~@#$%^*)를 포함하여 10-16자리로 만들어주세요.", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "ps" }, "단, 허용되는 특수문자(~@#$%^*) 외 다른 특수문자는 사용할 수 없습니다.", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "ps" }, "포털 사이트나 타 거래소와 동일하거나 비슷한 암호를 설정하지 마세요. 타 사이트에서 암호가 유출될 경우 제3자가 회원님의 계정에 접근할 위험이 있습니다.", -1))
        ])
      ]),
      _createElementVNode("button", {
        class: "login-btn on",
        disabled: _unref(state).beforePwInput.length <= 0 || _unref(state).pwCheckInput.length <= 0 || _unref(state).pwInput !== _unref(state).pwCheckInput,
        onClick: changePwTicket
      }, "비밀번호 변경", 8, _hoisted_7)
    ])
  ], 64))
}
}

})