<template>
    <Header :initType="'type2'" :initTitle="'판매계약서'" />
    <div class="wrap login contract">
        <div class="login-center">
            <div class="contract-inner">
                <p class="title">EPIC 판매계약서</p>
                <p class="sub">"구매자"와 EPIC NetWork(이하 "판매자")는 토큰의 매매에 관련하여 다음과 같이 계약한다.</p>
                <ul>
                    <li>
                        <p><span>1.토큰의 표시:</span>판매자가 유통, 판매하는 본 계약서에 첨부되는 EPIC(이하 "토큰"이라 한다)</p>
                    </li>
                    <li>
                        <p><span>2.매매수량:</span> ( {{store.SignState.data.amountTotal}} )개</p>
                    </li>
                    <li>
                        <p><span>3.대금:</span> ( {{store.SignState.data.requestKrw}} )원</p>
                    </li>
                    <li>
                        <p><span>4. 코인 구매 입금자명과 동일한 이름으로 서명해 주세요</span></p>
                    </li>
                </ul>
                <p class="last">계약당사자들이 전자서명함으로써 이 계약은 성립되었고, 당사자들은 각자 전자문서의 방법으로 본 계약서를 보관한다.</p>
                <div class="signImg" v-if="store.SignState.data.fileName" style="background-color: #fff; border-radius: 8px; margin: 10px 0; height: 240px;">
                    <img :src="store.SignState.data.fileName" >
                </div>
                <div class="human">
                    <div class="flex">
                        <p>구매자</p>
                        <p>{{store.AccountState.userInfo.name}}</p>
                    </div>
                    <div class="flex">
                        <p>판매자</p>
                        <p>EPIC NetWork</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="login-btn on" @click="router.go(-1)">확인</button>
    </div>
</template>
<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits,axios,store,onMounted, defineProps,  reactive } = useBaseComponent();


let state = reactive({
    symbol: '' as any,
    signImg:'' as any,
})

onMounted(() => {
    if (!store.SignState.data) {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => { router.push('/') } })
    } else {
        //
    }
    // store.setBeforePage(route.path);
})


</script>