<template>
    <Header :initType="'type1'" />
    <div class="wrap my">
        <h6>아이디 / 추천인코드</h6>
        <div class="box box1">
            <div class="flex">
                <p>아이디</p>
                <p class="text-truncate">{{store.AccountState.userInfo.referralCode}}</p>
                <img src="@/assets/images/copy.svg" alt="clipboard" class="copy" @click="copyText(store.AccountState.userInfo.referralCode)">
            </div>
        </div>
        <h6 class="certify-box">지갑주소</h6>
        <div class="container">
            <div class="box box1">
                <div class="flex">
                    <p>ERC20</p>
                    <p class="text-truncate">{{ store.AccountState.userInfo.erc20Address }}</p>
                    <img src="@/assets/images/copy.svg" alt="clipboard" class="copy" @click="copyText(store.AccountState.userInfo.erc20Address)">
                </div>
            </div>
            <div class="certify-box">
                <h6>인증센터</h6>
                <div class="flex">
                    <div class="left item-box">
                        <img src="@/assets/images/certify-my.svg" alt="">
                        <p class="text">본인인증</p>
                        <p class="text sub">인증완료</p>
                    </div>
                    <div class="right item-box">
                        <img src="@/assets/images//certify-phone.svg" alt="">
                        <p class="text">본인인증</p>
                        <p class="text sub">인증완료</p>
                    </div>
                </div>
            </div>
            <div class="box box2"><!---->
                <ul>
                    <!-- <li class="flex code">
                        <p class="title">내 추천인코드</p>
                        <div class="flex">
                            <p style="color: rgb(252, 125, 97); margin: 0px 3px 0px 0px;">{{ store.AccountState.userInfo.referralCode }}</p>
                            <img src="@/assets/images/copy.svg" alt="clipboard" class="copy">
                        </div>
                    </li> -->
                    <li class="flex cursor">
                        <p class="title">이름</p>
                        <div class="flex">
                            <p>{{ store.AccountState.userInfo.name }}</p>
                            <!-- <button>수정</button> -->
                        </div>
                    </li>
                    <!-- <li class="flex cursor">
                        <p class="title">연락처</p>
                        <div class="flex">
                            <p>{{ store.AccountState.userInfo.phone }}</p>
                            <button>수정</button>
                        </div>
                    </li> -->
                    <!-- <li class="flex cursor">
                        <p class="title">비밀번호 변경</p>
                        <div class="flex">
                            <p></p>
                            <button @click="router.push('/ChangePw')">수정</button>
                        </div>
                    </li> -->
                    <li class="flex cursor" @click="router.push('/Notice')">
                        <p>공지사항</p>
                        <div>
                            <img src="@/assets/images/arrow-right.svg" alt="arrow">
                        </div>
                    </li>
                    <!-- <li class="flex">
                        <p>내가 추천한 추천인</p>
                        <div>
                            <p>{{ store.AccountState.userInfo.referral ? store.AccountState.userInfo.referral :'-' }}</p>
                        </div>
                    </li> -->
                </ul>
            </div>
            <div class="box box3">
                <ul>
                    <li class="flex cursor" @click="router.push('/Language')">
                        <p class="title">언어</p>
                        <div class="language">한국어</div>
                    </li>
                    <!-- <li class="flex cursor">
                        <p class="title">간편인증번호 변경</p>
                        <div>
                            <img src="@/assets/images/arrow-right.svg" alt="arrow">
                        </div>
                    </li> -->
                    <!-- <li class="flex cursor">
                        <p class="title">비밀번호 변경</p>
                        <div>
                            <img src="@/assets/images/arrow-right.svg" alt="arrow">
                        </div>
                    </li> -->
                    <li class="flex cursor" @click="toggleLogoutPopup">
                        <p class="title">로그아웃</p>
                        <div>
                            <img src="@/assets/images/arrow-right.svg" alt="arrow">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="box box4">
                <ul>
                    <li class="flex cursor" @click="router.push('/SelectTerms')">
                        <p class="title">약관 및 정책</p>
                        <div>
                            <img src="@/assets/images/arrow-right.svg" alt="arrow">
                        </div>
                    </li>
                    <li class="flex secession cursor" @click="router.push('/Secession')">
                        <p class="title">회원탈퇴 <span>개인 정보와 설정이 모두 삭제됩니다.</span></p>
                        <div>
                            <img src="@/assets/images/arrow-right.svg" alt="arrow">
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="popup" v-if="state.isShowLogout">
        <div class="popup-inner">
            <p>로그아웃이 진행됩니다.<br>필요시 재로그인 해주세요.</p><!----><!---->
            <div class="buttons flex">
                <button class="close" @click="toggleLogoutPopup">취소</button>
                <button @click="logOut">로그아웃</button>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, store,defineEmits,axios,onMounted, defineProps, reactive } = useBaseComponent();



let state = reactive({
    isShowLogout: false,
})

onMounted(()=> {
    store.setBeforePage(route.path)
})

const logOut = () =>{
   store.logOut()
}

const toggleLogoutPopup = () => {
    state.isShowLogout = !state.isShowLogout;
}

const copyText = (text:string) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
        .then(() => {
            store.setVisibleToast({ message: '복사되었습니다.', isTypeWarning:false, duration: 2000 });
        })
        .catch((err) => {
            // alert("복사 실패: " + err);
        });
    } else {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        store.setVisibleToast({ message: '주소가 복사되었습니다.', isTypeWarning:false, duration: 2000 });
    }
}

</script>
