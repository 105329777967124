import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/EPUSDT.png'


const _hoisted_1 = { class: "wrap trade bot" }
const _hoisted_2 = { class: "cover" }
const _hoisted_3 = {
  class: "want",
  style: {"width":"calc( 100% - 32px )","margin":"0 auto"}
}
const _hoisted_4 = { class: "flex bgBox" }
const _hoisted_5 = { class: "money" }
const _hoisted_6 = ["onInput"]
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "cyber" }
const _hoisted_9 = { class: "list" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = { class: "bottom" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  style: {"color":"yellow"}
}
const _hoisted_16 = { key: 2 }
const _hoisted_17 = {
  key: 3,
  style: {"color":"yellowgreen"}
}
const _hoisted_18 = {
  key: 4,
  style: {"color":"yellow"}
}
const _hoisted_19 = { key: 5 }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = { class: "flex" }
const _hoisted_22 = {
  key: 0,
  class: "flex"
}
const _hoisted_23 = {
  key: 1,
  class: "flex"
}
const _hoisted_24 = { class: "onlyMobileFz10" }
const _hoisted_25 = {
  key: 2,
  class: "flex"
}
const _hoisted_26 = { class: "onlyMobileFz10" }
const _hoisted_27 = {
  key: 3,
  class: "flex"
}

import useBaseComponent from "@/baseComponent";
import { Swiper, SwiperSlide } from 'swiper/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'TradePage',
  setup(__props) {

const { route, router, defineEmits,axios,store ,defineProps, onMounted, reactive,nextTick,formatWon } = useBaseComponent();


let state = reactive({
    symbol:'EPIC' as any,
    amountInput:'' as any,
    purchaseList:[] as any,
    symbolAmount:'' as any,
    // isShowAlarmPopup:true,

    tokenInfo:{} as any, //토큰별 정보
    tokenPriceData:{} as any, //토큰별 시세

    //paging
    totalRecordSize:0,
    pageSize:999,
    currentPage:1,
})

onMounted(()=> {
    nextTick(()=> {
        getPurchaseList();
        getTokenInfo();
        gettokenPriceData();
    })
    store.setBeforePage(route.path)
    //
})


const getPurchaseList = async ()=> {
    let res = await axios.axiosMethods.purchaseCoinList({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:999,
        symbol:state.symbol,
    })
    // console.log(res)
    if(res.returnCode === 0){
        state.purchaseList = res.list;
        state.currentPage = res.page.pageNo;
        state.pageSize = res.page.pageSize;
        state.totalRecordSize = res.page.totalRecordSize;
    } else {
        //
    }
}

const openBuyPopup = async() => {

    if(state.amountInput.length <= 0 ) {
        store.setVisibleDialog({ title: '코인구매 신청', message:`수량을 입력해주세요`,okCancel:false, callback: () => {}})
    } else {
        let res = await axios.axiosMethods.purchaseCoinNotice({})
        if(res.returnCode === 0 ){
            store.setVisibleDialog({ title: res.data.title, message: res.data.message,okCancel:true, callback: () => {
                store.setVisibleDialog({ title: '코인구매 신청', message:`${formatWon(state.amountInput)}원으로 ${state.symbolAmount} ${state.symbol}구매를 신청하시겠습니까?`,okCancel:true, callback: () => {
                    store.PurchaseState.amount = state.amountInput;
                    store.PurchaseState.symbol = state.symbol;
                    store.PurchaseState.symbolAmount = state.symbolAmount;
                    store.PurchaseState.ready = true;
                    // router.push('/Pin')

                    purchaseCoinDeposit();
                    getPurchaseList();
                }})
            }})
        }
        else {
            store.setVisibleDialog({ title: '코인구매 신청', message:`${formatWon(state.amountInput)}원으로 ${state.symbolAmount} ${state.symbol}구매를 신청하시겠습니까?`,okCancel:true, callback: () => {
                    store.PurchaseState.amount = state.amountInput;
                    store.PurchaseState.symbol = state.symbol;
                    store.PurchaseState.symbolAmount = state.symbolAmount;
                    store.PurchaseState.ready = true;
                    // router.push('/Pin')

                    purchaseCoinDeposit();
                    getPurchaseList();
                }})
        }
    }
}


const purchaseCoinDeposit = async()=> {
    let res = await axios.axiosMethods.purchaseCoinDeposit({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        amount:Number(store.PurchaseState.amount.replace(/,/g, '')),
        symbol:store.PurchaseState.symbol
    })
    if(res.returnCode === 0 ){
        store.setVisibleDialog({ title: '코인구매 신청', message:`구매신청이 완료되었습니다.`,okCancel:false, callback: () => {
            store.PurchaseState.ready = false;
            router.push(store.RouteState.beforePage)
        }})
    } else {
        store.setVisibleDialog({ title: '코인구매 신청', message:res.description,okCancel:false, callback: () => {
            router.push(store.RouteState.beforePage)
        }})
    }

    getPurchaseList();
}

const setSignstate = (data:any) => {
    if(data.state === 1 || data.state === 8) {
        store.setSignIdx(data);
        router.push('/Sign')
    } else if(data.state === 3) {
         store.setSignIdx(data);
        router.push('/Contract')
    } else {
        return;
    }
}

const getTokenInfo = async () => {
    let res= await axios.axiosMethods.tokenInfo({
        symbol:state.symbol,
    })
    if(res.returnCode === 0 ){
        state.tokenInfo = res.data;
    } 
}

const gettokenPriceData = async () => {
    let res= await axios.axiosMethods.tokenPrice({
        symbol:'EPIC'
    })
    if(res.returnCode === 0 ){
        state.tokenPriceData = res.data;
    } 
}   

const formatPrice = (price:any) => {
    const tokenPrice = parseFloat(price); 
    const amountInput = parseFloat(state.amountInput.toString().replace(/,/g, ''))

    if(amountInput <= 0 || isNaN(amountInput)) {
        state.symbolAmount = 0;
        return '0.000'
    }
    // state.amountInput = state.amountInput.toLocaleString('en-US', { minimumFractionDigits: 0 });

    // state.amountInput = parseFloat(state.amountInput.toString().replace(/,/g, ''));

    let value = state.amountInput.replace(/[^\d]/g, '');
    
      // 천 단위마다 '.'을 추가하기
      if (value) {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      
      // 포맷된 값을 다시 할당
    state.amountInput = value;

    state.symbolAmount = amountInput / tokenPrice; 
    state.symbolAmount = parseFloat(state.symbolAmount.toFixed(4));
};

const onClickHandler = (pageNo:number) => {
    state.currentPage = pageNo;
    getPurchaseList();
}


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { initType: 'type1' }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).tokenInfo, (item, idx) => {
          return (_openBlock(), _createElementBlock("div", { key: idx }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "title" }, "EPIC 코인구매 신청", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: "0",
                    class: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).amountInput) = $event)),
                    onInput: ($event: any) => (formatPrice(item.price))
                  }, null, 40, _hoisted_6), [
                    [_vModelText, _unref(state).amountInput]
                  ])
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "won" }, "원", -1))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "expectation" }, "예상 구매 수량", -1)),
                _createElementVNode("p", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_unref(state).symbolAmount), 1),
                  _createElementVNode("span", null, _toDisplayString(item.symbol), 1)
                ])
              ]),
              _createElementVNode("button", {
                class: "buy",
                onClick: openBuyPopup
              }, "EPIC 구매 신청")
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[12] || (_cache[12] = _createElementVNode("h6", { class: "title change" }, "거래상품", -1)),
        _createElementVNode("ul", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).purchaseList, (item, idx) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "product",
              key: idx,
              onClick: ($event: any) => (setSignstate(item))
            }, [
              _createElementVNode("div", _hoisted_12, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex top" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex", [{'wait':item.state === 0 || item.state === 2}]])
                  }, [
                    _cache[4] || (_cache[4] = _createElementVNode("p", null, "현재 상태", -1)),
                    (item.state === 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_14, "관리자 승인 대기중"))
                      : (item.state === 1)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_15, "구매자 사인 대기"))
                        : (item.state === 2)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_16, "관리자 거절"))
                          : (item.state === 3)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_17, "구매 완료"))
                            : (item.state === 8)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_18, "사인 필요"))
                              : (_openBlock(), _createElementBlock("p", _hoisted_19, " --- "))
                  ], 2),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[5] || (_cache[5] = _createElementVNode("p", null, "전송 가능 수량", -1)),
                    _createElementVNode("p", null, _toDisplayString(item.amountTotal) + " / " + _toDisplayString(item.requestKrw) + " KRW", 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[6] || (_cache[6] = _createElementVNode("p", null, "신청일:", -1)),
                    _createElementVNode("p", null, _toDisplayString(item.createDate), 1)
                  ]),
                  (item.state !== 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                        _cache[7] || (_cache[7] = _createElementVNode("p", null, "승인일:", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.acceptDate ? item.acceptDate : '-'), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (item.state === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _cache[8] || (_cache[8] = _createElementVNode("p", null, "입금은행:", -1)),
                        _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(state).tokenInfo[0].bankCompany), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (item.state === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _cache[9] || (_cache[9] = _createElementVNode("p", null, "계좌번호:", -1)),
                        _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(state).tokenInfo[0].bankAccount), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (item.state === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _cache[10] || (_cache[10] = _createElementVNode("p", null, "예금주:", -1)),
                        _createElementVNode("p", null, _toDisplayString(_unref(state).tokenInfo[0].bankUser), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 8, _hoisted_11))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}
}

})