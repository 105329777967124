import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrap login" }
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = { class: "box" }
const _hoisted_4 = {
  class: "box",
  style: {"margin":"0px"}
}
const _hoisted_5 = { class: "buttons flex" }
const _hoisted_6 = {
  key: 0,
  class: "popup"
}

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const { route, router,store,axios, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({
    isShowPwPopup:false,
    idInput:''as any,
    pwInput:''as any,
})

// onMounted(()=> {
//     const id = localStorage.getItem('id');
//     const ticketId = localStorage.getItem('ticketId');
//     if(id || ticketId) {
//         router.push('/')
//     }
// })

const togglePwPopup = () => {
    state.isShowPwPopup = !state.isShowPwPopup;
}

const login = async () => {
    let res = await axios.axiosMethods.ticketRequest({
        id:state.idInput,
        password:state.pwInput,
    })
    if(res.returnCode === 0) {
        store.setUserInfo(res.id,res.ticketId);
        router.push('/');
    } else if(res.returnCode === 41) {
        store.setVisibleDialog({ message: '', title:'아이디를 확인해주세요',okCancel:false, callback: () => {}})
    } else if(res.returnCode === 49) {
        store.setVisibleDialog({ message: '', title:'비밀번호를 확인해주세요',okCancel:false, callback: () => {}})
    } else {
        store.setVisibleDialog({ message: res.description, title:'',okCancel:false, callback: () => {}})
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "title" }, [
          _createElementVNode("p", null, [
            _createTextVNode("EPIC WALLET "),
            _createElementVNode("br"),
            _createElementVNode("span", null, "서비스 이용")
          ])
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "sub" }, "자산 관리를 시작하기 위해 ID/PW를 입력해주세요.", -1)),
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("p", null, "아이디", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).idInput) = $event))
          }, null, 512), [
            [_vModelText, _unref(state).idInput]
          ])
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"height":"13px","margin":"3.5px 0px","padding":"0px 0px 0px 15px"} }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("p", null, "비밀번호", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).pwInput) = $event)),
            onKeyup: _withKeys(login, ["enter"])
          }, null, 544), [
            [_vModelText, _unref(state).pwInput]
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"height":"30px","padding":"10px 0px 0px 15px","margin":"0px 0px 10px"} }, null, -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/Register')))
          }, "회원가입")
        ])
      ]),
      _createElementVNode("button", {
        class: "login-btn on",
        onClick: login
      }, "로그인")
    ]),
    (_unref(state).isShowPwPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", { class: "popup-inner" }, [
            _cache[9] || (_cache[9] = _createElementVNode("p", null, "비밀번호 찾기", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "small" }, [
              _createTextVNode("비밀번호 분실과 관련된 사항은 각"),
              _createElementVNode("br"),
              _createTextVNode("영업지점으로 연락해주세요.")
            ], -1)),
            _createElementVNode("button", { onClick: togglePwPopup }, "확인")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})