import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/arrow-right.svg'


const _hoisted_1 = { class: "wrap terms-area" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectTerms',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '약관 및 정책'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", {
          class: "flex",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/Service')))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("p", null, "서비스 이용약관", -1),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "arrow"
          }, null, -1)
        ])),
        _createElementVNode("li", {
          class: "flex",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/Privacy')))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("p", null, "개인정보 처리방침", -1),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "arrow"
          }, null, -1)
        ]))
      ])
    ])
  ], 64))
}
}

})