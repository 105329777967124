import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrap pin flex" }
const _hoisted_2 = { class: "pin-cover" }
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"left","padding-left":"16px"}
}
const _hoisted_4 = {
  key: 1,
  style: {"text-align":"left","padding-left":"16px"}
}
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 2,
  style: {"text-align":"left","padding-left":"16px"}
}
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "newNumberBox" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'PinPage',
  setup(__props) {

const { route, router, defineEmits, store,axios,defineProps, onMounted, reactive,watch ,formatWon} = useBaseComponent();


let state = reactive({
    // setState:false, // 이미있다면 true
    errMessage:'비밀번호를 입력하세요.' as any,
    step:0,
    numberPad:['0','1','2','3','4','5','6','7','8','9'],

    newNumber:'' as any,
    saveNumber:'' as any, 
})

onMounted(()=> {
    // checkOtpSet();
    mixPad();
    // newNumber('');
})


const shuffle = (array:any) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));  
        [array[i], array[j]] = [array[j], array[i]];   
    }
};

const mixPad = () => {
    shuffle(state.numberPad)
}

const pwConfirm = () =>{
    //구매
    if(store.PurchaseState.ready){
        //purchaseCoinDeposit();
    }
    // 전송
    else if(store.TradeState.ready){
        purchaseCoinSend();
    }
    //탈퇴
    else if(store.AccountState.secession) {
        userDelete();
    }
}

const userDelete = async() => {
    let res = await  axios.axiosMethods.userDelete({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        otp:state.newNumber,
    })
    if(res.returnCode === 0) {
        localStorage.clear();
        sessionStorage.clear();
        router.push('/')
    } else if(res.returnCode === 72){
        store.setVisibleDialog({ title: '', message:'이미 탈퇴 처리 되었습니다.',okCancel:false, callback: () => { router.push('/')}})
    } else {
        store.setVisibleDialog({ title: '실패', message:res.description,okCancel:false, callback: () => { router.push('/')}})
    }
} 

// const newNumber = (pin: any) => {
//     if (state.newNumber.length >= 6) {
//         return; 
//     }

//     state.newNumber += pin;

//     if (state.setState) {
//         if (state.newNumber.length === 6) {
//            // 이미설정했고, 6자리입력시 
//            //구매루트
//            if(store.PurchaseState.ready){
//                 purchaseCoinDeposit();
//            }
//            //
//            if(store.TradeState.ready){
//                 purchaseCoinSend();
//            }
//         }
//     } else {
//         if (state.step === 0) {
//             if (state.newNumber.length === 6) {
//                 state.saveNumber = state.newNumber;
//                 state.step = 1; 
//                 state.newNumber = '';
//                 state.errMessage = 'OTP 번호를 다시 입력하세요.';
//             }
//         } else if (state.step === 1) {
//             //재확인
//             if (state.newNumber.length === 6) {
//                 if (state.saveNumber === state.newNumber) {
//                     userOtpSet(state.newNumber);
//                 } else {
//                     state.errMessage = 'OTP 번호가 일치하지 않습니다.'; 
//                     state.newNumber = ''; 
//                 }
//             }
//         }
//     }
// };

// const userOtpSet = async(otp:any) => {
//     let res = axios.axiosMethods.userOtpSet({
//         id:store.AccountState.id,
//         ticketId:store.AccountState.ticketId,
//         data:otp
//     })
//     if(res.returnCode === 0) {
//         router.push(store.RouteState.beforePage)
//     } else {
//         //
//     }
// }

const purchaseCoinSend = async () => {
    let res = await axios.axiosMethods.purchaseCoinSend({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        idx:store.TradeState.withdrawData.idx,
        to:store.TradeState.to,
        otp:state.newNumber,
    })
    if(res.returnCode === 0 ){
        store.setVisibleDialog({ title: '전송', message:`전송이 완료되었습니다.`,okCancel:false, callback: () => {
            store.TradeState.ready = false;
            router.push('/Wallet')
        }})
    } else {
        store.setVisibleDialog({ title: '전송 실패', message:res.description,okCancel:false, callback: () => {}})
    }
}

// const deleteNumber = () => {
//      state.newNumber = state.newNumber.slice(0, -1); 
// };

// const checkOtpSet = () => {
//     if(store.AccountState.userInfo.otp) {
//         state.setState = true;
//     } else {
//         state.setState = false;
//     }
// }
 


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { initType: 'type2' }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_unref(store).AccountState.secession)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
              _createElementVNode("p", { class: "title" }, [
                _createElementVNode("span", null, "회원탈퇴를 진행합니다.")
              ], -1)
            ])))
          : (_unref(store).PurchaseState.ready)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, [
                  _createTextVNode(_toDisplayString(_unref(formatWon)(_unref(store).PurchaseState.amount)) + "원으로 ", 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(store).PurchaseState.symbolAmount) + " " + _toDisplayString(_unref(store).PurchaseState.symbol), 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", null, "구매신청을 진행합니다.", -1))
                ])
              ]))
            : (_unref(store).TradeState.ready)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, [
                    _createTextVNode("받으실 분: " + _toDisplayString(_unref(store).TradeState.to) + " ", 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(store).PurchaseState.symbolAmount) + " " + _toDisplayString(_unref(store).PurchaseState.symbol), 1),
                    _cache[3] || (_cache[3] = _createElementVNode("span", null, "전송을 진행합니다.", -1))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8)),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            class: "newNumber",
            placeholder: "비밀번호를 입력해주세요",
            type: "password",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).newNumber) = $event)),
            onKeyup: _withKeys(pwConfirm, ["enter"])
          }, null, 544), [
            [_vModelText, _unref(state).newNumber]
          ])
        ]),
        _createElementVNode("button", {
          class: "login-btn on",
          onClick: pwConfirm
        }, "완료")
      ])
    ])
  ], 64))
}
}

})