import { defineStore } from "pinia";
import { useLocalStorage, useStorage } from "@vueuse/core";
import axios from "@/axios";
import router from "@/router";

export const useStore = defineStore("root-store", {
  state: () => ({
    AccountState:{
        id:useLocalStorage('id',''),
        ticketId:useLocalStorage('ticketId',''),
        userInfo:{} as any,
        secession:false,
    },
    CommonState: {
      toastVisible: false,
      toastMessage: "",
      toastIsTypeWarning: false,
      toastDuration: 2000,
      toastCurrTimer: null as any,  // 타입 명시적으로 수정
      configSite: {} as any,
      visibleDialog: false,
      visibleDialogMessage: '',
      visibleDialogTitle: '',
      visibleDialogCallback: null as (() => void) | null,
      bDialogOkCancel: false,
    },
    PurchaseState:{
      ready:false,
      amount:'',
      symbol:'',
      symbolAmount:'',
    },
    RouteState:{
      beforePage:'',
      afterPage:'',
    },
    SignState:{
      data:'' as any,
    },
    TokenState:{
      tokenInfoData:'' as any,
      tokenPriceData:'' as any,
    },
    TradeState:{
      ready:false,
      to:'' as any,
      depositData:{} as any,
      withdrawData:{} as any,
    }
  }),
  actions: {
    async login(id:string,pw:string,router:any) {
        const res = await axios.axiosMethods.ticketRequest({
            id:id,
            password:pw,
        })
        if(res.returnCode === 0) {
            this.setUserInfo(res.id,res.ticketId);
            router.push('/');
        } else if(res.returnCode === 41) {
            this.setVisibleDialog({ message: '', title:'아이디를 확인해주세요',okCancel:false, callback: () => {}})
        } else if(res.returnCode === 49) {
            this.setVisibleDialog({ message: '', title:'비밀번호를 확인해주세요',okCancel:false, callback: () => {}})
        } else {
            this.setVisibleDialog({ message: res.description, title:'',okCancel:false, callback: () => {}})
        }
    },
    setWithdrawData(data:any){
      this.TradeState.withdrawData = data;
    },
    setSignIdx(data:any){
      this.SignState.data = data;
    },
    setBeforePage(path:string){
      this.RouteState.beforePage = path;
    },
    setUserInfo(id:string,ticketId:string){
        this.AccountState.id = id;
        this.AccountState.ticketId = ticketId;
    },
    setVisibleToast({ isTypeWarning = false, message = '', duration = 1000 }) {
      // 기존 토스트 메시지가 있으면 숨김
      if (this.CommonState.toastCurrTimer) {
        this.setUnvisibleToast();
      }

      // 새로 토스트 메시지 설정
      setTimeout(() => {
        const replaceMessage = message.replace("\n", "<br/>");

        this.CommonState.toastVisible = true;
        this.CommonState.toastIsTypeWarning = isTypeWarning;
        this.CommonState.toastMessage = replaceMessage;
        this.CommonState.toastDuration = duration;

        this.CommonState.toastCurrTimer = setTimeout(() => {
          this.setUnvisibleToast();
        }, duration);
      }, 100);
    },
    setUnvisibleToast() {
      this.CommonState.toastVisible = false;
      if (this.CommonState.toastCurrTimer) {
        clearTimeout(this.CommonState.toastCurrTimer);  // Timer를 지웁니다
      }
      this.CommonState.toastCurrTimer = null;
    },
    setVisibleDialog({ visible = true, message = '', title = '', okCancel = false, callback = () => { } }) {
      const replaceMessage = message.replace("\n", "<br/>");

      this.CommonState.bDialogOkCancel = okCancel;
      this.CommonState.visibleDialogMessage = replaceMessage;
      this.CommonState.visibleDialogTitle = title;
      this.CommonState.visibleDialog = visible;

      this.CommonState.visibleDialogCallback = callback;
    },
    executeVisibleDialog({ isExecute = false }) {
      // 다이얼로그 숨기기 및 초기화
      this.CommonState.visibleDialog = false;
      this.CommonState.visibleDialogMessage = '';
      this.CommonState.visibleDialogTitle = '';

      if (isExecute && this.CommonState.visibleDialogCallback) {
        this.CommonState.visibleDialogCallback();  // 콜백 실행
      }
    },
    logOut(){
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    },
  },
  persist: {
    storage: sessionStorage, // sessionStorage
  },
});
