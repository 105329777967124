import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/arrow-right.svg'
import _imports_1 from '@/assets/images/reset.svg'


const _hoisted_1 = {
  key: 0,
  class: "wrap login terms sell"
}
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = { class: "box" }
const _hoisted_4 = { class: "box" }
const _hoisted_5 = { class: "box" }
const _hoisted_6 = { class: "top flex" }
const _hoisted_7 = { class: "box" }
const _hoisted_8 = { class: "top flex" }
const _hoisted_9 = { class: "buttons" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 1,
  class: "wrap login sell signing"
}
const _hoisted_12 = { class: "login-center" }
const _hoisted_13 = { class: "name" }
const _hoisted_14 = { class: "sign-area" }
const _hoisted_15 = { class: "signPad" }
const _hoisted_16 = { class: "buttons" }
const _hoisted_17 = {
  key: 2,
  class: "popup"
}

import { VueSignaturePad } from "@selemondev/vue3-signature-pad"
import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'SignPage',
  setup(__props) {

const { route, router, defineEmits, axios, store, onMounted, defineProps, reactive ,ref,watch} = useBaseComponent();

const signature = ref<typeof VueSignaturePad | null>(null);  // null을 초기값으로 설정

let state = reactive({
    isShowPopup: false,
    step: 0,
    symbol: '' as any,

    //check
    allCheck:false,
    check0:false,
    check1:false,
    check2:false,

    //sign
    disabled: false,
    options: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255, 255, 255)'
    },
    file:'' as any
})



onMounted(() => {
    if (store.SignState.data) {
        // console.log(store.SignState.data)
        return;
    } else {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => { router.push('/Trade') } })
    }
    store.setBeforePage(route.path);
})

watch(() => [
    state.check0, state.check1, state.check2
], () => {
    state.allCheck = state.check0 && state.check1 && state.check2;
})

const checkAll = () => {
    if (state.allCheck) {
        state.check0 = true;
        state.check1 = true;
        state.check2 = true;
    } else {
        state.check0 = false;
        state.check1 = false;
        state.check2 = false;
    }
};


const togglePopup = () => {
    state.isShowPopup = !state.isShowPopup;
}

const handleSaveSignature = async () => {
    const data = signature.value?.saveSignature(); 
    let base64Data = data;
    if (base64Data.startsWith('data:image/png;base64,')) {
        base64Data = base64Data.replace('data:image/png;base64,', '');
    }

    base64Data = base64Data.replace(/-/g, '+').replace(/_/g, '/');

    try {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/png' });
        const file = new File([blob], 'filename.png', { type: 'image/png' });

        let formData = new FormData();
        formData.append('file', file);

        let res = await axios.axiosMethods.purchaseCoinUpload({
            idx: store.SignState.data.idx,
            id: store.AccountState.id,
            ticketId: store.AccountState.ticketId,
            file: formData,
        });

        // console.log('handle', res);

        if (res.data.returnCode === 0) {
            confirmSign();
        } else {
            store.setVisibleDialog({
                message: '',
                title: '잘못된 접근입니다.',
                okCancel: false,
                callback: () => {
                    router.push('/Trade');
                },
            });
        }
    } catch (error) {
        // console.error('디코딩실패', error);
    }
};

const confirmSign = async () => {
    let res = await axios.axiosMethods.purchaseCoinSign({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        idx:store.SignState.data.idx,
    })
    if(res.returnCode === 0) {
        store.setVisibleDialog({ message: '', title: '서명하셨습니다.', okCancel: false, callback: () => { router.push('/Trade') } })
    } else {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => {router.push('/Trade') } })
    }
}

const handleClear = (t: string) => {
    if (signature.value) return signature.value.clearCanvas()
};



return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '판매계약서'
    }),
    (_unref(state).step === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                class: "checkbox form-check-input",
                type: "checkbox",
                id: "defaultCheck",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).allCheck) = $event)),
                onChange: checkAll
              }, null, 544), [
                [_vModelCheckbox, _unref(state).allCheck]
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex" }, [
                _createElementVNode("label", {
                  class: "form-check-label text-truncate",
                  for: "defaultCheck"
                }, "약관에 모두 동의 합니다.")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                class: "checkbox form-check-input",
                type: "checkbox",
                id: "defaultCheck1",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).check0) = $event))
              }, null, 512), [
                [_vModelCheckbox, _unref(state).check0]
              ]),
              _createElementVNode("div", {
                class: "flex",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/Contract')))
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("label", { class: "form-check-label text-truncate" }, "EPIC 토큰 프라이빗 판매계약서", -1),
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "arrow",
                  alt: "arrow"
                }, null, -1)
              ]))
            ]),
            _cache[15] || (_cache[15] = _createElementVNode("p", { class: "ps" }, "* 토큰 판매계약서의 내용을 반드시 확인해주세요", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  class: "checkbox form-check-input",
                  type: "checkbox",
                  id: "defaultCheck2",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).check1) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _unref(state).check1]
                ]),
                _createElementVNode("div", {
                  class: "flex",
                  onClick: togglePopup
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("label", { class: "form-check-label text-truncate" }, "토큰 판매 서비스 유의사항", -1),
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "arrow",
                    alt: "arrow"
                  }, null, -1)
                ]))
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "bottom" }, [
                _createElementVNode("p", null, "고객님이 입금하신 금액과 코인구매 신청 금액을 확인 후, 관리자 승인이 진행됩니다. 실제 입금하신 금액과 신청 금액이 상이할 경우에는 승인 처리가 보류 혹은 거절될 수 있으니 유의하시기 바랍니다.")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  class: "checkbox form-check-input",
                  type: "checkbox",
                  id: "defaultCheck3",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).check2) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _unref(state).check2]
                ]),
                _createElementVNode("div", {
                  class: "flex",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/Privacy')))
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("label", { class: "form-check-label text-truncate" }, "EPIC 개인정보 처리방침", -1),
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "arrow",
                    alt: "arrow"
                  }, null, -1)
                ]))
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "bottom" }, [
                _createElementVNode("p", null, "EPIC NetWork(이하 “판매자”)는 EPIC(이하 “앱”)을 이용해 주시는 이용자의 개인정보 보호를 매우 중요시하며 『개인정보 보호법』제30조, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『통신비밀보호법』 및 『전기통신사업법』에 따라 정보주체의 개인정보를 보호합니다.")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: "login-btn on cancel",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(router).go(-1)))
            }, "취소"),
            _createElementVNode("button", {
              class: "login-btn on",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(state).step = 1)),
              disabled: !_unref(state).check0 || !_unref(state).check1 || !_unref(state).check2
            }, "다음", 8, _hoisted_10)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(store).AccountState.userInfo.name), 1),
            _cache[18] || (_cache[18] = _createElementVNode("p", { class: "symbol" }, "EPIC 토큰 상품 가입을 위한 제반사항 (토큰 판매계약서, 토큰 판매 서비스 유의사항, 개인정보 처리방침)을 확인하였음을 모바일 서명으로 자필 서명합니다. ", -1)),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", { class: "flex" }, [
                _cache[16] || (_cache[16] = _createElementVNode("p", null, " 코인 구매 입금자명과 동일한 이름으로 서명해 주세요.", -1)),
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "clear",
                  class: "clear",
                  onClick: handleClear
                })
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_unref(VueSignaturePad), {
                  ref_key: "signature",
                  ref: signature,
                  height: "240px",
                  width: "100%",
                  maxWidth: 2,
                  minWidth: 2,
                  disabled: _unref(state).disabled,
                  options: {
                            penColor: _unref(state).options.penColor, backgroundColor: _unref(state).options.backgroundColor
                        }
                }, null, 8, ["disabled", "options"])
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("p", { class: "ps" }, "*다시 서명하려면 우측 상단의 새로고침 버튼을 선택해 주세요.", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("button", {
              class: "login-btn on cancel",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(state).step = 0 ))
            }, "뒤로가기"),
            _createElementVNode("button", {
              class: "login-btn on",
              onClick: handleSaveSignature
            }, "확인")
          ])
        ])),
    (_unref(state).isShowPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", { class: "popup-inner" }, [
            _cache[19] || (_cache[19] = _createElementVNode("p", null, "EPIC 코인구매 유의사항", -1)),
            _cache[20] || (_cache[20] = _createElementVNode("p", { class: "btn-date" }, "2024-01-02 15:10:22", -1)),
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "small date-small" }, [
              _createElementVNode("p", null, "고객님이 입금하신 금액과 코인구매 신청 금액을 확인 후, 관리자 승인이 진행됩니다. 실제 입금하신 금액과 신청 금액이 상이할 경우에는 승인 처리가 보류 혹은 거절될 수 있으니 유의하시기 바랍니다."),
              _createElementVNode("br"),
              _createElementVNode("p", null, "입금하신 고객님과 EPIC을 이용하는 고객님의 정보가 동일하지 않을 경우에는 구매신청 처리가 지연되거나 거절될 수 있습니다."),
              _createElementVNode("br"),
              _createElementVNode("p", null, "코인구매를 통해 지급되는 수량은 고객님이 구매 신청했을 시점에 표시되는 예상 구매 수량으로 결정되며, 구매 신청이 완료된 시점 이후로는 그 어떠한 사안으로도 수량 변경이 이루어지지 않습니다.")
            ], -1)),
            _createElementVNode("button", { onClick: togglePopup }, "확인")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})