<template>
    <Header :initType="'type2'" :initTitle="'추천인 코드 입력'" />
    <div class="wrap login recommender">
        <div class="login-center">
            <h5>추천인 코드를 입력해주세요.</h5>
            <p class="sub">추천인 코드는 최초 가입 시에만 입력할 수 있습니다. (선택사항)</p>
            <div class="warn"><input type="text" placeholder="추천인 코드를 입력해주세요.">
                <div style="height: 30px; padding: 10px 0px 0px 15px;"><!----></div>
            </div>
        </div>
        <button class="login-btn on">다음</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})
</script>
