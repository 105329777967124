import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/alarm-menu.svg'
import _imports_1 from '@/assets/images/arrow-right.svg'
import _imports_2 from '@/assets/images/notice-menu.svg'


const _hoisted_1 = { class: "wrap menu bot" }
const _hoisted_2 = { class: "container" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'MenuPage',
  setup(__props) {

const { route, router, defineEmits,axios,store, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

onMounted(()=>{
    store.setBeforePage(route.path);
})


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { initType: 'type1' }),
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("h6", null, "메뉴", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", {
            class: "flex cursor",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/Alarm')))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "이미지"
            }, null, -1),
            _createElementVNode("p", null, "알림", -1),
            _createElementVNode("img", {
              src: _imports_1,
              alt: "arrow"
            }, null, -1)
          ])),
          _createElementVNode("li", {
            class: "flex cursor",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/Notice')))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("img", {
              src: _imports_2,
              alt: "이미지"
            }, null, -1),
            _createElementVNode("p", null, "공지사항", -1),
            _createElementVNode("img", {
              src: _imports_1,
              alt: "arrow"
            }, null, -1)
          ]))
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}
}

})