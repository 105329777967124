<template>
    <Header :initType="'type1'" />
    <div class="wrap trade bot">
        <div class="cover">
                <div v-for="(item,idx) in state.tokenInfo" :key="idx">
                    <div class="want" style="width:calc( 100% - 32px );margin:0 auto;">
                        <h6 class="title">EPIC 코인구매 신청</h6>
                        <div class="flex bgBox">
                            <div class="money">
                                <input type="text" placeholder="0" class="" v-model="state.amountInput" @input="formatPrice(item.price)"/>
                            </div>
                            <div class="won">원</div>
                        </div>
                        <div class="flex">
                            <p class="expectation">예상 구매 수량</p>
                            <p class="cyber">{{ state.symbolAmount }}<span>{{ item.symbol }}</span></p>
                        </div>
                        <button class="buy" @click="openBuyPopup">EPIC 구매 신청</button>
                    </div>
                </div>
        </div>
        <!-- <div class="cover">
            <swiper :slidesPerView="1" :spaceBetween="10" :pagination="{ clickable: true }" :navigation="true"
                effect="slide">
                <swiper-slide v-for="(item,idx) in state.tokenInfo" :key="idx">
                    <div class="want">
                        <h6 class="title">EPIC 코인구매 신청</h6>
                        <div class="flex bgBox">
                            <div class="money">
                                <input type="text" placeholder="0" class="" v-model="state.amountInput" @input="formatPrice(item.price)"/>
                            </div>
                            <div class="won">원</div>
                        </div>
                        <div class="flex">
                            <p class="expectation">예상 구매 수량</p>
                            <p class="cyber">{{ state.symbolAmount }}<span>{{ item.symbol }}</span></p>
                        </div>
                        <button class="buy" @click="openBuyPopup">EPIC 구매 신청</button>
                    </div>
                </swiper-slide>
            </swiper>
        </div> -->
        <div class="list">
            <h6 class="title change">거래상품</h6>
            <ul class="container">
                <li class="product" v-for="(item,idx) in state.purchaseList" :key="idx" @click="setSignstate(item)">
                    <div class="flex">
                        <div class="flex top">
                            <img src="@/assets/images/EPUSDT.png" alt="">
                        </div>
                        <div class="bottom">
                            <div class="flex" :class="[{'wait':item.state === 0 || item.state === 2}]">
                                <p>현재 상태</p>
                                <p v-if="item.state === 0">관리자 승인 대기중</p>
                                <p v-else-if="item.state === 1" style="color:yellow">구매자 사인 대기</p>
                                <p v-else-if="item.state === 2">관리자 거절</p>
                                <p v-else-if="item.state === 3" style="color:yellowgreen">구매 완료</p>
                                <p v-else-if="item.state === 8" style="color:yellow">사인 필요</p>
                                <p v-else> --- </p>
                            </div>
                            <div class="flex">
                                <p>전송 가능 수량</p>
                                <p>{{item.amountTotal}} / {{ item.requestKrw }} KRW</p>
                            </div>
                            <div class="flex">
                                <p>신청일:</p>
                                <p>{{item.createDate}}</p>
                            </div>
                            <div class="flex" v-if="item.state !== 0">
                                <p>승인일:</p>
                                <p>{{item.acceptDate ? item.acceptDate : '-'}}</p>
                            </div>
                            <div class="flex" v-if="item.state === 0">
                                <p>입금은행:</p>
                                <p class="onlyMobileFz10">
                                    {{ state.tokenInfo[0].bankCompany }} 
                                </p>
                            </div>
                            <div class="flex" v-if="item.state === 0">
                                <p>계좌번호:</p>
                                <p class="onlyMobileFz10">
                                    {{ state.tokenInfo[0].bankAccount }}
                                </p>
                            </div>
                            <div class="flex" v-if="item.state === 0">
                                <p>예금주:</p>
                                <p>
                                    {{ state.tokenInfo[0].bankUser }}
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- <vue-awesome-paginate
                :total-items="state.totalRecordSize"
                :items-per-page="state.pageSize"
                :max-pages-shown="5"
                v-model="state.currentPage"
                @click="onClickHandler(state.currentPage)"
            /> -->
        </div>
    </div>
    <Footer />
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
import { Swiper, SwiperSlide } from 'swiper/vue';
const { route, router, defineEmits,axios,store ,defineProps, onMounted, reactive,nextTick,formatWon } = useBaseComponent();


let state = reactive({
    symbol:'EPIC' as any,
    amountInput:'' as any,
    purchaseList:[] as any,
    symbolAmount:'' as any,
    // isShowAlarmPopup:true,

    tokenInfo:{} as any, //토큰별 정보
    tokenPriceData:{} as any, //토큰별 시세

    //paging
    totalRecordSize:0,
    pageSize:999,
    currentPage:1,
})

onMounted(()=> {
    nextTick(()=> {
        getPurchaseList();
        getTokenInfo();
        gettokenPriceData();
    })
    store.setBeforePage(route.path)
    //
})


const getPurchaseList = async ()=> {
    let res = await axios.axiosMethods.purchaseCoinList({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:999,
        symbol:state.symbol,
    })
    // console.log(res)
    if(res.returnCode === 0){
        state.purchaseList = res.list;
        state.currentPage = res.page.pageNo;
        state.pageSize = res.page.pageSize;
        state.totalRecordSize = res.page.totalRecordSize;
    } else {
        //
    }
}

const openBuyPopup = async() => {

    if(state.amountInput.length <= 0 ) {
        store.setVisibleDialog({ title: '코인구매 신청', message:`수량을 입력해주세요`,okCancel:false, callback: () => {}})
    } else {
        let res = await axios.axiosMethods.purchaseCoinNotice({})
        if(res.returnCode === 0 ){
            store.setVisibleDialog({ title: res.data.title, message: res.data.message,okCancel:true, callback: () => {
                store.setVisibleDialog({ title: '코인구매 신청', message:`${formatWon(state.amountInput)}원으로 ${state.symbolAmount} ${state.symbol}구매를 신청하시겠습니까?`,okCancel:true, callback: () => {
                    store.PurchaseState.amount = state.amountInput;
                    store.PurchaseState.symbol = state.symbol;
                    store.PurchaseState.symbolAmount = state.symbolAmount;
                    store.PurchaseState.ready = true;
                    // router.push('/Pin')

                    purchaseCoinDeposit();
                    getPurchaseList();
                }})
            }})
        }
        else {
            store.setVisibleDialog({ title: '코인구매 신청', message:`${formatWon(state.amountInput)}원으로 ${state.symbolAmount} ${state.symbol}구매를 신청하시겠습니까?`,okCancel:true, callback: () => {
                    store.PurchaseState.amount = state.amountInput;
                    store.PurchaseState.symbol = state.symbol;
                    store.PurchaseState.symbolAmount = state.symbolAmount;
                    store.PurchaseState.ready = true;
                    // router.push('/Pin')

                    purchaseCoinDeposit();
                    getPurchaseList();
                }})
        }
    }
}


const purchaseCoinDeposit = async()=> {
    let res = await axios.axiosMethods.purchaseCoinDeposit({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        amount:Number(store.PurchaseState.amount.replace(/,/g, '')),
        symbol:store.PurchaseState.symbol
    })
    if(res.returnCode === 0 ){
        store.setVisibleDialog({ title: '코인구매 신청', message:`구매신청이 완료되었습니다.`,okCancel:false, callback: () => {
            store.PurchaseState.ready = false;
            router.push(store.RouteState.beforePage)
        }})
    } else {
        store.setVisibleDialog({ title: '코인구매 신청', message:res.description,okCancel:false, callback: () => {
            router.push(store.RouteState.beforePage)
        }})
    }

    getPurchaseList();
}

const setSignstate = (data:any) => {
    if(data.state === 1 || data.state === 8) {
        store.setSignIdx(data);
        router.push('/Sign')
    } else if(data.state === 3) {
         store.setSignIdx(data);
        router.push('/Contract')
    } else {
        return;
    }
}

const getTokenInfo = async () => {
    let res= await axios.axiosMethods.tokenInfo({
        symbol:state.symbol,
    })
    if(res.returnCode === 0 ){
        state.tokenInfo = res.data;
    } 
}

const gettokenPriceData = async () => {
    let res= await axios.axiosMethods.tokenPrice({
        symbol:'EPIC'
    })
    if(res.returnCode === 0 ){
        state.tokenPriceData = res.data;
    } 
}   

const formatPrice = (price:any) => {
    const tokenPrice = parseFloat(price); 
    const amountInput = parseFloat(state.amountInput.toString().replace(/,/g, ''))

    if(amountInput <= 0 || isNaN(amountInput)) {
        state.symbolAmount = 0;
        return '0.000'
    }
    // state.amountInput = state.amountInput.toLocaleString('en-US', { minimumFractionDigits: 0 });

    // state.amountInput = parseFloat(state.amountInput.toString().replace(/,/g, ''));

    let value = state.amountInput.replace(/[^\d]/g, '');
    
      // 천 단위마다 '.'을 추가하기
      if (value) {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      
      // 포맷된 값을 다시 할당
    state.amountInput = value;

    state.symbolAmount = amountInput / tokenPrice; 
    state.symbolAmount = parseFloat(state.symbolAmount.toFixed(4));
};

const onClickHandler = (pageNo:number) => {
    state.currentPage = pageNo;
    getPurchaseList();
}

</script>

<style>
.swiper-wrapper {
    transform: translate3d(28px, 0px, 0px) !important;
}

.swiper-slide {
    margin-right: -10px !important;
}
</style>