import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrap login contract" }
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = { class: "contract-inner" }
const _hoisted_4 = {
  key: 0,
  class: "signImg",
  style: {"background-color":"#fff","border-radius":"8px","margin":"10px 0","height":"240px"}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "human" }
const _hoisted_7 = { class: "flex" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'ContractPage',
  setup(__props) {

const { route, router, defineEmits,axios,store,onMounted, defineProps,  reactive } = useBaseComponent();


let state = reactive({
    symbol: '' as any,
    signImg:'' as any,
})

onMounted(() => {
    if (!store.SignState.data) {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => { router.push('/') } })
    } else {
        //
    }
    // store.setBeforePage(route.path);
})



return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '판매계약서'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "title" }, "EPIC 판매계약서", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "sub" }, "\"구매자\"와 EPIC NetWork(이하 \"판매자\")는 토큰의 매매에 관련하여 다음과 같이 계약한다.", -1)),
          _createElementVNode("ul", null, [
            _cache[3] || (_cache[3] = _createElementVNode("li", null, [
              _createElementVNode("p", null, [
                _createElementVNode("span", null, "1.토큰의 표시:"),
                _createTextVNode("판매자가 유통, 판매하는 본 계약서에 첨부되는 EPIC(이하 \"토큰\"이라 한다)")
              ])
            ], -1)),
            _createElementVNode("li", null, [
              _createElementVNode("p", null, [
                _cache[1] || (_cache[1] = _createElementVNode("span", null, "2.매매수량:", -1)),
                _createTextVNode(" ( " + _toDisplayString(_unref(store).SignState.data.amountTotal) + " )개", 1)
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", null, [
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "3.대금:", -1)),
                _createTextVNode(" ( " + _toDisplayString(_unref(store).SignState.data.requestKrw) + " )원", 1)
              ])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("li", null, [
              _createElementVNode("p", null, [
                _createElementVNode("span", null, "4. 코인 구매 입금자명과 동일한 이름으로 서명해 주세요")
              ])
            ], -1))
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "last" }, "계약당사자들이 전자서명함으로써 이 계약은 성립되었고, 당사자들은 각자 전자문서의 방법으로 본 계약서를 보관한다.", -1)),
          (_unref(store).SignState.data.fileName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _unref(store).SignState.data.fileName
                }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[5] || (_cache[5] = _createElementVNode("p", null, "구매자", -1)),
              _createElementVNode("p", null, _toDisplayString(_unref(store).AccountState.userInfo.name), 1)
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex" }, [
              _createElementVNode("p", null, "판매자"),
              _createElementVNode("p", null, "EPIC NetWork")
            ], -1))
          ])
        ])
      ]),
      _createElementVNode("button", {
        class: "login-btn on",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).go(-1)))
      }, "확인")
    ])
  ], 64))
}
}

})