<template>
<!-- <div :class="[store.CommonState.themeMode === 'dark' ? 'dark' : '',{'devicePc' : store.SystemState._deviceType !== DEVICE_TYPE_MOBILE}]"> -->
<!-- <div class="pc mobile"> -->
    <router-view :key="$route.fullPath" />
    <div class="popup" v-if="store.CommonState.visibleDialog" style="touch-action: manipulation;">
        <div class="popup-inner">
            <p>{{store.CommonState.visibleDialogTitle}}</p>
            <p class="small" v-if="store.CommonState.visibleDialogMessage.length > 0" v-html="store.CommonState.visibleDialogMessage"></p>
        <!----><!----><!---->
        <div class="flex" :class="{'buttons':store.CommonState.bDialogOkCancel}" >
            <button v-if="store.CommonState.bDialogOkCancel" class="close" @click="store.executeVisibleDialog({isExecute:false})" >취소</button>
            <button @click="store.executeVisibleDialog({isExecute:true})">확인</button>
        </div>
    </div>
</div>
    <!-- <div class="popup confirm" id="app-popup" v-if="store.SystemState.visibleDialog">
        <div class="popup-inner" :class="{'no-title' : store.SystemState.visibleDialogTitle === ''}">
            <p class="title" v-if="store.SystemState.visibleDialogTitle.length > 0">{{ store.SystemState.visibleDialogTitle }}</p>
            <p class="content" v-html="store.SystemState.visibleDialogMessage"></p>
            <div class="flex-center">
                <button class="cancel" @click="store.setVisibleDialog({visible:false, okCancel:true})" v-if="store.SystemState.bDialogOkCancel">취소</button>
                <button class="ok" @click="store.setVisibleDialog({visible:false})">확인</button>
            </div>
        </div>
    </div> -->
    <div class="popup-join"  v-if="store.CommonState.toastVisible" :class="[{'caution':store.CommonState.toastIsTypeWarning}]" :style="{animationDuration: ((store.CommonState.toastDuration / 1000) + 0.1 ) +'s'}">
        <p class="text"  v-html="store.CommonState.toastMessage"></p>
    </div>
<!-- </div> -->
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
import { onBeforeRouteLeave } from "vue-router";
const { route, router, defineEmits, defineProps, onMounted, reactive,axios, store, watch} = useBaseComponent();

// // let state = reactive({
 
// // })
// const id = localStorage.getItem('id');
// const ticketId = localStorage.getItem('ticketId');

// onMounted(() => {
//     if(!id || !ticketId){
//         return;
//     } else {
//         // getUserInfo()
//     }
//     // console.log(route.path)
// });

// // const getUserInfo = async () => {
// //     let res = await axios.axiosMethods.userInfo({
// //         id:id,
// //         ticketId:ticketId,
// //     })
// //     if(res.returnCode === 0) {
// //         store.AccountState.userInfo = res.data;
// //     } else {
// //         store.setVisibleDialog({ title: '오류', message: res.description, okCancel: false, callback: () => { } })
// //     }
// // }



</script>
<style>

</style>
