import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popup",
  style: {"touch-action":"manipulation"}
}
const _hoisted_2 = { class: "popup-inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import useBaseComponent from "@/baseComponent";
import { onBeforeRouteLeave } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive,axios, store, watch} = useBaseComponent();

// // let state = reactive({
 
// // })
// const id = localStorage.getItem('id');
// const ticketId = localStorage.getItem('ticketId');

// onMounted(() => {
//     if(!id || !ticketId){
//         return;
//     } else {
//         // getUserInfo()
//     }
//     // console.log(route.path)
// });

// // const getUserInfo = async () => {
// //     let res = await axios.axiosMethods.userInfo({
// //         id:id,
// //         ticketId:ticketId,
// //     })
// //     if(res.returnCode === 0) {
// //         store.AccountState.userInfo = res.data;
// //     } else {
// //         store.setVisibleDialog({ title: '오류', message: res.description, okCancel: false, callback: () => { } })
// //     }
// // }




return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    })),
    (_unref(store).CommonState.visibleDialog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_unref(store).CommonState.visibleDialogTitle), 1),
            (_unref(store).CommonState.visibleDialogMessage.length > 0)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "small",
                  innerHTML: _unref(store).CommonState.visibleDialogMessage
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["flex", {'buttons':_unref(store).CommonState.bDialogOkCancel}])
            }, [
              (_unref(store).CommonState.bDialogOkCancel)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "close",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).executeVisibleDialog({isExecute:false})))
                  }, "취소"))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(store).executeVisibleDialog({isExecute:true})))
              }, "확인")
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(store).CommonState.toastVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["popup-join", [{'caution':_unref(store).CommonState.toastIsTypeWarning}]]),
          style: _normalizeStyle({animationDuration: ((_unref(store).CommonState.toastDuration / 1000) + 0.1 ) +'s'})
        }, [
          _createElementVNode("p", {
            class: "text",
            innerHTML: _unref(store).CommonState.toastMessage
          }, null, 8, _hoisted_4)
        ], 6))
      : _createCommentVNode("", true)
  ], 64))
}
}

})