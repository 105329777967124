<template>
    <Header :initType="'type2'" :initTitle="'추가정보 입력'" />
    <div class="wrap login info">
        <div class="login-center">
            <div class="title">
                <p><span>추가정보 입력</span>을<br> 완료해주세요. </p>
            </div>
            <div class="mid-form">
                <p>이름</p>
                <input type="text" placeholder="이름을 입력해주세요.">
                <div class="error"><!----></div>
                <p>연락처</p>
                <input type="text" placeholder="연락처를 입력해주세요." maxlength="11" style="margin: 9px 0px 13px;">
                <p>닉네임</p>
                <input type="text" placeholder="닉네임을 입력해주세요.">
                <div class="error"><!----></div>
                <p>아이디</p>
                <input type="text" placeholder="아이디를 입력해주세요." class="clickShield"
                    style="margin: 9px 0px 13px;">
                <p>비밀번호</p>
                <input type="password" placeholder="10~16자리 입력">
                <div class="error"><!----><!----><!----><!----></div>
                <p>비밀번호 확인</p>
                <input type="password" placeholder="10~16자리 입력">
                <div class="error"><!----></div>
                <p class="ps first">비밀번호는 영문자, 숫자, 특수문자(~@#$%^*)를 포함하여 10-16자리로 만들어주세요. 단, 허용되는 특수문자(~@#$%^*) 외 다른 특수문자는
                    사용할 수 없습니다.</p>
                <p class="ps">포털 사이트나 타 거래소와 동일하거나 비슷한 암호를 설정하지 마세요. 타 사이트에서 암호가 유출될 경우 제3자가 회원님의 계정에 접근할 위험이 있습니다.</p>
                <p>추천인 코드</p>
                <input type="text" placeholder="추천인 코드를 입력해주세요." class="clickShield">
            </div>
        </div>
        <button class="login-btn on">완료</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})
</script>
