<template>
    <Header :initType="'type2'" :initTitle="'판매계약서'" />
    <div class="wrap login terms sell" v-if="state.step === 0">
        <div class="login-center">

            <div class="box">
                <input class="checkbox form-check-input" type="checkbox" id="defaultCheck" v-model="state.allCheck" @change="checkAll">
                <div class="flex">
                    <label class="form-check-label text-truncate" for="defaultCheck">약관에 모두 동의 합니다.</label>
                </div>
            </div>
            <div class="box">
                <input class="checkbox form-check-input" type="checkbox" id="defaultCheck1" v-model="state.check0">
                <div class="flex" @click="router.push('/Contract')">
                    <label class="form-check-label text-truncate">EPIC 토큰 프라이빗 판매계약서</label>
                    <img src="@/assets/images/arrow-right.svg" class="arrow" alt="arrow">
                </div>
            </div>
            <p class="ps">* 토큰 판매계약서의 내용을 반드시 확인해주세요</p>
            <div class="box">
                <div class="top flex">
                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck2" v-model="state.check1">
                    <div class="flex" @click="togglePopup">
                        <label class="form-check-label text-truncate">토큰 판매 서비스 유의사항</label>
                        <img src="@/assets/images/arrow-right.svg" class="arrow" alt="arrow">
                    </div>
                </div>
                <div class="bottom">
                    <p>고객님이 입금하신 금액과 코인구매 신청 금액을 확인 후, 관리자 승인이 진행됩니다. 실제 입금하신 금액과 신청 금액이 상이할 경우에는 승인 처리가 보류 혹은 거절될 수 있으니
                        유의하시기 바랍니다.</p>
                </div>
            </div>
            <div class="box">
                <div class="top flex">
                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck3" v-model="state.check2">
                    <div class="flex" @click="router.push('/Privacy')">
                        <label class="form-check-label text-truncate">EPIC 개인정보 처리방침</label>
                        <img src="@/assets/images/arrow-right.svg" class="arrow" alt="arrow">
                    </div>
                </div>
                <div class="bottom">
                    <p>EPIC NetWork(이하 “판매자”)는 EPIC(이하 “앱”)을 이용해 주시는 이용자의 개인정보 보호를 매우 중요시하며 『개인정보 보호법』제30조, 『정보통신망 이용촉진
                        및 정보보호 등에 관한 법률』, 『통신비밀보호법』 및 『전기통신사업법』에 따라 정보주체의 개인정보를 보호합니다.</p>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button class="login-btn on cancel" @click="router.go(-1)">취소</button>
            <button class="login-btn on" @click="state.step = 1" :disabled="!state.check0 || !state.check1 || !state.check2" >다음</button>
        </div>
    </div>
    <div class="wrap login sell signing" v-else>
        <div class="login-center">
            <p class="name">{{store.AccountState.userInfo.name}}</p>
            <p class="symbol">EPIC 토큰 상품 가입을 위한 제반사항 (토큰 판매계약서, 토큰 판매 서비스 유의사항, 개인정보 처리방침)을 확인하였음을 모바일 서명으로 자필 서명합니다.
            </p>
            <div class="sign-area">
                <div class="flex">
                    <p> 코인 구매 입금자명과 동일한 이름으로 서명해 주세요.</p>
                    <img src="@/assets/images/reset.svg" alt="clear" class="clear" @click="handleClear">
                </div>
                <div class="signPad">
                    <VueSignaturePad ref="signature" height="240px" width="100%" :maxWidth="2" :minWidth="2"
                        :disabled="state.disabled"
                        :options="{
                            penColor: state.options.penColor, backgroundColor: state.options.backgroundColor
                        }" 
                    />
                </div>
                <p class="ps">*다시 서명하려면 우측 상단의 새로고침 버튼을 선택해 주세요.</p>
            </div>
        </div>
        <div class="buttons">
            <button class="login-btn on cancel" @click="state.step = 0 ">뒤로가기</button>
            <button class="login-btn on" @click="handleSaveSignature">확인</button>
        </div>
    </div>
    <div class="popup" v-if="state.isShowPopup">
        <div class="popup-inner">
            <p>EPIC 코인구매 유의사항</p>
            <p class="btn-date">2024-01-02 15:10:22</p><!---->
            <div class="small date-small">
                <p>고객님이 입금하신 금액과 코인구매 신청 금액을 확인 후, 관리자 승인이 진행됩니다. 실제 입금하신 금액과 신청 금액이 상이할 경우에는 승인 처리가 보류 혹은 거절될 수 있으니
                    유의하시기
                    바랍니다.</p><br>
                <p>입금하신 고객님과 EPIC을 이용하는 고객님의 정보가 동일하지 않을 경우에는 구매신청 처리가 지연되거나 거절될 수 있습니다.</p><br>
                <p>코인구매를 통해 지급되는 수량은 고객님이 구매 신청했을 시점에 표시되는 예상 구매 수량으로 결정되며, 구매 신청이 완료된 시점 이후로는 그 어떠한 사안으로도 수량 변경이 이루어지지
                    않습니다.</p>
            </div>
            <button @click="togglePopup">확인</button>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { VueSignaturePad } from "@selemondev/vue3-signature-pad"
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, axios, store, onMounted, defineProps, reactive ,ref,watch} = useBaseComponent();

const signature = ref<typeof VueSignaturePad | null>(null);  // null을 초기값으로 설정

let state = reactive({
    isShowPopup: false,
    step: 0,
    symbol: '' as any,

    //check
    allCheck:false,
    check0:false,
    check1:false,
    check2:false,

    //sign
    disabled: false,
    options: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255, 255, 255)'
    },
    file:'' as any
})



onMounted(() => {
    if (store.SignState.data) {
        // console.log(store.SignState.data)
        return;
    } else {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => { router.push('/Trade') } })
    }
    store.setBeforePage(route.path);
})

watch(() => [
    state.check0, state.check1, state.check2
], () => {
    state.allCheck = state.check0 && state.check1 && state.check2;
})

const checkAll = () => {
    if (state.allCheck) {
        state.check0 = true;
        state.check1 = true;
        state.check2 = true;
    } else {
        state.check0 = false;
        state.check1 = false;
        state.check2 = false;
    }
};


const togglePopup = () => {
    state.isShowPopup = !state.isShowPopup;
}

const handleSaveSignature = async () => {
    const data = signature.value?.saveSignature(); 
    let base64Data = data;
    if (base64Data.startsWith('data:image/png;base64,')) {
        base64Data = base64Data.replace('data:image/png;base64,', '');
    }

    base64Data = base64Data.replace(/-/g, '+').replace(/_/g, '/');

    try {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/png' });
        const file = new File([blob], 'filename.png', { type: 'image/png' });

        let formData = new FormData();
        formData.append('file', file);

        let res = await axios.axiosMethods.purchaseCoinUpload({
            idx: store.SignState.data.idx,
            id: store.AccountState.id,
            ticketId: store.AccountState.ticketId,
            file: formData,
        });

        // console.log('handle', res);

        if (res.data.returnCode === 0) {
            confirmSign();
        } else {
            store.setVisibleDialog({
                message: '',
                title: '잘못된 접근입니다.',
                okCancel: false,
                callback: () => {
                    router.push('/Trade');
                },
            });
        }
    } catch (error) {
        // console.error('디코딩실패', error);
    }
};

const confirmSign = async () => {
    let res = await axios.axiosMethods.purchaseCoinSign({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        idx:store.SignState.data.idx,
    })
    if(res.returnCode === 0) {
        store.setVisibleDialog({ message: '', title: '서명하셨습니다.', okCancel: false, callback: () => { router.push('/Trade') } })
    } else {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => {router.push('/Trade') } })
    }
}

const handleClear = (t: string) => {
    if (signature.value) return signature.value.clearCanvas()
};


</script>