<template>
    <Header :initType="'type1'"/>
    <div class="wrap main trade">
        <p class="noticeTitle" style="margin-top: 0;">공지사항</p>
        <div class="notice flex cursor" v-for=" (item,idx) in state.noticeList" :key="idx" @click="router.push('/Notice')">
            <div>
                <p class="notice-title">{{ item.title }}</p>
            </div>
            <img src="@/assets/images/arrow-right.svg" alt="arrow">
        </div>
        <p class="noticeTitle">구매 상품 상태</p>
        <ul class="container" style="background-color: transparent; padding: 0;" v-if="state.login">
            <li class="product" v-for="(item,idx) in state.purchaseList" :key="idx" @click="detailPage(item)">
                <div class="flex">
                    <div class="flex top">
                        <img src="@/assets/images/EPUSDT.png" alt="">
                    </div>
                    <div class="bottom">
                        <!-- <div class="flex" :class="[{'wait':item.state === 0 || item.state === 2}]">
                            <p>현재 상태</p>
                            <p v-if="item.state === 0">관리자 승인 대기중</p>
                            <p v-else-if="item.state === 1" style="color:yellow">관리자 승인</p>
                            <p v-else-if="item.state === 2">관리자 거절</p>
                            <p v-else-if="item.state === 3" style="color:yellowgreen">구매 완료</p>
                            <p v-else-if="item.state === 8" style="color:yellow">사인 필요</p>
                            <p v-else> --- </p>
                        </div> -->
                        <div class="flex">
                            <p>전송 가능 수량</p>
                            <p>{{item.amountTotal}} {{ item.symbol }}</p>
                        </div>
                        <div class="flex">
                            <p>입금 금액</p>
                            <p>{{ formatPrice(item.requestKrw) }} KRW</p>
                        </div>
                        <div class="flex">
                            <p>신청일:</p>
                            <p>{{item.createDate}}</p>
                        </div>
                        <div class="flex">
                            <p>승인일:</p>
                            <p>{{item.acceptDate ? item.acceptDate : '-'}}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="container" style="background: transparent; padding: 0;" v-else>
            <li class="product" style=" text-align: center;" @click="router.push('/Login')">
                로그인 후 이용해주세요.
            </li>
        </ul>
        <p class="noticeTitle">가상자산 시세</p>
        <ul class="container" style="border-radius: 8px;">
            <li class="flex" v-for="(item,idx) in state.binanceList" :key="idx">
                <img class="symbol" :src="require(`@/assets/images/${item.symbol}.png`)" alt="">
                <div class="name"><p>{{item.symbol.substring(0,3)}}</p><p class="sub">USDT</p></div>
                <img class="graph" :src="require(`@/assets/images/${item.symbol}.svg`)" alt="">
                <div class="asset"><p>{{ formatPrice(item.lastPrice) }}</p><p class="sub">{{item.priceChange}}</p></div>
                <div class="increase " :class="item.priceChangePercent !== 0 && item.priceChangePercent > 0 ?'plus':'minus'">{{item.priceChangePercent}}%</div>
            </li>
        </ul>
    </div>
    <Footer />
    <NoticePopup :initIsShow="state.isShowNotice" :initData="state.noticeDetailData" @close ="closeNotice"/>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, axios,store,defineProps, onMounted, reactive,onUnmounted } = useBaseComponent();


let state = reactive({
    login:false,
    isShowNotice:false,

    symbol:'EPIC' as any,
    amountInput:'' as any,
    purchaseList:[] as any,
    symbolAmount:'' as any,
    binanceList:[] as any,
     //paging
    totalRecordSize:0,
    pageSize:5,
    currentPage:1,
    noticeList:[] as any,
    noticeDetailData:{} as any,
    timer:null as any,
})

// onMounted(()=> {
    // })
    
    onMounted(()=>{
    const id = localStorage.getItem('id');
    const ticketId = localStorage.getItem('ticketId');
    if( id && ticketId) {state.login = true};
        
    getNoticeList();
    getBinanceList();

    state.timer = setInterval(() => {
        getBinanceList();
    }, 5000);
    getPurchaseOwner();
    store.setBeforePage(route.path);
});

onUnmounted(() => {
  if (state.timer) {
    clearInterval(state.timer);
  }
});

const getPurchaseOwner = async ()=> {
    let res = await axios.axiosMethods.purchaseCoinOwner({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:999,
        symbol:state.symbol,
    })
    // console.log(res)
    if(res.returnCode === 0){
        state.purchaseList = res.list;
        state.currentPage = res.page.pageNo;
        state.pageSize = res.page.pageSize;
        state.totalRecordSize = res.page.totalRecordSize;
    } else {
        //
    }
}

const getBinanceList = async () => {
 let res = await axios.axiosMethods_Binance.binance();
 console.log(res)
 if(res.data) {
    state.binanceList = res.data;

    state.binanceList.forEach((v:any)=>{
        v.priceChange = parseFloat(v.priceChange).toFixed(2);
        v.priceChangePercent = parseFloat(v.priceChangePercent).toFixed(2); 
    })

 }
};

const getNoticeList = async() => {
    let res = await axios.axiosMethods.newNoticeList ({
        pageNo:1,
        pageSize:10,
    })
    if(res.returnCode === 0) {
        state.noticeList = res.list;
    }
}

const openNotice = (data:any) => {
    state.isShowNotice = true;
    state.noticeDetailData = data;
}

const closeNotice = () => {
    state.isShowNotice = false;
}


const detailPage = (item:any) => {
    // router.push( {
    //     path:'/Detail',
    //     query:{
    //         symbol:symbol,
    //     },
    // });
    store.setWithdrawData(item);
    // 바로 전송으로
    router.push('/Withdraw')
};

const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}


</script>
