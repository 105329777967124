// import dayjs from "dayjs";
import { defineEmits, defineProps, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, watch, nextTick } from "vue";
// import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import axios from "./axios";
import {useStore} from "./pinia"

export default function useBaseComponent() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // const i18n = useI18n({ useScope: "global" });
    const formatWon = (won:any) => { 
        const cleanedWon = won.replace(/,/g, '');
        const roundedWon = Math.round(parseFloat(cleanedWon));
        const formattedWon = new Intl.NumberFormat('ko-KR').format(roundedWon);
        return won = formattedWon;
    }
   
    return {
        axios,
        // dayjs,
        // i18n,
        route,
        router,
        store,
        defineEmits,
        defineProps,
        onBeforeMount,
        onBeforeUnmount,
        onUnmounted,
        onMounted,
        reactive,
        ref,
        watch,
        nextTick,
        formatWon
    };
}
