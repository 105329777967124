import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/secession.svg'


import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'SecessionPage',
  setup(__props) {

const { route, router, defineEmits,axios,store, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

const goConfirm = async() => {
    store.AccountState.secession = true;
    router.push('/Pin');
    // let res = await  axios.axiosMethods.userDelete({
    //     id:store.AccountState.id,
    //     ticketId:store.AccountState.ticketId,
    //     otp:'',
    // })
} 

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '추가정보 변경'
    }),
    _createElementVNode("div", { class: "wrap secession-area flex" }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "box" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "secession"
        }),
        _createElementVNode("p", null, "회원 탈퇴 시 회원 정보를 비롯한 보유 중인 모든 자산(투자 상품에 가입된 자산을 비롯하여 해지가 진행 중인 상품의 코인 수량 포함)이 삭제됩니다. 삭제된 자산에 대한 책임은 사용자에게 있으며 삭제된 자산은 복구되지 않는 점 유의하시기 바랍니다. 남은 자산이 있는지 다시 한번 확인하시기 바랍니다.")
      ], -1)),
      _createElementVNode("button", { onClick: goConfirm }, "탈퇴하기")
    ])
  ], 64))
}
}

})