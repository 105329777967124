<template>
    <Header :initType="'type2'" :initTitle="'알림'" />
    <div class="wrap notice alarm">
        <ul class="container">
            <li class="product" v-for="(item,idx) in state.userLog" :key="idx">
                    <div class="flex">
                        <!-- <div class="flex top">
                            <img src="@/assets/images/EPUSDT.png" alt="">
                        </div> -->
                        <div class="bottom" style="width:100%;">
                            <div class="flex" >
                                <p>타입</p>
                                <p v-if="item.type === 21">[구매] {{ formatPrice(item.data3) }} 원</p>
                                <p v-else-if="item.type === 22">[출금] {{ item.data3 }}</p>
                                <p v-else-if="item.type === 23">[입금] {{ item.data3 }}</p>
                            </div>
                            <div class="flex">
                                <p>수량</p>
                                <p>{{item.amount}} {{ item.symbol }}</p>
                            </div>
                            <div class="flex">
                                <p>일자</p>
                                <p>{{item.createDate}}</p>
                            </div>
                            <div class="flex">
                                <p>주소</p>
                                <p @click="item.data2 ?  goAddress(item.data2):''" style="cursor: pointer; text-decoration: underline;">{{item.data2 ? item.data2.substring(0,16):''}}...</p>
                            </div>
                            <div class="flex" v-if="item.type === 21">
                                <p>Hash</p>
                                <p @click="item.data4 ?  goHash(item.data4):''" style="cursor: pointer; text-decoration: underline;">{{ item.data4 ? item.data4.substring(0,16):''}}...</p>
                            </div>
                        </div>
                    </div>
                </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, axios,store,onMounted,defineEmits, defineProps,  reactive } = useBaseComponent();


let state = reactive({
    userLog:[] as any,
})

onMounted(()=>{
    getUserLog();
    // getAlarm();
    store.setBeforePage(route.path);
})

const getUserLog = async () => {
    let res = await axios.axiosMethods.userLog({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:1,
        pageSize:999,
        symbol:'EPIC',
    })
    if(res.returnCode === 0) {
        state.userLog = res.list;
    }
}


const goHash = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/tx/`+add)
}

const goAddress = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/address/`+add)
}


// const getAlarm = async() => {
//     let res = await axios.axiosMethods.pushList({
//         id:store.AccountState.id,
//         ticketId:store.AccountState.ticketId,
//         pageNo:1,
//         pageSize:999,
//     })
//     console.log(res)
// }


const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}
</script>
