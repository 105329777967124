import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'ServicePage',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '서비스 이용약관'
    }),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "wrap terms-area service" }, [
      _createElementVNode("div", null, [
        _createElementVNode("iframe", {
          src: "https://eight.epic-net.ai/terms.html",
          id: "frame",
          width: "100%",
          height: "100%"
        })
      ])
    ], -1))
  ], 64))
}
}

})