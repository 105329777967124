import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.svg'
import _imports_1 from '@/assets/images/alarm.svg'
import _imports_2 from '@/assets/images/back-header.svg'


const _hoisted_1 = { class: "title" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    initType: {
        type: String,
        default: 'type1',
    },
    initTitle: {
        type: String,
        default: '',
    },
},
  setup(__props) {

const { route, router, defineEmits,axios,store, defineProps, onMounted, reactive } = useBaseComponent();

const props = __props;

let state = reactive({})

const id = localStorage.getItem('id');
const ticketId = localStorage.getItem('ticketId');

onMounted(() => {
    if(!id || !ticketId){
        return;
    } else {
        getUserInfo()
    }
    // console.log(route.path)
});

const getUserInfo = async () => {
    let res = await axios.axiosMethods.userInfo({
        id:id,
        ticketId:ticketId,
    })
    if(res.returnCode === 0) {
        store.AccountState.userInfo = res.data;
    } else {
        store.setVisibleDialog({ title: '오류', message: '티켓이 만료되었습니다.', okCancel: false, callback: () => { 
            // store.logOut(); 
            sessionStorage.clear();
            localStorage.clear();
            router.push('/Login')
        } })
        //
    }
    // if(!res.data.otp) {
    //     router.push('/Pin')
    // }
}

const backPage = () => {
    // sessionStorage.setItem('TradeState', JSON.stringify({ ready: false }));
    // sessionStorage.setItem('PurchaseState', JSON.stringify({ ready: false }));
    // sessionStorage.setItem('AccountState', JSON.stringify({ secession: false }));
    if(route.path === '/Contract' || route.path === '/Privacy' || route.path === '/Detail' || route.path === '/Service' || route.path === '/Withdraw') {
        router.go(-1);
        return;
    }
    store.TradeState.ready = false;
    store.PurchaseState.ready = false;
    store.AccountState.secession = false;

    if(route.path === store.RouteState.beforePage) {
        router.push('/')
    } else if(store.RouteState.beforePage) {
        router.push(store.RouteState.beforePage)
    } else {
        router.go(-1)
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header flex", [{'type1': props.initType === 'type1'},{'type2': props.initType === 'type2'}]])
  }, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "logo",
      class: "logo",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
    }),
    _createElementVNode("img", {
      src: _imports_1,
      alt: "alarm",
      class: "alarm-icon",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/Alarm')))
    }),
    _createElementVNode("img", {
      src: _imports_2,
      alt: "back",
      class: "back",
      onClick: backPage
    }),
    _createElementVNode("p", _hoisted_1, _toDisplayString(props.initTitle), 1)
  ], 2))
}
}

})