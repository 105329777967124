import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrap login info" }
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = ["disabled"]

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'FindPw',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive,axios,store } = useBaseComponent();


let state = reactive({
    beforePwInput:''as any,
    pwInput:'' as any,
    pwCheckInput:'' as any,
    pwPass:false,
    pwError: ''as any,
    pwErrorBefore:'' as any,

    idInput:'' as any,
    nameInput:'' as any,
    phoneInput:'' as any,
})

const phoneCheck = () => {
    state.phoneInput = state.phoneInput.replace(/[^0-9]/g, '');
}

const findPassword = async () => {
    let res =await axios.axiosMethods.userPasswordFind({
        id:state.idInput,
        name:state.nameInput,
        phone:state.phoneInput,
    })
    if(res.returnCode === 0 ) {
        store.setVisibleDialog({ message: '새로운 비밀번호로 로그인 해주세요.', title:res.data,okCancel:false, callback: () => { router.push('/login') }});
    } else {
        store.setVisibleDialog({ title: res.description, message:'',okCancel:false, callback: () => {  }});
    }
}


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '비밀번호 찾기'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createElementVNode("p", null, "아이디", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "아이디를 입력해주세요.",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).idInput) = $event))
          }, null, 512), [
            [_vModelText, _unref(state).idInput]
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "error" }, null, -1)),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "이름", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "이름을 입력해주세요.",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).nameInput) = $event))
          }, null, 512), [
            [_vModelText, _unref(state).nameInput]
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "error" }, null, -1)),
          _cache[7] || (_cache[7] = _createElementVNode("p", null, "연락처", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "연락처를 입력해주세요.",
            maxlength: "11",
            style: {"margin":"9px 0px 13px"},
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).phoneInput) = $event)),
            onInput: phoneCheck
          }, null, 544), [
            [_vModelText, _unref(state).phoneInput]
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "error" }, null, -1))
        ])
      ]),
      _createElementVNode("button", {
        class: "login-btn on",
        disabled: _unref(state).idInput.length <= 0 || _unref(state).nameInput.length <= 0 || _unref(state).phoneInput.length <= 0,
        onClick: findPassword
      }, "비밀번호 찾기", 8, _hoisted_3)
    ])
  ], 64))
}
}

})