<template>
    <Header :initType="'type2'" :initTitle="'언어'" />
    <div class="wrap language-area">
        <ul>
            <li class="flex clickShield">
                <p>English</p><!---->
            </li>
            <li class="flex active">
                <p>한국어</p><img src="@/assets/images/active.svg" alt="active">
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})
</script>
