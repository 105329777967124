<template>
    <div class="header flex type2"  >
        <img src="@/assets/images/back-header.svg" alt="back" class="back" @click="prevStep()">
        <p class="title">{{ state.initTitle[state.step] }}</p>
    </div>
    <div class="wrap login terms" v-if="state.step === 0">
        <div class="login-center">
            <div class="title">
                <p><span>이용약관</span>에<br> 동의해주세요. </p>
            </div>
            <div class="agree">
                <div class="accordion-item">
                    <h2 class="accordion-header flex">
                        <input class="checkbox form-check-input" type="checkbox" id="defaultCheck" v-model="state.allCheck"  @change="checkAll">
                        <label class="form-check-label" for="defaultCheck">이용약관에 모두 동의합니다.</label>
                    </h2>
                    <div class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ul>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck0" v-model="state.check0">
                                    <div class="flex">
                                        <label class="form-check-label cursor" for="defaultCheck0">만 19세 이상입니다. <span>(필수)</span></label><!---->
                                    </div>
                                </li>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck1" v-model="state.check1">
                                    <div class="flex">
                                        <label class="form-check-label cursor" for="defaultCheck1">국제자금세탁방지기구에서 발표한 국제기준 미이행/비협조 국가 또는 지역의 국민, 시민권자, 영주권자,
                                            거주자가 아니며, 해당 국가/지역 체류 중에는 서비스를 이용하지 않을 것을 보증합니다.
                                            <span>(필수)</span>
                                        </label><!---->
                                    </div>
                                </li>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck2" v-model="state.check2">
                                    <div class="flex" @click="router.push('/Service')">
                                        <label class="form-check-label cursor" for="defaultCheck2">서비스 이용약관 <span>(필수)</span>
                                        </label>
                                        <img src="@/assets/images/arrow-right.svg" alt="arrow">
                                    </div>
                                </li>
                                <li class="flex">
                                    <input class="checkbox form-check-input" type="checkbox" id="defaultCheck3" v-model="state.check3">
                                    <div class="flex" @click="router.push('/Privacy')">
                                        <label class="form-check-label cursor" for="defaultCheck3">개인정보 수집/이용 동의 <span>(필수)</span>
                                        </label>
                                        <img src="@/assets/images/arrow-right.svg" alt="arrow">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="login-btn" :disabled="!state.allCheck" :class="{'on':state.allCheck}" @click="nextStep(1)">다음</button>
    </div>
    <div class="wrap login createId" v-else-if="state.step === 1">
        <div class="login-center">
            <h5>만나서 반갑습니다!</h5>
            <p class="sub">사용하실 아이디를 입력해주세요.</p>
            <div class="warn">
                <input type="text" placeholder="아이디를 입력하세요" v-model="state.idInput" @input="checkID" />
                <div style="height: 30px; padding: 10px 0px 0px 15px;">
                    <div>
                        <p class="error" v-if="!state.idPass">{{state.idError}}</p>
                        <p v-else-if="state.idPass">사용 가능한 아이디입니다.</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="login-btn" :disabled="!state.idPass" :class="{'on':state.idPass}" @click="nextStep(3)">다음</button>
    </div>
    <!-- <div class="wrap login recommender" v-else-if="state.step === 2">
        <div class="login-center">
            <h5>추천인 코드를 입력해주세요.</h5>
            <p class="sub">추천인 코드는 최초 가입 시에만 입력할 수 있습니다. (선택사항)</p>
            <div class="warn">
                <input type="text" placeholder="추천인 코드를 입력해주세요." v-model="state.agInput" @input="checkAG">
                <div style="height: 30px; padding: 10px 0px 0px 15px;">
                    <p class="error" v-if="!state.agPass">{{state.agError}}</p>
                    <p v-else-if="state.agPass">{{state.agError}}</p>
                </div>
            </div>
        </div>
        <button class="login-btn on" @click="nextStep(3)">다음</button>
    </div> -->
    <div class="wrap login info" v-else-if="state.step === 3">
        <div class="login-center">
            <div class="title">
                <p><span>추가정보 입력</span>을<br> 완료해주세요. </p>
            </div>
            <div class="mid-form">
                <p>이름</p>
                <input type="text" placeholder="이름을 입력해주세요." v-model="state.nameInput">
                <div class="error">
                    <p style="color:#fc7d61">(코인 상품 구매시 입금자명과 동일하게 입력해주세요)</p>
                </div>
                <!-- <p>연락처</p>
                <input type="text" placeholder="연락처를 입력해주세요." maxlength="11" style="margin: 9px 0px 13px;" v-model="state.phoneInput" @input="phoneCheck"> -->
                <div class="error"><!----></div>
                <p>아이디</p>
                <input type="text" placeholder="아이디를 입력해주세요." class="clickShield" readonly v-bind:value="state.idInput" style="margin: 9px 0px 13px;" disabled />
                <p>비밀번호</p>
                <input type="password" placeholder="10~16자리 입력" v-model="state.pwInput" @input="pwCheck">
                <div class="error">
                    <p>{{ state.pwError }}</p>
                </div>
                <p>비밀번호 확인</p>
                <input type="password" placeholder="10~16자리 입력" v-model="state.pwCheckInput">
                <div class="error">
                    <p v-if="state.pwCheckInput.length > 0 && state.pwInput !== state.pwCheckInput">비밀번호를 확인해주세요.</p>
                </div>
                <p class="ps first">비밀번호는 영문자, 숫자, 특수문자(~@#$%^*)를 포함하여 10-16자리로 만들어주세요. 단, 허용되는 특수문자(~@#$%^*) 외 다른 특수문자는
                    사용할 수 없습니다.</p>
                <p class="ps">포털 사이트나 타 거래소와 동일하거나 비슷한 암호를 설정하지 마세요. 타 사이트에서 암호가 유출될 경우 제3자가 회원님의 계정에 접근할 위험이 있습니다.</p>
                <!-- <p>추천인 코드</p>
                <input type="text" placeholder="" class="clickShield" v-bind:value="state.agPass ? state.agInput:'' " readonly disabled> -->
            </div>
        </div>
        <button class="login-btn on" @click="checkValue">완료</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, axios,store,defineEmits, defineProps, onMounted, reactive,watch } = useBaseComponent();

let state = reactive({
    initTitle:['이용약관 동의','아이디 입력','추천인 코드 입력','추가정보 입력'],
    step:0,
    //step0
    allCheck:false,
    check0:false,
    check1:false,
    check2:false,
    check3:false,
    //step1
    idPass:false,
    idInput:'' as any,
    idError:'' as any,
    //step2
    agInput:'' as any,
    agError:'' as any,
    agPass:false,
    //setp3
    nameInput:'' as any,
    phoneInput:'' as any,
    pwInput: '' as any,
    pwCheckInput:'' as any,
    pwError:'' as any,
})

// onMounted(()=> {
//     const id = localStorage.getItem('id');
//     const ticketId = localStorage.getItem('ticketId');
//     if(id || ticketId) {
//         router.push('/')
//     }
// })

watch(() => [
    state.check0, state.check1, state.check2, state.check3
], () => {
    state.allCheck = state.check0 && state.check1 && state.check2 && state.check3;
})

const checkAll = () => {
    if (state.allCheck) {
        state.check0 = true;
        state.check1 = true;
        state.check2 = true;
        state.check3 = true;
    } else {
        state.check0 = false;
        state.check1 = false;
        state.check2 = false;
        state.check3 = false;
    }
};

const checkID = async() => {
    state.idInput = state.idInput.replace(/[^a-zA-Z0-9]/g, '');
    const idRegex = /^[a-zA-Z0-9]+$/;
    if (!idRegex.test(state.idInput)) {
        state.idPass = false;
        state.idError = '영문과 숫자만 입력 가능합니다.';
        return;
    }

    if(state.idInput.length < 4 ) {
        state.idPass =false;
       state.idError = '아이디는 4자리 이상 입니다.'
    } else if(state.idInput.length > 16 ){
        state.idPass =false;
        state.idError = '아이디는 16자리 이하 입니다.'
    } else if(state.idInput.length >= 4 && state.idInput.length <= 16) {
        let res = await  axios.axiosMethods.referralCodeExist({
            referralCode :state.idInput,
        })
        if(res.returnCode === 0) {
            state.idPass = true;
        } else {
            state.idPass =false;
            state.idError = '사용할 수 없는 아이디입니다.'
        }
    }
} 

const checkAG = async() =>{
    state.agInput = state.agInput.replace(/[^a-zA-Z0-9]/g, '');
    const agRegex = /^[a-zA-Z0-9]*$/;
    if (!agRegex.test(state.agInput)) {
        state.agError = '영문과 숫자만 입력 가능합니다.';
        return;
    }
    if(state.agInput && state.agInput.length >= 4 && state.agInput.length <= 16){
        let res = await  axios.axiosMethods.referralCodeExist({
            referralCode :state.agInput,
        })
        if(res.returnCode === 42) {
            state.agPass = true;
            state.agError = '추천가능한 아이디 입니다.'
        } else {
            state.agPass = false;
            state.agError = '추천인이 존재하지 않습니다.'
        }
    }
} 

// const phoneCheck = () => {
//     state.phoneInput = state.phoneInput.replace(/[^0-9]/g, '');
// }

const pwCheck = () => {
    const pw = state.pwInput;
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^*])[a-zA-Z\d~!@#$%^*]{10,16}$/;
    if (pwPattern.test(pw)) {
        state.pwError = '';
    } else {
        state.pwError ='비밀번호는 10~16자리로, 영문자, 숫자, 특수문자를 모두 포함해야 합니다.';
    }
}

const checkValue = async () => {
    if(state.nameInput.length <= 0) {
        store.setVisibleDialog({ message: '이름을 입력해주세요.', title:'',okCancel:false, callback: () => {  }})
    } 
    // else if(state.phoneInput.length <=0) {
    //     store.setVisibleDialog({ message: '연락처를 입력해주세요.', title:'',okCancel:false, callback: () => {  }})
    // } 
    else if(state.pwCheckInput !== state.pwInput) {
        store.setVisibleDialog({ message: '비밀번호를 확인해주세요.', title:'',okCancel:false, callback: () => {  }})
    } else {
        let res = await axios.axiosMethods.userRegister({
            id: state.idInput,
            // phone: state.phoneInput,
            password: state.pwCheckInput,
            name: state.nameInput,
            referral: state.agInput,
        })
        if(res.returnCode === 0) {
            store.setVisibleDialog({ message: '회원가입에 성공하였습니다.', title:'',okCancel:false, callback: () => {router.push('/Login')  }})
        } else {
            store.setVisibleDialog({ message: '회원가입에 실패하였습니다.', title:'',okCancel:false, callback: () => {window.location.reload() }})
        }
    }
}

const nextStep = (step:number) => {
    state.step = step;
}

const prevStep = () => {
    if(state.step !== 0 ) {
        state.step = state.step - 1 ;
    } else {
        router.push('/login')
    }
}

</script>
