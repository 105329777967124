import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/back-header.svg'
import _imports_1 from '@/assets/images/arrow-right.svg'


const _hoisted_1 = { class: "header flex type2" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "wrap login terms"
}
const _hoisted_4 = { class: "login-center" }
const _hoisted_5 = { class: "agree" }
const _hoisted_6 = { class: "accordion-item" }
const _hoisted_7 = { class: "accordion-header flex" }
const _hoisted_8 = {
  class: "accordion-collapse collapse show",
  "data-bs-parent": "#accordionFlushExample"
}
const _hoisted_9 = { class: "accordion-body" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 1,
  class: "wrap login createId"
}
const _hoisted_16 = { class: "login-center" }
const _hoisted_17 = { class: "warn" }
const _hoisted_18 = { style: {"height":"30px","padding":"10px 0px 0px 15px"} }
const _hoisted_19 = {
  key: 0,
  class: "error"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = {
  key: 2,
  class: "wrap login info"
}
const _hoisted_23 = { class: "login-center" }
const _hoisted_24 = { class: "mid-form" }
const _hoisted_25 = ["value"]
const _hoisted_26 = { class: "error" }
const _hoisted_27 = { class: "error" }
const _hoisted_28 = { key: 0 }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPage',
  setup(__props) {

const { route, router, axios,store,defineEmits, defineProps, onMounted, reactive,watch } = useBaseComponent();

let state = reactive({
    initTitle:['이용약관 동의','아이디 입력','추천인 코드 입력','추가정보 입력'],
    step:0,
    //step0
    allCheck:false,
    check0:false,
    check1:false,
    check2:false,
    check3:false,
    //step1
    idPass:false,
    idInput:'' as any,
    idError:'' as any,
    //step2
    agInput:'' as any,
    agError:'' as any,
    agPass:false,
    //setp3
    nameInput:'' as any,
    phoneInput:'' as any,
    pwInput: '' as any,
    pwCheckInput:'' as any,
    pwError:'' as any,
})

// onMounted(()=> {
//     const id = localStorage.getItem('id');
//     const ticketId = localStorage.getItem('ticketId');
//     if(id || ticketId) {
//         router.push('/')
//     }
// })

watch(() => [
    state.check0, state.check1, state.check2, state.check3
], () => {
    state.allCheck = state.check0 && state.check1 && state.check2 && state.check3;
})

const checkAll = () => {
    if (state.allCheck) {
        state.check0 = true;
        state.check1 = true;
        state.check2 = true;
        state.check3 = true;
    } else {
        state.check0 = false;
        state.check1 = false;
        state.check2 = false;
        state.check3 = false;
    }
};

const checkID = async() => {
    state.idInput = state.idInput.replace(/[^a-zA-Z0-9]/g, '');
    const idRegex = /^[a-zA-Z0-9]+$/;
    if (!idRegex.test(state.idInput)) {
        state.idPass = false;
        state.idError = '영문과 숫자만 입력 가능합니다.';
        return;
    }

    if(state.idInput.length < 4 ) {
        state.idPass =false;
       state.idError = '아이디는 4자리 이상 입니다.'
    } else if(state.idInput.length > 16 ){
        state.idPass =false;
        state.idError = '아이디는 16자리 이하 입니다.'
    } else if(state.idInput.length >= 4 && state.idInput.length <= 16) {
        let res = await  axios.axiosMethods.referralCodeExist({
            referralCode :state.idInput,
        })
        if(res.returnCode === 0) {
            state.idPass = true;
        } else {
            state.idPass =false;
            state.idError = '사용할 수 없는 아이디입니다.'
        }
    }
} 

const checkAG = async() =>{
    state.agInput = state.agInput.replace(/[^a-zA-Z0-9]/g, '');
    const agRegex = /^[a-zA-Z0-9]*$/;
    if (!agRegex.test(state.agInput)) {
        state.agError = '영문과 숫자만 입력 가능합니다.';
        return;
    }
    if(state.agInput && state.agInput.length >= 4 && state.agInput.length <= 16){
        let res = await  axios.axiosMethods.referralCodeExist({
            referralCode :state.agInput,
        })
        if(res.returnCode === 42) {
            state.agPass = true;
            state.agError = '추천가능한 아이디 입니다.'
        } else {
            state.agPass = false;
            state.agError = '추천인이 존재하지 않습니다.'
        }
    }
} 

// const phoneCheck = () => {
//     state.phoneInput = state.phoneInput.replace(/[^0-9]/g, '');
// }

const pwCheck = () => {
    const pw = state.pwInput;
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^*])[a-zA-Z\d~!@#$%^*]{10,16}$/;
    if (pwPattern.test(pw)) {
        state.pwError = '';
    } else {
        state.pwError ='비밀번호는 10~16자리로, 영문자, 숫자, 특수문자를 모두 포함해야 합니다.';
    }
}

const checkValue = async () => {
    if(state.nameInput.length <= 0) {
        store.setVisibleDialog({ message: '이름을 입력해주세요.', title:'',okCancel:false, callback: () => {  }})
    } 
    // else if(state.phoneInput.length <=0) {
    //     store.setVisibleDialog({ message: '연락처를 입력해주세요.', title:'',okCancel:false, callback: () => {  }})
    // } 
    else if(state.pwCheckInput !== state.pwInput) {
        store.setVisibleDialog({ message: '비밀번호를 확인해주세요.', title:'',okCancel:false, callback: () => {  }})
    } else {
        let res = await axios.axiosMethods.userRegister({
            id: state.idInput,
            // phone: state.phoneInput,
            password: state.pwCheckInput,
            name: state.nameInput,
            referral: state.agInput,
        })
        if(res.returnCode === 0) {
            store.setVisibleDialog({ message: '회원가입에 성공하였습니다.', title:'',okCancel:false, callback: () => {router.push('/Login')  }})
        } else {
            store.setVisibleDialog({ message: '회원가입에 실패하였습니다.', title:'',okCancel:false, callback: () => {window.location.reload() }})
        }
    }
}

const nextStep = (step:number) => {
    state.step = step;
}

const prevStep = () => {
    if(state.step !== 0 ) {
        state.step = state.step - 1 ;
    } else {
        router.push('/login')
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "back",
        class: "back",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (prevStep()))
      }),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(state).initTitle[_unref(state).step]), 1)
    ]),
    (_unref(state).step === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "title" }, [
              _createElementVNode("p", null, [
                _createElementVNode("span", null, "이용약관"),
                _createTextVNode("에"),
                _createElementVNode("br"),
                _createTextVNode(" 동의해주세요. ")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    class: "checkbox form-check-input",
                    type: "checkbox",
                    id: "defaultCheck",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).allCheck) = $event)),
                    onChange: checkAll
                  }, null, 544), [
                    [_vModelCheckbox, _unref(state).allCheck]
                  ]),
                  _cache[14] || (_cache[14] = _createElementVNode("label", {
                    class: "form-check-label",
                    for: "defaultCheck"
                  }, "이용약관에 모두 동의합니다.", -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", _hoisted_10, [
                        _withDirectives(_createElementVNode("input", {
                          class: "checkbox form-check-input",
                          type: "checkbox",
                          id: "defaultCheck0",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).check0) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(state).check0]
                        ]),
                        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex" }, [
                          _createElementVNode("label", {
                            class: "form-check-label cursor",
                            for: "defaultCheck0"
                          }, [
                            _createTextVNode("만 19세 이상입니다. "),
                            _createElementVNode("span", null, "(필수)")
                          ])
                        ], -1))
                      ]),
                      _createElementVNode("li", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          class: "checkbox form-check-input",
                          type: "checkbox",
                          id: "defaultCheck1",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).check1) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(state).check1]
                        ]),
                        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex" }, [
                          _createElementVNode("label", {
                            class: "form-check-label cursor",
                            for: "defaultCheck1"
                          }, [
                            _createTextVNode("국제자금세탁방지기구에서 발표한 국제기준 미이행/비협조 국가 또는 지역의 국민, 시민권자, 영주권자, 거주자가 아니며, 해당 국가/지역 체류 중에는 서비스를 이용하지 않을 것을 보증합니다. "),
                            _createElementVNode("span", null, "(필수)")
                          ])
                        ], -1))
                      ]),
                      _createElementVNode("li", _hoisted_12, [
                        _withDirectives(_createElementVNode("input", {
                          class: "checkbox form-check-input",
                          type: "checkbox",
                          id: "defaultCheck2",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).check2) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(state).check2]
                        ]),
                        _createElementVNode("div", {
                          class: "flex",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/Service')))
                        }, _cache[17] || (_cache[17] = [
                          _createElementVNode("label", {
                            class: "form-check-label cursor",
                            for: "defaultCheck2"
                          }, [
                            _createTextVNode("서비스 이용약관 "),
                            _createElementVNode("span", null, "(필수)")
                          ], -1),
                          _createElementVNode("img", {
                            src: _imports_1,
                            alt: "arrow"
                          }, null, -1)
                        ]))
                      ]),
                      _createElementVNode("li", _hoisted_13, [
                        _withDirectives(_createElementVNode("input", {
                          class: "checkbox form-check-input",
                          type: "checkbox",
                          id: "defaultCheck3",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(state).check3) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(state).check3]
                        ]),
                        _createElementVNode("div", {
                          class: "flex",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(router).push('/Privacy')))
                        }, _cache[18] || (_cache[18] = [
                          _createElementVNode("label", {
                            class: "form-check-label cursor",
                            for: "defaultCheck3"
                          }, [
                            _createTextVNode("개인정보 수집/이용 동의 "),
                            _createElementVNode("span", null, "(필수)")
                          ], -1),
                          _createElementVNode("img", {
                            src: _imports_1,
                            alt: "arrow"
                          }, null, -1)
                        ]))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("button", {
            class: _normalizeClass(["login-btn", {'on':_unref(state).allCheck}]),
            disabled: !_unref(state).allCheck,
            onClick: _cache[8] || (_cache[8] = ($event: any) => (nextStep(1)))
          }, "다음", 10, _hoisted_14)
        ]))
      : (_unref(state).step === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _cache[20] || (_cache[20] = _createElementVNode("h5", null, "만나서 반갑습니다!", -1)),
              _cache[21] || (_cache[21] = _createElementVNode("p", { class: "sub" }, "사용하실 아이디를 입력해주세요.", -1)),
              _createElementVNode("div", _hoisted_17, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: "아이디를 입력하세요",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(state).idInput) = $event)),
                  onInput: checkID
                }, null, 544), [
                  [_vModelText, _unref(state).idInput]
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", null, [
                    (!_unref(state).idPass)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_unref(state).idError), 1))
                      : (_unref(state).idPass)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_20, "사용 가능한 아이디입니다."))
                        : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("button", {
              class: _normalizeClass(["login-btn", {'on':_unref(state).idPass}]),
              disabled: !_unref(state).idPass,
              onClick: _cache[10] || (_cache[10] = ($event: any) => (nextStep(3)))
            }, "다음", 10, _hoisted_21)
          ]))
        : (_unref(state).step === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "title" }, [
                  _createElementVNode("p", null, [
                    _createElementVNode("span", null, "추가정보 입력"),
                    _createTextVNode("을"),
                    _createElementVNode("br"),
                    _createTextVNode(" 완료해주세요. ")
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_24, [
                  _cache[22] || (_cache[22] = _createElementVNode("p", null, "이름", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: "이름을 입력해주세요.",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(state).nameInput) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(state).nameInput]
                  ]),
                  _cache[23] || (_cache[23] = _createElementVNode("div", { class: "error" }, [
                    _createElementVNode("p", { style: {"color":"#fc7d61"} }, "(코인 상품 구매시 입금자명과 동일하게 입력해주세요)")
                  ], -1)),
                  _cache[24] || (_cache[24] = _createElementVNode("div", { class: "error" }, null, -1)),
                  _cache[25] || (_cache[25] = _createElementVNode("p", null, "아이디", -1)),
                  _createElementVNode("input", {
                    type: "text",
                    placeholder: "아이디를 입력해주세요.",
                    class: "clickShield",
                    readonly: "",
                    value: _unref(state).idInput,
                    style: {"margin":"9px 0px 13px"},
                    disabled: ""
                  }, null, 8, _hoisted_25),
                  _cache[26] || (_cache[26] = _createElementVNode("p", null, "비밀번호", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    placeholder: "10~16자리 입력",
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(state).pwInput) = $event)),
                    onInput: pwCheck
                  }, null, 544), [
                    [_vModelText, _unref(state).pwInput]
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("p", null, _toDisplayString(_unref(state).pwError), 1)
                  ]),
                  _cache[27] || (_cache[27] = _createElementVNode("p", null, "비밀번호 확인", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    placeholder: "10~16자리 입력",
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(state).pwCheckInput) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(state).pwCheckInput]
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    (_unref(state).pwCheckInput.length > 0 && _unref(state).pwInput !== _unref(state).pwCheckInput)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_28, "비밀번호를 확인해주세요."))
                      : _createCommentVNode("", true)
                  ]),
                  _cache[28] || (_cache[28] = _createElementVNode("p", { class: "ps first" }, "비밀번호는 영문자, 숫자, 특수문자(~@#$%^*)를 포함하여 10-16자리로 만들어주세요. 단, 허용되는 특수문자(~@#$%^*) 외 다른 특수문자는 사용할 수 없습니다.", -1)),
                  _cache[29] || (_cache[29] = _createElementVNode("p", { class: "ps" }, "포털 사이트나 타 거래소와 동일하거나 비슷한 암호를 설정하지 마세요. 타 사이트에서 암호가 유출될 경우 제3자가 회원님의 계정에 접근할 위험이 있습니다.", -1))
                ])
              ]),
              _createElementVNode("button", {
                class: "login-btn on",
                onClick: checkValue
              }, "완료")
            ]))
          : _createCommentVNode("", true)
  ], 64))
}
}

})