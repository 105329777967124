<template>
   <div class="popup popup-notice" v-if="props.initIsShow">
        <div class="popup-inner">
            <p class="title">{{props.initData?.title}}</p>
            <p class="date">{{ props.initData?.createDate}}</p>
            <p class="message" v-html="props.initData?.message"></p>
            <button @click="close">확인</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = defineProps({
    initIsShow: {
        type: Boolean,
        default: false,
    },
    initData: {
        type: Object,
        default: {} as any,
    },
});

let state = reactive({})

const emit = defineEmits(["close"]);

const close = () => {   
    emit("close");
}

</script>
