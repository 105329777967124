import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateIdPage',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

let state = reactive({})

onMounted(()=> {
    if(route.query.pass === 'check'){return;} else {router.push('/Terms')}
})



return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '아이디 입력'
    }),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"wrap login createId\"><div class=\"login-center\"><h5>만나서 반갑습니다!</h5><p class=\"sub\">사용하실 아이디를 입력해주세요.</p><div class=\"warn\"><input type=\"text\" placeholder=\"아이디를 입력하세요\"><div style=\"height:30px;padding:10px 0px 0px 15px;\"><div><p class=\"error\">아이디는 4자리 이상 입니다.</p></div></div></div></div><button class=\"login-btn\">다음</button></div>", 1))
  ], 64))
}
}

})