<template>
    <div class="wrap login">
        <div class="login-center">
            <div class="title">
                <p>EPIC WALLET <br><span>서비스 이용</span></p><!---->
            </div>
            <p class="sub">자산 관리를 시작하기 위해 ID/PW를 입력해주세요.</p>
            <div class="box">
                <p>아이디</p>
                <input type="text" v-model="state.idInput">
            </div>
            <div style="height: 13px; margin: 3.5px 0px; padding: 0px 0px 0px 15px;"><!----></div>
            <div class="box" style="margin: 0px;">
                <p>비밀번호</p>
                <input type="password" v-model="state.pwInput" @keyup.enter="login">
            </div>
            <div style="height: 30px; padding: 10px 0px 0px 15px; margin: 0px 0px 10px;"><!----></div>
            <div class="buttons flex">
                <button @click="router.push('/Register')">회원가입</button>
                <!-- <button class="find" @click="togglePwPopup">비밀번호 찾기</button> -->
                 <!-- <button class="find" @click="router.push('/FindPw')">비밀번호 찾기</button> -->
            </div>
        </div>
        <button class="login-btn on" @click="login">로그인</button>
    </div>
    <div class="popup" v-if="state.isShowPwPopup">
        <div class="popup-inner">
            <p>비밀번호 찾기</p><!----><!---->
            <p class="small">비밀번호 분실과 관련된 사항은 각<br>영업지점으로 연락해주세요.</p>
            <button @click="togglePwPopup">확인</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router,store,axios, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({
    isShowPwPopup:false,
    idInput:''as any,
    pwInput:''as any,
})

// onMounted(()=> {
//     const id = localStorage.getItem('id');
//     const ticketId = localStorage.getItem('ticketId');
//     if(id || ticketId) {
//         router.push('/')
//     }
// })

const togglePwPopup = () => {
    state.isShowPwPopup = !state.isShowPwPopup;
}

const login = async () => {
    let res = await axios.axiosMethods.ticketRequest({
        id:state.idInput,
        password:state.pwInput,
    })
    if(res.returnCode === 0) {
        store.setUserInfo(res.id,res.ticketId);
        router.push('/');
    } else if(res.returnCode === 41) {
        store.setVisibleDialog({ message: '', title:'아이디를 확인해주세요',okCancel:false, callback: () => {}})
    } else if(res.returnCode === 49) {
        store.setVisibleDialog({ message: '', title:'비밀번호를 확인해주세요',okCancel:false, callback: () => {}})
    } else {
        store.setVisibleDialog({ message: res.description, title:'',okCancel:false, callback: () => {}})
    }
}

</script>
