import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrap notice alarm" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  class: "bottom",
  style: {"width":"100%"}
}
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "flex"
}
const _hoisted_14 = ["onClick"]

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'AlarmPage',
  setup(__props) {

const { route, router, axios,store,onMounted,defineEmits, defineProps,  reactive } = useBaseComponent();


let state = reactive({
    userLog:[] as any,
})

onMounted(()=>{
    getUserLog();
    // getAlarm();
    store.setBeforePage(route.path);
})

const getUserLog = async () => {
    let res = await axios.axiosMethods.userLog({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:1,
        pageSize:999,
        symbol:'EPIC',
    })
    if(res.returnCode === 0) {
        state.userLog = res.list;
    }
}


const goHash = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/tx/`+add)
}

const goAddress = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/address/`+add)
}


// const getAlarm = async() => {
//     let res = await axios.axiosMethods.pushList({
//         id:store.AccountState.id,
//         ticketId:store.AccountState.ticketId,
//         pageNo:1,
//         pageSize:999,
//     })
//     console.log(res)
// }


const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '알림'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).userLog, (item, idx) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "product",
            key: idx
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[0] || (_cache[0] = _createElementVNode("p", null, "타입", -1)),
                  (item.type === 21)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, "[구매] " + _toDisplayString(formatPrice(item.data3)) + " 원", 1))
                    : (item.type === 22)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_7, "[출금] " + _toDisplayString(item.data3), 1))
                      : (item.type === 23)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_8, "[입금] " + _toDisplayString(item.data3), 1))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[1] || (_cache[1] = _createElementVNode("p", null, "수량", -1)),
                  _createElementVNode("p", null, _toDisplayString(item.amount) + " " + _toDisplayString(item.symbol), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[2] || (_cache[2] = _createElementVNode("p", null, "일자", -1)),
                  _createElementVNode("p", null, _toDisplayString(item.createDate), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[3] || (_cache[3] = _createElementVNode("p", null, "주소", -1)),
                  _createElementVNode("p", {
                    onClick: ($event: any) => (item.data2 ?  goAddress(item.data2):''),
                    style: {"cursor":"pointer","text-decoration":"underline"}
                  }, _toDisplayString(item.data2 ? item.data2.substring(0,16):'') + "...", 9, _hoisted_12)
                ]),
                (item.type === 21)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _cache[4] || (_cache[4] = _createElementVNode("p", null, "Hash", -1)),
                      _createElementVNode("p", {
                        onClick: ($event: any) => (item.data4 ?  goHash(item.data4):''),
                        style: {"cursor":"pointer","text-decoration":"underline"}
                      }, _toDisplayString(item.data4 ? item.data4.substring(0,16):'') + "...", 9, _hoisted_14)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}
}

})