import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/active.svg'


import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeLanguage',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '언어'
    }),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "wrap language-area" }, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", { class: "flex clickShield" }, [
          _createElementVNode("p", null, "English")
        ]),
        _createElementVNode("li", { class: "flex active" }, [
          _createElementVNode("p", null, "한국어"),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "active"
          })
        ])
      ])
    ], -1))
  ], 64))
}
}

})