<template>
    <Header :initType="'type2'" :initTitle="`전송 ${state.data.symbol}`" />
    <div class="wrap withdraw login">
        <div class="login-center">
            <p class="title">{{state.data.amountTotal}} <span>{{state.data.symbol}}</span> Available</p>
            <!-- <div class="price">
                <input type="number" placeholder="0">
                <p>{{state.data.symbol}}</p>
            </div> -->
            <div class="error"><!----></div>
            <!-- <p class="won">{{ state.data.requestKrw }} <span>KRW</span></p> -->
            <h6>받으실 분</h6>
            <input type="text" placeholder="받으실 분 주소나 아이디를 입력하세요." class="ad" style="background: rgb(45, 45, 45);" v-model="state.addressInput"/>
            <div class="ps">
                <p>전송 전 꼭 알아두세요!</p>
                <p>-본인인증을 한 계정에 한해서 출금이 가능합니다.</p>
                <p>- 암호 화폐의 특정상 출근 신청이 완료되면 취소할 없으며, EPIC은 지갑으로만 송금 가능합니다.</p>
                <p>- 출금 신청 완료 이후의 과정은 블록체인 네트워크에서 처리되며, 발생할 수 있는 지연 등의 문제는 EPIC에서 처리가 불가능합니다.</p>
                <p>- 전송이 완료 되면, 구매했던 상품 지갑의 소유권이 전송받은 수신자로 이전 됩니다.</p>
            </div>
        </div>
        <button class="login-btn on" @click="goWithdraw">출금하기</button>
    </div>
</template>
<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, axios, store, onMounted, defineProps, reactive } = useBaseComponent();


let state = reactive({
    symbol: '' as any,
    signImg: '' as any,
    data: { } as any,
    addressInput: '' as any
})

onMounted(() => {
    if (!store.TradeState.withdrawData) {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => { router.push('/') } })
    } else {
        state.data = store.TradeState.withdrawData;
    }

    // console.log(store.TradeState.withdrawData)
    store.setBeforePage(route.path);
})

const goWithdraw = () => {
    if(state.addressInput.length <= 0) {
        store.setVisibleDialog({ message: '', title: '주소 혹은 아이디를 입력해주세요.', okCancel: false, callback: () => { } })
    } else {
        store.TradeState.to = state.addressInput;
        store.TradeState.ready = true;
        router.push('/Pin')
    }
}
</script>