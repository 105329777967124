import axios, { AxiosInstance } from 'axios';
import binanceURL from "./binanceURL";


// Create axios instance for your API
const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Use default value if environment variable is not defined
    transformRequest: [(data) => JSON.stringify(data)],
    transformResponse: [(data) => JSON.parse(data)],
    headers: {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
    },
});

const axiosInstanceFile: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    transformRequest: [(data) => JSON.stringify(data)],
    transformResponse: [(data) => JSON.parse(data)],
    headers: {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'multipart/form-data',
        Pragma: 'no-cache',
    },
});

const axiosMethods_Binance = {
    binance(): Promise<any> {
        const symbols = '["BTCUSDT","ETHUSDT","XRPUSDT","TRXUSDT"]'
        return binanceURL.get(`/api/v3/ticker/24hr?symbols=${encodeURIComponent(symbols)}`);
    },
    priceChangeStatistics(symbolArray: any): Promise<any> {
        //Rolling window price change statistics        
        //const symbols = '["BTCUSDT","ETHUSDT","BNBUSDT","ADAUSDT","XRPUSDT","TRXUSDT","USDCUSDT","SOLUSDT"]'
        const symbols = "[\"" + Array.prototype.join.call(symbolArray, "\",\"") + "\"]";
        return binanceURL.get(`/api/v3/ticker?symbols=${encodeURIComponent(symbols)}`);
    },
    kline(symbol : any, interval : any): Promise<any> {
        //return binanceURL.get(`/api/v3/klines?symbol=BTCUSDT&interval=1M`);
        return binanceURL.get(`/api/v3/klines?symbol=` + symbol + `&interval=` + interval);
    },
}


// Function to handle common axios responses
const axiosCommonResponse = async (url: string, data?: any): Promise<any> => {
    try {
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        // Handle the error as needed, e.g., log it or throw it
        console.error('Error in axiosCommonResponse:', error);
        throw error;
    }
};

// Define axios methods for API requests
const axiosMethods = {
    // 공지사항 리스트
    newNoticeList(param: any): Promise<any> {
        return axiosCommonResponse('/v2/newNotice/list', param);
    },
    // 구매요청전 팝업
    purchaseCoinNotice(param: any): Promise<any> {
        return axiosCommonResponse('/v2/purchaseCoin/notice', param);
    },
    // 구매요청
    purchaseCoinDeposit(param: any): Promise<any> {
        return axiosCommonResponse('/v2/purchaseCoin/deposit', param);
    },
    // 구매 리스트
    purchaseCoinList(param: any): Promise<any> {
        return axiosCommonResponse('/v2/purchaseCoin/list', param);
    },
    // 구매 보유 리스트
    purchaseCoinOwner(param: any): Promise<any> {
        return axiosCommonResponse('/v2/purchaseCoin/owner', param);
    },
    // 전송요청
    purchaseCoinSend(param: any): Promise<any> {
        return axiosCommonResponse('/v2/purchaseCoin/send', param);
    },
    // 구매 사인
    purchaseCoinSign(param: any): Promise<any> {
        return axiosCommonResponse('/v2/purchaseCoin/sign', param);
    },
    //구매 사인 파일 업로드
    purchaseCoinUpload(param: any): Promise<any> {
        return axiosInstanceFile.post("/v2/purchaseCoin/upload/" + param.idx + "/" + param.id + "/" + param.ticketId , param.file,{
            headers: {
                "Content-Type": "multipart/form-data",
            },
            transformRequest: [
                () => {
                    return param.file;
                },
            ],
        });
    },
    //구매 사인 파일 업로드 테스트
    purchaseCoinTest(param: any): Promise<any> {
        return axiosInstanceFile.post("/v2/purchaseCoin/test/" + param.idx + "/" + param.id + "/" + param.ticketId , param.file,{
            headers: {
                "Content-Type": "multipart/form-data",
            },
            transformRequest: [
                () => {
                    return param.file;
                },
            ],
        });
    },
    //알림 푸쉬 리스트
    pushList(param: any): Promise<any> {
        return axiosCommonResponse('/v2/push/list', param);
    },
    //레퍼럴 조회
    referralCodeExist(param: any): Promise<any> {
        return axiosCommonResponse('/v2/referralCode/exist', param);
    },
    //티켓요청
    ticketRequest(param: any): Promise<any> {
        return axiosCommonResponse('/v2/ticket/request', param);
    },
    //토큰별 차트
    tokenChart(param: any): Promise<any> {
        return axiosCommonResponse('/v2/token/chart', param);
    },
    //토큰별 정보
    tokenInfo(param: any): Promise<any> {
        return axiosCommonResponse('/v2/token/info', param);
    },
    //토큰별 시세
    tokenPrice(param: any): Promise<any> {
        return axiosCommonResponse('/v2/token/price', param);
    },
    //비밀번호 변경 with Ticket
    changePwTicket(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/changePasswordWithTicket', param);
    },
    //아이디 조회
    userExist(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/exist', param);
    },
    //유저 정보
    userInfo(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/info', param);
    },
    //유저 로그 리스트
    userLog(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/log', param);
    },
    //유저 로그 조회 idx
    userLogDetail(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/log/detail', param);
    },
    //회원가입
    userRegister(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/register', param);
    },
    //유저 코인
    userToken(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/token', param);
    },
    //유저정보 수정
    userUpdate(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/update', param);
    },
    //비밀번호 찾기
    userPasswordFind(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/password/find', param);
    },
    //OTP
    userOtpSet(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/otp/set', param);
    },
    //OTP
    userDelete(param: any): Promise<any> {
        return axiosCommonResponse('/v2/user/delete', param);
    },
};

export default { axiosInstance, axiosMethods, axiosCommonResponse,axiosMethods_Binance };
