import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/arrow-right.svg'


const _hoisted_1 = { class: "wrap login terms" }
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = { class: "agree" }
const _hoisted_4 = { class: "accordion-item" }
const _hoisted_5 = { class: "accordion-header flex" }
const _hoisted_6 = {
  class: "accordion-collapse collapse show",
  "data-bs-parent": "#accordionFlushExample"
}
const _hoisted_7 = { class: "accordion-body" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = ["disabled"]

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'TermsPage',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive,watch } = useBaseComponent();


let state = reactive({
    allCheck:false,
    check0:false,
    check1:false,
    check2:false,
    check3:false,
})

watch(() => [
    state.check0, state.check1, state.check2, state.check3
], () => {
    state.allCheck = state.check0 && state.check1 && state.check2 && state.check3;
})

const checkAll = () => {
    if (state.allCheck) {
        state.check0 = true;
        state.check1 = true;
        state.check2 = true;
        state.check3 = true;
    } else {
        state.check0 = false;
        state.check1 = false;
        state.check2 = false;
        state.check3 = false;
    }
};

const nextStep = () => {
    router.push({
        path:'/CreateId',
        query:{pass:'check'}
    })
}


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '이용약관 동의'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "title" }, [
          _createElementVNode("p", null, [
            _createElementVNode("span", null, "이용약관"),
            _createTextVNode("에"),
            _createElementVNode("br"),
            _createTextVNode(" 동의해주세요. ")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                class: "checkbox form-check-input",
                type: "checkbox",
                id: "defaultCheck",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).allCheck) = $event)),
                onChange: checkAll
              }, null, 544), [
                [_vModelCheckbox, _unref(state).allCheck]
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                class: "form-check-label",
                for: "defaultCheck"
              }, "이용약관에 모두 동의합니다.", -1))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      class: "checkbox form-check-input",
                      type: "checkbox",
                      id: "defaultCheck0",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).check0) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _unref(state).check0]
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex" }, [
                      _createElementVNode("label", {
                        class: "form-check-label cursor",
                        for: "defaultCheck0"
                      }, [
                        _createTextVNode("만 19세 이상입니다. "),
                        _createElementVNode("span", null, "(필수)")
                      ])
                    ], -1))
                  ]),
                  _createElementVNode("li", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      class: "checkbox form-check-input",
                      type: "checkbox",
                      id: "defaultCheck1",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).check1) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _unref(state).check1]
                    ]),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex" }, [
                      _createElementVNode("label", {
                        class: "form-check-label cursor",
                        for: "defaultCheck1"
                      }, [
                        _createTextVNode("국제자금세탁방지기구에서 발표한 국제기준 미이행/비협조 국가 또는 지역의 국민, 시민권자, 영주권자, 거주자가 아니며, 해당 국가/지역 체류 중에는 서비스를 이용하지 않을 것을 보증합니다. "),
                        _createElementVNode("span", null, "(필수)")
                      ])
                    ], -1))
                  ]),
                  _createElementVNode("li", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      class: "checkbox form-check-input",
                      type: "checkbox",
                      id: "defaultCheck2",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).check2) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _unref(state).check2]
                    ]),
                    _createElementVNode("div", {
                      class: "flex",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/Service')))
                    }, _cache[10] || (_cache[10] = [
                      _createElementVNode("label", {
                        class: "form-check-label cursor",
                        for: "defaultCheck2"
                      }, [
                        _createTextVNode("서비스 이용약관 "),
                        _createElementVNode("span", null, "(필수)")
                      ], -1),
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "arrow"
                      }, null, -1)
                    ]))
                  ]),
                  _createElementVNode("li", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      class: "checkbox form-check-input",
                      type: "checkbox",
                      id: "defaultCheck3",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(state).check3) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _unref(state).check3]
                    ]),
                    _createElementVNode("div", {
                      class: "flex",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(router).push('/Privacy')))
                    }, _cache[11] || (_cache[11] = [
                      _createElementVNode("label", {
                        class: "form-check-label cursor",
                        for: "defaultCheck3"
                      }, [
                        _createTextVNode("개인정보 수집/이용 동의 "),
                        _createElementVNode("span", null, "(필수)")
                      ], -1),
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "arrow"
                      }, null, -1)
                    ]))
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("button", {
        class: _normalizeClass(["login-btn", {'on':_unref(state).allCheck}]),
        disabled: !_unref(state).allCheck,
        onClick: nextStep
      }, "다음", 10, _hoisted_12)
    ])
  ], 64))
}
}

})