<template>
    <Header :initType="'type2'" :initTitle="'비밀번호 찾기'" />
    <div class="wrap login info">
        <div class="login-center">
            <div>
                <p>아이디</p>
                <input type="text" placeholder="아이디를 입력해주세요." v-model="state.idInput" />
                <div class="error"><!----></div>
                <p>이름</p>
                <input type="text" placeholder="이름을 입력해주세요." v-model="state.nameInput">
                <div class="error"><!----></div>
                <p>연락처</p>
                <input type="text" placeholder="연락처를 입력해주세요." maxlength="11" style="margin: 9px 0px 13px;" v-model="state.phoneInput" @input="phoneCheck">
                <div class="error"><!----></div>
            </div>
        </div>
        <button class="login-btn on" :disabled="state.idInput.length <= 0 || state.nameInput.length <= 0 || state.phoneInput.length <= 0" @click="findPassword">비밀번호 찾기</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive,axios,store } = useBaseComponent();


let state = reactive({
    beforePwInput:''as any,
    pwInput:'' as any,
    pwCheckInput:'' as any,
    pwPass:false,
    pwError: ''as any,
    pwErrorBefore:'' as any,

    idInput:'' as any,
    nameInput:'' as any,
    phoneInput:'' as any,
})

const phoneCheck = () => {
    state.phoneInput = state.phoneInput.replace(/[^0-9]/g, '');
}

const findPassword = async () => {
    let res =await axios.axiosMethods.userPasswordFind({
        id:state.idInput,
        name:state.nameInput,
        phone:state.phoneInput,
    })
    if(res.returnCode === 0 ) {
        store.setVisibleDialog({ message: '새로운 비밀번호로 로그인 해주세요.', title:res.data,okCancel:false, callback: () => { router.push('/login') }});
    } else {
        store.setVisibleDialog({ title: res.description, message:'',okCancel:false, callback: () => {  }});
    }
}

</script>
