import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popup popup-notice"
}
const _hoisted_2 = { class: "popup-inner" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = ["innerHTML"]

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'NoticePopup',
  props: {
    initIsShow: {
        type: Boolean,
        default: false,
    },
    initData: {
        type: Object,
        default: {} as any,
    },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = __props;

let state = reactive({})

const emit = __emit;

const close = () => {   
    emit("close");
}


return (_ctx: any,_cache: any) => {
  return (props.initIsShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(props.initData?.title), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(props.initData?.createDate), 1),
          _createElementVNode("p", {
            class: "message",
            innerHTML: props.initData?.message
          }, null, 8, _hoisted_5),
          _createElementVNode("button", { onClick: close }, "확인")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})