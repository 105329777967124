import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/copy.svg'
import _imports_1 from '@/assets/images/certify-my.svg'
import _imports_2 from '@/assets/images//certify-phone.svg'
import _imports_3 from '@/assets/images/arrow-right.svg'


const _hoisted_1 = { class: "wrap my" }
const _hoisted_2 = { class: "box box1" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "text-truncate" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "box box1" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "text-truncate" }
const _hoisted_9 = { class: "box box2" }
const _hoisted_10 = { class: "flex cursor" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "box box3" }
const _hoisted_13 = { class: "box box4" }
const _hoisted_14 = {
  key: 0,
  class: "popup"
}

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'MyPage',
  setup(__props) {

const { route, router, store,defineEmits,axios,onMounted, defineProps, reactive } = useBaseComponent();



let state = reactive({
    isShowLogout: false,
})

onMounted(()=> {
    store.setBeforePage(route.path)
})

const logOut = () =>{
   store.logOut()
}

const toggleLogoutPopup = () => {
    state.isShowLogout = !state.isShowLogout;
}

const copyText = (text:string) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
        .then(() => {
            store.setVisibleToast({ message: '복사되었습니다.', isTypeWarning:false, duration: 2000 });
        })
        .catch((err) => {
            // alert("복사 실패: " + err);
        });
    } else {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        store.setVisibleToast({ message: '주소가 복사되었습니다.', isTypeWarning:false, duration: 2000 });
    }
}


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { initType: 'type1' }),
    _createElementVNode("div", _hoisted_1, [
      _cache[15] || (_cache[15] = _createElementVNode("h6", null, "아이디 / 추천인코드", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("p", null, "아이디", -1)),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(store).AccountState.userInfo.referralCode), 1),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "clipboard",
            class: "copy",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (copyText(_unref(store).AccountState.userInfo.referralCode)))
          })
        ])
      ]),
      _cache[16] || (_cache[16] = _createElementVNode("h6", { class: "certify-box" }, "지갑주소", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("p", null, "ERC20", -1)),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(store).AccountState.userInfo.erc20Address), 1),
            _createElementVNode("img", {
              src: _imports_0,
              alt: "clipboard",
              class: "copy",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (copyText(_unref(store).AccountState.userInfo.erc20Address)))
            })
          ])
        ]),
        _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"certify-box\"><h6>인증센터</h6><div class=\"flex\"><div class=\"left item-box\"><img src=\"" + _imports_1 + "\" alt=\"\"><p class=\"text\">본인인증</p><p class=\"text sub\">인증완료</p></div><div class=\"right item-box\"><img src=\"" + _imports_2 + "\" alt=\"\"><p class=\"text\">본인인증</p><p class=\"text sub\">인증완료</p></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", _hoisted_10, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "title" }, "이름", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", null, _toDisplayString(_unref(store).AccountState.userInfo.name), 1)
              ])
            ]),
            _createElementVNode("li", {
              class: "flex cursor",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/Notice')))
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("p", null, "공지사항", -1),
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_3,
                  alt: "arrow"
                })
              ], -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", {
              class: "flex cursor",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/Language')))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("p", { class: "title" }, "언어", -1),
              _createElementVNode("div", { class: "language" }, "한국어", -1)
            ])),
            _createElementVNode("li", {
              class: "flex cursor",
              onClick: toggleLogoutPopup
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("p", { class: "title" }, "로그아웃", -1),
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_3,
                  alt: "arrow"
                })
              ], -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", {
              class: "flex cursor",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/SelectTerms')))
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("p", { class: "title" }, "약관 및 정책", -1),
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_3,
                  alt: "arrow"
                })
              ], -1)
            ])),
            _createElementVNode("li", {
              class: "flex secession cursor",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/Secession')))
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("p", { class: "title" }, [
                _createTextVNode("회원탈퇴 "),
                _createElementVNode("span", null, "개인 정보와 설정이 모두 삭제됩니다.")
              ], -1),
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_3,
                  alt: "arrow"
                })
              ], -1)
            ]))
          ])
        ])
      ])
    ]),
    (_unref(state).isShowLogout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", { class: "popup-inner" }, [
            _cache[17] || (_cache[17] = _createElementVNode("p", null, [
              _createTextVNode("로그아웃이 진행됩니다."),
              _createElementVNode("br"),
              _createTextVNode("필요시 재로그인 해주세요.")
            ], -1)),
            _createElementVNode("div", { class: "buttons flex" }, [
              _createElementVNode("button", {
                class: "close",
                onClick: toggleLogoutPopup
              }, "취소"),
              _createElementVNode("button", { onClick: logOut }, "로그아웃")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Footer)
  ], 64))
}
}

})