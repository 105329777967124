import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/arrow-right.svg'
import _imports_1 from '@/assets/images/EPUSDT.png'


const _hoisted_1 = { class: "wrap main trade" }
const _hoisted_2 = { class: "notice-title" }
const _hoisted_3 = {
  key: 0,
  class: "container",
  style: {"background-color":"transparent","padding":"0"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "bottom" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = {
  key: 1,
  class: "container",
  style: {"background":"transparent","padding":"0"}
}
const _hoisted_12 = {
  class: "container",
  style: {"border-radius":"8px"}
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "name" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "asset" }
const _hoisted_17 = { class: "sub" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

const { route, router, defineEmits, axios,store,defineProps, onMounted, reactive,onUnmounted } = useBaseComponent();


let state = reactive({
    login:false,
    isShowNotice:false,

    symbol:'EPIC' as any,
    amountInput:'' as any,
    purchaseList:[] as any,
    symbolAmount:'' as any,
    binanceList:[] as any,
     //paging
    totalRecordSize:0,
    pageSize:5,
    currentPage:1,
    noticeList:[] as any,
    noticeDetailData:{} as any,
    timer:null as any,
})

// onMounted(()=> {
    // })
    
    onMounted(()=>{
    const id = localStorage.getItem('id');
    const ticketId = localStorage.getItem('ticketId');
    if( id && ticketId) {state.login = true};
        
    getNoticeList();
    getBinanceList();

    state.timer = setInterval(() => {
        getBinanceList();
    }, 5000);
    getPurchaseOwner();
    store.setBeforePage(route.path);
});

onUnmounted(() => {
  if (state.timer) {
    clearInterval(state.timer);
  }
});

const getPurchaseOwner = async ()=> {
    let res = await axios.axiosMethods.purchaseCoinOwner({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:999,
        symbol:state.symbol,
    })
    // console.log(res)
    if(res.returnCode === 0){
        state.purchaseList = res.list;
        state.currentPage = res.page.pageNo;
        state.pageSize = res.page.pageSize;
        state.totalRecordSize = res.page.totalRecordSize;
    } else {
        //
    }
}

const getBinanceList = async () => {
 let res = await axios.axiosMethods_Binance.binance();
 console.log(res)
 if(res.data) {
    state.binanceList = res.data;

    state.binanceList.forEach((v:any)=>{
        v.priceChange = parseFloat(v.priceChange).toFixed(2);
        v.priceChangePercent = parseFloat(v.priceChangePercent).toFixed(2); 
    })

 }
};

const getNoticeList = async() => {
    let res = await axios.axiosMethods.newNoticeList ({
        pageNo:1,
        pageSize:10,
    })
    if(res.returnCode === 0) {
        state.noticeList = res.list;
    }
}

const openNotice = (data:any) => {
    state.isShowNotice = true;
    state.noticeDetailData = data;
}

const closeNotice = () => {
    state.isShowNotice = false;
}


const detailPage = (item:any) => {
    // router.push( {
    //     path:'/Detail',
    //     query:{
    //         symbol:symbol,
    //     },
    // });
    store.setWithdrawData(item);
    // 바로 전송으로
    router.push('/Withdraw')
};

const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}



return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_NoticePopup = _resolveComponent("NoticePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { initType: 'type1' }),
    _createElementVNode("div", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("p", {
        class: "noticeTitle",
        style: {"margin-top":"0"}
      }, "공지사항", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).noticeList, (item, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "notice flex cursor",
          key: idx,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/Notice')))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(item.title), 1)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_0,
            alt: "arrow"
          }, null, -1))
        ]))
      }), 128)),
      _cache[10] || (_cache[10] = _createElementVNode("p", { class: "noticeTitle" }, "구매 상품 상태", -1)),
      (_unref(state).login)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).purchaseList, (item, idx) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "product",
                key: idx,
                onClick: ($event: any) => (detailPage(item))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex top" }, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    })
                  ], -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[3] || (_cache[3] = _createElementVNode("p", null, "전송 가능 수량", -1)),
                      _createElementVNode("p", null, _toDisplayString(item.amountTotal) + " " + _toDisplayString(item.symbol), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("p", null, "입금 금액", -1)),
                      _createElementVNode("p", null, _toDisplayString(formatPrice(item.requestKrw)) + " KRW", 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[5] || (_cache[5] = _createElementVNode("p", null, "신청일:", -1)),
                      _createElementVNode("p", null, _toDisplayString(item.createDate), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[6] || (_cache[6] = _createElementVNode("p", null, "승인일:", -1)),
                      _createElementVNode("p", null, _toDisplayString(item.acceptDate ? item.acceptDate : '-'), 1)
                    ])
                  ])
                ])
              ], 8, _hoisted_4))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("ul", _hoisted_11, [
            _createElementVNode("li", {
              class: "product",
              style: {"text-align":"center"},
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/Login')))
            }, " 로그인 후 이용해주세요. ")
          ])),
      _cache[11] || (_cache[11] = _createElementVNode("p", { class: "noticeTitle" }, "가상자산 시세", -1)),
      _createElementVNode("ul", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).binanceList, (item, idx) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "flex",
            key: idx
          }, [
            _createElementVNode("img", {
              class: "symbol",
              src: require(`@/assets/images/${item.symbol}.png`),
              alt: ""
            }, null, 8, _hoisted_13),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", null, _toDisplayString(item.symbol.substring(0,3)), 1),
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "sub" }, "USDT", -1))
            ]),
            _createElementVNode("img", {
              class: "graph",
              src: require(`@/assets/images/${item.symbol}.svg`),
              alt: ""
            }, null, 8, _hoisted_15),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("p", null, _toDisplayString(formatPrice(item.lastPrice)), 1),
              _createElementVNode("p", _hoisted_17, _toDisplayString(item.priceChange), 1)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["increase", item.priceChangePercent !== 0 && item.priceChangePercent > 0 ?'plus':'minus'])
            }, _toDisplayString(item.priceChangePercent) + "%", 3)
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_Footer),
    _createVNode(_component_NoticePopup, {
      initIsShow: _unref(state).isShowNotice,
      initData: _unref(state).noticeDetailData,
      onClose: closeNotice
    }, null, 8, ["initIsShow", "initData"])
  ], 64))
}
}

})