import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
//Pc
import Home from "@/views/Home.vue";
import Wallet from "@/views/WalletPage.vue";
import Trade from "@/views/TradePage.vue";
import My from "@/views/MyPage.vue";
import Menu from "@/views/MenuPage.vue";
import Sign from "@/views/SignPage.vue";
import Notice from "@/views/NoticePage.vue";
import Alarm from "@/views/AlarmPage.vue";
import Detail from "@/views/DetailPage.vue";
import Contract from "@/views/ContractPage.vue";
import Withdraw from "@/views/WithdrawPage.vue";
import Pin from "@/views/PinPage.vue";
import Language from "@/views/Menu/ChangeLanguage.vue";
import ChangeInfo from "@/views/Menu/ChangeInfo.vue";
import ChangePw from "@/views/Menu/ChangePw.vue";
import FindPw from "@/views/Menu/FindPw.vue";
import SelectTerms from "@/views/Menu/SelectTerms.vue";
import Service from "@/views/Menu/ServicePage.vue";
import Privacy from "@/views/Menu/PrivacyPage.vue";
import Secession from "@/views/Menu/SecessionPage.vue";
import Login from "@/views/Register/LoginPage.vue";
import Terms from "@/views/Register/TermsPage.vue";
import CreateId from "@/views/Register/CreateIdPage.vue";
import Recommender from "@/views/Register/RecommenderPage.vue";
import Info from "@/views/Register/InfoPage.vue";
import Register from "@/views/Register/RegisterPage.vue"


// import { addListener, launch } from 'devtools-detector';
const view = document.createElement('div');
document.body.appendChild(view);

const appName = process.env.VUE_APP_TITLE?.toString();

const routes: Array<RouteRecordRaw> = [
    //Pc
    { path: "/", name: "Home", component: Home }, // 홈 페이지
    { path: "/Wallet", name: "Wallet", component: Wallet }, // 지갑 페이지
    { path: "/Trade", name: "Trade", component: Trade }, // 토큰 페이지
    { path: "/Detail", name: "Detail", component: Detail }, // 토큰 페이지
    { path: "/My", name: "My", component: My }, // 마이 페이지
    { path: "/Menu", name: "Menu", component: Menu }, // 메뉴 페이지
    { path: "/Withdraw", name: "Withdraw", component: Withdraw }, // 메뉴 페이지
    { path: "/Sign", name: "Sign", component: Sign }, // 서명 페이지
    { path: "/Notice", name: "Notice", component: Notice }, // 공지 페이지
    { path: "/Alarm", name: "Alarm", component: Alarm }, // 알람 페이지
    { path: "/Login", name: "Login", component: Login }, // Login 페이지
    { path: "/Terms", name: "Terms", component: Terms }, // 회원가입 약관 페이지
    { path: "/CreateId", name: "CreateId", component: CreateId }, // 아이디 생성 페이지
    { path: "/Recommender", name: "Recommender", component: Recommender }, // 추천인 등록 페이지
    { path: "/Info", name: "Info", component: Info }, // 추가정보 입력 페이지
    { path: "/Contract", name: "Contract", component: Contract }, // 판매 계약서 페이지
    { path: "/Register", name: "Register", component: Register }, // 회원가입 페이지
    { path: "/Pin", name: "Pin", component: Pin }, // pin 페이지

    { path: "/Language", name: "Language", component: Language }, // 언어설정
    // { path: "/ChangeInfo", name: "ChangeInfo", component: ChangeInfo }, // 정보수정
    { path: "/ChangePw", name: "ChangePw", component: ChangePw }, // 비밀번호 변경
    { path: "/FindPw", name: "FindPw", component: FindPw }, // 비밀번호 찾기
    { path: "/Secession", name: "Secession", component: Secession }, // 회원탈퇴

    { path: "/SelectTerms", name: "SelectTerms", component: SelectTerms }, // 약관 및 정책
    { path: "/Service", name: "Service", component: Service }, // 서비스 이용약관
    { path: "/Privacy", name: "Privacy", component: Privacy }, // 개인정보 처리방침


    // { path: "/m/Message/:idx", name: "M_MessageDetail", component: M_MemoDetail }, // 메세지함
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes,
});

const scrollToTop =()=> {
    window.scrollTo({
      top: 0,
    //   behavior: 'smooth',
    });
  }

  router.beforeEach((to, from, next) => {
    const id = localStorage.getItem('id');
    const ticketId = localStorage.getItem('ticketId');
    
    if (id || ticketId) {
        if (to.path === '/Login' || to.path === '/Register' || to.path === '/FindPw') {
          next('/');  
          return;  
        }
      } else {
        if (to.path !== '/Login' && to.path !== '/Register' && to.path !== '/FindPw' && to.path !== '/' && to.path !== '/Privacy' && to.path !== '/Service' && to.path !== '/Notice') {
          next('/Login'); 
          return; 
        }
      }
    scrollToTop();
    next();
  });



export default router;
