<template>
    <div class="header flex" :class="[{'type1': props.initType === 'type1'},{'type2': props.initType === 'type2'}]" >
        <img src="@/assets/images/logo.svg" alt="logo" class="logo" @click="router.push('/')">
        <img src="@/assets/images/alarm.svg" alt="alarm" class="alarm-icon" @click="router.push('/Alarm')">
        <img src="@/assets/images/back-header.svg" alt="back" class="back" @click="backPage">
        <p class="title">{{ props.initTitle }}</p>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits,axios,store, defineProps, onMounted, reactive } = useBaseComponent();

const props = defineProps({
    initType: {
        type: String,
        default: 'type1',
    },
    initTitle: {
        type: String,
        default: '',
    },
});

let state = reactive({})

const id = localStorage.getItem('id');
const ticketId = localStorage.getItem('ticketId');

onMounted(() => {
    if(!id || !ticketId){
        return;
    } else {
        getUserInfo()
    }
    // console.log(route.path)
});

const getUserInfo = async () => {
    let res = await axios.axiosMethods.userInfo({
        id:id,
        ticketId:ticketId,
    })
    if(res.returnCode === 0) {
        store.AccountState.userInfo = res.data;
    } else {
        store.setVisibleDialog({ title: '오류', message: '티켓이 만료되었습니다.', okCancel: false, callback: () => { 
            // store.logOut(); 
            sessionStorage.clear();
            localStorage.clear();
            router.push('/Login')
        } })
        //
    }
    // if(!res.data.otp) {
    //     router.push('/Pin')
    // }
}

const backPage = () => {
    // sessionStorage.setItem('TradeState', JSON.stringify({ ready: false }));
    // sessionStorage.setItem('PurchaseState', JSON.stringify({ ready: false }));
    // sessionStorage.setItem('AccountState', JSON.stringify({ secession: false }));
    if(route.path === '/Contract' || route.path === '/Privacy' || route.path === '/Detail' || route.path === '/Service' || route.path === '/Withdraw') {
        router.go(-1);
        return;
    }
    store.TradeState.ready = false;
    store.PurchaseState.ready = false;
    store.AccountState.secession = false;

    if(route.path === store.RouteState.beforePage) {
        router.push('/')
    } else if(store.RouteState.beforePage) {
        router.push(store.RouteState.beforePage)
    } else {
        router.go(-1)
    }
}
</script>
