<template>
    <Header :initType="'type2'" :initTitle="'아이디 입력'" />
    <div class="wrap login createId">
        <div class="login-center">
            <h5>만나서 반갑습니다!</h5>
            <p class="sub">사용하실 아이디를 입력해주세요.</p>
            <div class="warn"><input type="text" placeholder="아이디를 입력하세요">
                <div style="height: 30px; padding: 10px 0px 0px 15px;">
                    <div><!----><!---->
                        <p class="error">아이디는 4자리 이상 입니다.</p><!----><!----><!---->
                    </div>
                </div>
            </div>
        </div>
        <button class="login-btn">다음</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

let state = reactive({})

onMounted(()=> {
    if(route.query.pass === 'check'){return;} else {router.push('/Terms')}
})


</script>
