<template>
    <Header :initType="'type1'" />
    <div class="wrap wallet main">
        <div class="cover">
            <div class="canvas-box">
                <div class="container cursor">
                    <div class="title-box flex">
                        <img src="@/assets/images/profile-wallet.svg" alt="">
                        <p>총 보유자산</p>
                    </div>
                    <div class="flex">
                        <div class="money"><p>{{formatPrice(state.totalPrice)}}</p></div>
                        <div class="won">KRW</div>
                    </div>
                    <p class="cyber"></p>
                </div>
                <div class="progress-area">
                    <div style="width: 100%; background: rgb(250, 45, 0);"></div>
                    <div style="width: 0%; background: rgb(104, 126, 227);"></div>
                </div>
                <div class="chart flex container">
                    <div class="chart-cover">
                        <p class="text">보유자산<br>포트폴리오</p>

                    </div>
                    <div class="legend">
                        <div class="legend-inner" v-for="(item,idx) in state.totalWalletInfo" :key="idx">
                            <div class="flex">
                                <div class="circle" :style="{background: item.tokenId === 1 ?'rgb(104, 126, 227)': 'rgb(250, 45, 0)'}"></div>
                                <p class="label">{{item.symbol}}</p>
                                <p class="chart-data">{{item.percentage}}</p>
                            </div>
                        </div>
                        <!-- <div class="legend-inner">
                            <div class="flex">
                                <div class="circle" style="background: rgb(104, 126, 227);"></div>
                                <p class="label">ETH</p>
                                <p class="chart-data">0.00%</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <ul class="container wallet-ul">
            <h6 class="title">토큰</h6>
            <li class="flex token" @click="detailPage(item.symbol)" v-for="(item,idx) in state.totalWalletInfo" :key="idx">
                <img src="@/assets/images/EPUSDT.png" alt="" v-if="item.symbol === 'EPIC'">
                <img src="@/assets/images/ETHUSDT.png" alt="" v-if="item.symbol === 'ETH'">
                <p class="name">{{item.symbol}}</p>
                <div class="price">
                    <p>{{ item.point }} <span>{{item.symbol}}</span></p>
                    <p><span>= {{ formatPrice(item.requestKrw)}} KRW</span></p>
                </div>
            </li>
        </ul>
    </div>
    <Footer />
</template>

<script lang="ts" setup>
import { VueSignaturePad } from "@selemondev/vue3-signature-pad"
import useBaseComponent from "@/baseComponent";
import { format } from "path";
const { route, router, axios, store, onMounted, reactive, } = useBaseComponent();


let state = reactive({
    totalPrice:'',
    totalAmount:'',
    totalWalletInfo:[] as any,
});
onMounted(()=> {
    getUserToken();
    store.setBeforePage(route.path)
})

const detailPage = (symbol:any) => {
    router.push( {
        path:'/Detail',
        query:{
            symbol:symbol,
        },
    });
};

const getUserToken = async() => {
    let res =await axios.axiosMethods.userToken({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        symbol:'',
    })
    if(res.returnCode === 0) {
        state.totalWalletInfo = res.list.reverse();
        state.totalPrice = parseFloat(res.list.reduce((sum: any, item: any) => {
            return sum + (parseFloat(item.price) * item.point);
        }, 0)).toFixed(4);
        state.totalWalletInfo.forEach((v:any) => {
            if(state.totalPrice && Number(state.totalPrice) > 0) {
                const itemTotal = parseFloat(v.price) * v.point;
                const percentage = (itemTotal / parseFloat(state.totalPrice)) * 100;
               
                // console.log(state.totalPrice);
                if(isNaN(v.percentage)){
                    v.percentage = percentage.toFixed(2); 
                } else {
                    v.percentage = '0';
                }
                v.requestKrw = itemTotal;
            }
            else {
                v.percentage = '0';
            }
        });
    }
    // console.log('userToken',state.totalWalletInfo)
}

const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}


</script>
