import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/trade.svg'
import _imports_1 from '@/assets/images/wallet.svg'
import _imports_2 from '@/assets/images/footer-logo.svg'
import _imports_3 from '@/assets/images/my.svg'
import _imports_4 from '@/assets/images/menu.svg'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "row" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(["icon-trade", {'active':_unref(route).name === 'Home'}]),
          src: _imports_0,
          alt: ""
        }, null, 2),
        _createElementVNode("p", {
          class: _normalizeClass({'active':_unref(route).name === 'Home'})
        }, "마켓", 2)
      ]),
      _createElementVNode("div", {
        class: "col",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/Detail?symbol=EPIC')))
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(["icon-wallet", {'active':_unref(route).name === 'Detail'}]),
          src: _imports_1,
          alt: ""
        }, null, 2),
        _createElementVNode("p", {
          class: _normalizeClass({'active':_unref(route).name === 'Detail'})
        }, "지갑", 2)
      ]),
      _createElementVNode("div", {
        class: "col",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/Trade')))
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(["icon-footer-logo", {'active':_unref(route).name === 'Trade'}]),
          src: _imports_2,
          alt: ""
        }, null, 2),
        _createElementVNode("p", {
          class: _normalizeClass({'active':_unref(route).name === 'Trade'})
        }, "토큰구매", 2)
      ]),
      _createElementVNode("div", {
        class: "col",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/My')))
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(["icon-my", {'active':_unref(route).name === 'My'}]),
          src: _imports_3,
          alt: ""
        }, null, 2),
        _createElementVNode("p", {
          class: _normalizeClass({'active':_unref(route).name === 'My'})
        }, "마이페이지", 2)
      ]),
      _createElementVNode("div", {
        class: "col",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/Menu')))
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(["icon-menu", {'active':_unref(route).name === 'Menu'}]),
          src: _imports_4,
          alt: ""
        }, null, 2),
        _createElementVNode("p", {
          class: _normalizeClass({'active':_unref(route).name === 'Menu'})
        }, "메뉴", 2)
      ])
    ])
  ]))
}
}

})