<template>
    <Header :initType="'type2'" :initTitle="'공지사항'" />
    <div class="wrap notice">
        <ul>
            <li class="flex" v-for=" (item,idx) in state.noticeList" :key="idx" @click="openNotice(item)">
                <div>
                    <div class="flex">
                        <p>[공지]</p>
                        <p class="date">{{item.createDate}}</p>
                    </div>
                    <p class="title">{{item.title}}</p>
                </div>
                <img src="@/assets/images/arrow-right.svg" alt="arrow">
            </li>
        </ul>
    </div>
     <NoticePopup :initIsShow="state.isShowNotice" :initData="state.noticeDetailData" @close ="closeNotice"/>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits,store,axios,onMounted, defineProps, reactive } = useBaseComponent();


let state = reactive({
    noticeList:[] as any,
    noticeDetailData:{} as any,
    isShowNotice:false,
})

onMounted(()=> {
    getNoticeList();
    store.setBeforePage(route.path)
})

const getNoticeList = async() => {
    let res = await axios.axiosMethods.newNoticeList ({
        pageNo:1,
        pageSize:999,
    })
    if(res.returnCode === 0) {
        state.noticeList = res.list;
    }
}

const openNotice = (data:any) => {
    state.isShowNotice = true;
    state.noticeDetailData = data;
}

const closeNotice = () => {
    state.isShowNotice = false;
}

</script>
