<template>
    <Header :initType="'type2'" :initTitle="'개인정보 처리방침'" />
    <div class="wrap terms-area service">
        <div>
            <iframe src="https://eight.epic-net.ai/privacyPolicy.html" id="frame" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})
</script>
