import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/profile-wallet.svg'
import _imports_1 from '@/assets/images/EPUSDT.png'
import _imports_2 from '@/assets/images/ETHUSDT.png'


const _hoisted_1 = { class: "wrap wallet main" }
const _hoisted_2 = { class: "cover" }
const _hoisted_3 = { class: "canvas-box" }
const _hoisted_4 = { class: "container cursor" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "money" }
const _hoisted_7 = { class: "chart flex container" }
const _hoisted_8 = { class: "legend" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "chart-data" }
const _hoisted_12 = { class: "container wallet-ul" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 0,
  src: _imports_1,
  alt: ""
}
const _hoisted_15 = {
  key: 1,
  src: _imports_2,
  alt: ""
}
const _hoisted_16 = { class: "name" }
const _hoisted_17 = { class: "price" }

import { VueSignaturePad } from "@selemondev/vue3-signature-pad"
import useBaseComponent from "@/baseComponent";
import { format } from "path";

export default /*@__PURE__*/_defineComponent({
  __name: 'WalletPage',
  setup(__props) {

const { route, router, axios, store, onMounted, reactive, } = useBaseComponent();


let state = reactive({
    totalPrice:'',
    totalAmount:'',
    totalWalletInfo:[] as any,
});
onMounted(()=> {
    getUserToken();
    store.setBeforePage(route.path)
})

const detailPage = (symbol:any) => {
    router.push( {
        path:'/Detail',
        query:{
            symbol:symbol,
        },
    });
};

const getUserToken = async() => {
    let res =await axios.axiosMethods.userToken({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        symbol:'',
    })
    if(res.returnCode === 0) {
        state.totalWalletInfo = res.list.reverse();
        state.totalPrice = parseFloat(res.list.reduce((sum: any, item: any) => {
            return sum + (parseFloat(item.price) * item.point);
        }, 0)).toFixed(4);
        state.totalWalletInfo.forEach((v:any) => {
            if(state.totalPrice && Number(state.totalPrice) > 0) {
                const itemTotal = parseFloat(v.price) * v.point;
                const percentage = (itemTotal / parseFloat(state.totalPrice)) * 100;
               
                // console.log(state.totalPrice);
                if(isNaN(v.percentage)){
                    v.percentage = percentage.toFixed(2); 
                } else {
                    v.percentage = '0';
                }
                v.requestKrw = itemTotal;
            }
            else {
                v.percentage = '0';
            }
        });
    }
    // console.log('userToken',state.totalWalletInfo)
}

const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}



return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { initType: 'type1' }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title-box flex" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }),
              _createElementVNode("p", null, "총 보유자산")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(formatPrice(_unref(state).totalPrice)), 1)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "won" }, "KRW", -1))
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "cyber" }, null, -1))
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "progress-area" }, [
            _createElementVNode("div", { style: {"width":"100%","background":"rgb(250, 45, 0)"} }),
            _createElementVNode("div", { style: {"width":"0%","background":"rgb(104, 126, 227)"} })
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "chart-cover" }, [
              _createElementVNode("p", { class: "text" }, [
                _createTextVNode("보유자산"),
                _createElementVNode("br"),
                _createTextVNode("포트폴리오")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).totalWalletInfo, (item, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "legend-inner",
                  key: idx
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: "circle",
                      style: _normalizeStyle({background: item.tokenId === 1 ?'rgb(104, 126, 227)': 'rgb(250, 45, 0)'})
                    }, null, 4),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(item.symbol), 1),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(item.percentage), 1)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("ul", _hoisted_12, [
        _cache[5] || (_cache[5] = _createElementVNode("h6", { class: "title" }, "토큰", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).totalWalletInfo, (item, idx) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "flex token",
            onClick: ($event: any) => (detailPage(item.symbol)),
            key: idx
          }, [
            (item.symbol === 'EPIC')
              ? (_openBlock(), _createElementBlock("img", _hoisted_14))
              : _createCommentVNode("", true),
            (item.symbol === 'ETH')
              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_16, _toDisplayString(item.symbol), 1),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(item.point) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(item.symbol), 1)
              ]),
              _createElementVNode("p", null, [
                _createElementVNode("span", null, "= " + _toDisplayString(formatPrice(item.requestKrw)) + " KRW", 1)
              ])
            ])
          ], 8, _hoisted_13))
        }), 128))
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}
}

})