import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/arrow-right.svg'


const _hoisted_1 = { class: "wrap notice" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "title" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'NoticePage',
  setup(__props) {

const { route, router, defineEmits,store,axios,onMounted, defineProps, reactive } = useBaseComponent();


let state = reactive({
    noticeList:[] as any,
    noticeDetailData:{} as any,
    isShowNotice:false,
})

onMounted(()=> {
    getNoticeList();
    store.setBeforePage(route.path)
})

const getNoticeList = async() => {
    let res = await axios.axiosMethods.newNoticeList ({
        pageNo:1,
        pageSize:999,
    })
    if(res.returnCode === 0) {
        state.noticeList = res.list;
    }
}

const openNotice = (data:any) => {
    state.isShowNotice = true;
    state.noticeDetailData = data;
}

const closeNotice = () => {
    state.isShowNotice = false;
}


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_NoticePopup = _resolveComponent("NoticePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: '공지사항'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).noticeList, (item, idx) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "flex",
            key: idx,
            onClick: ($event: any) => (openNotice(item))
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                _cache[0] || (_cache[0] = _createElementVNode("p", null, "[공지]", -1)),
                _createElementVNode("p", _hoisted_4, _toDisplayString(item.createDate), 1)
              ]),
              _createElementVNode("p", _hoisted_5, _toDisplayString(item.title), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_0,
              alt: "arrow"
            }, null, -1))
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _createVNode(_component_NoticePopup, {
      initIsShow: _unref(state).isShowNotice,
      initData: _unref(state).noticeDetailData,
      onClose: closeNotice
    }, null, 8, ["initIsShow", "initData"])
  ], 64))
}
}

})