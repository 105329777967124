<template>
    <Header :initType="'type2'" :initTitle="'비밀번호 변경'" />
    <div class="wrap login info">
        <div class="login-center">
            <div>
                <p>비밀번호</p>
                <input type="password" placeholder="10~16자리 입력" v-model="state.beforePwInput" @input="newCheck"/>
                <div class="error" style="margin: 4px 0px 30px;">
                    {{ state.pwErrorBefore }}
                </div>
                <p>새 비밀번호</p>
                <input type="password" placeholder="10~16자리 입력" v-model="state.pwInput" @input="pwCheck"/>
                <div class="error">
                    {{ state.pwError }}
                </div>
                <p>비밀번호 확인</p>
                <input type="password" placeholder="10~16자리 입력" v-model="state.pwCheckInput"/>
                <div class="error">
                    <p v-if="state.pwCheckInput.length > 0 && state.pwInput !== state.pwCheckInput">비밀번호를 확인해주세요.</p>
                </div>
                <p class="ps first">비밀번호는 영문자, 숫자, 특수문자(~@#$%^*)를 포함하여 10-16자리로 만들어주세요.</p>
                <p class="ps">단, 허용되는 특수문자(~@#$%^*) 외 다른 특수문자는 사용할 수 없습니다.</p>
                <p class="ps">포털 사이트나 타 거래소와 동일하거나 비슷한 암호를 설정하지 마세요. 타 사이트에서 암호가 유출될 경우 제3자가 회원님의 계정에 접근할 위험이 있습니다.</p>
            </div>
        </div>
        <button class="login-btn on" :disabled="state.beforePwInput.length <= 0 || state.pwCheckInput.length <= 0 || state.pwInput !== state.pwCheckInput" @click="changePwTicket">비밀번호 변경</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive,axios,store } = useBaseComponent();


let state = reactive({
    beforePwInput:''as any,
    pwInput:'' as any,
    pwCheckInput:'' as any,
    pwPass:false,
    pwError: ''as any,
    pwErrorBefore:'' as any,
})

const changePwTicket = async() => {
    let res = await axios.axiosMethods.changePwTicket({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        priorPassword:state.beforePwInput,
        newPassword:state.pwCheckInput,
    })
    if(res.returnCode === 0) {
        store.setVisibleDialog({ title: '변경되었습니다.', message: '', okCancel: false, callback: () => { router.go(-1)} })
    } else {
        store.setVisibleDialog({ title: '실패하였습니다.', message: res.description, okCancel: false, callback: () => { } })
    }
}

const pwCheck = () => {
    const pw = state.pwInput;
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^*])[a-zA-Z\d~!@#$%^*]{10,16}$/;
    if (pwPattern.test(pw)) {
        state.pwError = '';
    } else {
        state.pwError ='비밀번호는 10~16자리로, 영문자, 숫자, 특수문자를 모두 포함해야 합니다.';
    }
}

const newCheck = () => {
    const pw = state.beforePwInput;
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^*])[a-zA-Z\d~!@#$%^*]{10,16}$/;
    if (pwPattern.test(pw)) {
        state.pwErrorBefore = '';
    } else {
        state.pwErrorBefore ='비밀번호는 10~16자리로, 영문자, 숫자, 특수문자를 모두 포함해야 합니다.';
    }
}
</script>
