import { createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useStore } from '@/pinia'

//css common
import "vue-awesome-paginate/dist/style.css";
import "@/assets/css/reset.css";
import "swiper/css";
import VueAwesomePaginate from "vue-awesome-paginate";
import "@/assets/css/common.css";
import "@/assets/css/copy.css";
import "@/assets/css/index.css";


//////////////////components///////////////////////
//Chart
// /Pc/
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import NoticePopup from "@/components/popup/NoticePopup.vue";



const app = createApp(App);
const pinia = createPinia();

// const useI18n = i18n();
pinia.use(piniaPluginPersistedstate)
app.use(router);
app.use(pinia);
app.use(VueAwesomePaginate);
// app.use(useI18n);

app.component("Header", Header);
app.component("Footer", Footer);
app.component("NoticePopup", NoticePopup);

app.mount("#app");
