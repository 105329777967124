<template>
    <Header :initType="'type2'" :initTitle="'약관 및 정책'" />
    <div class="wrap terms-area">
        <ul>
            <li class="flex" @click="router.push('/Service')">
                <p>서비스 이용약관</p><img src="@/assets/images/arrow-right.svg" alt="arrow">
            </li>
            <li class="flex" @click="router.push('/Privacy')">
                <p>개인정보 처리방침</p><img src="@/assets/images/arrow-right.svg" alt="arrow">
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})
</script>
