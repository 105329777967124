<template>
    <Header :initType="'type2'" />
    <div class="wrap pin flex">
        <div class="pin-cover">
            <!-- <div v-if="!state.setState">
                <div v-if="state.step === 0">
                    <p class="title">OTP 번호 설정</p>
                    <p class="sub" >{{state.errMessage}}</p>
                </div>
                <div v-else>
                    <p class="title">OTP 번호 확인</p>
                    <p class="sub" :style="{color: state.errMessage === 'OTP 번호가 일치하지 않습니다.'?'yellow': ''}">{{ state. errMessage }}</p>
                </div>
            </div> -->
            <div style="text-align: left; padding-left: 16px;" v-if="store.AccountState.secession">
                <p class="title" >
                    <span>회원탈퇴를 진행합니다.</span>
                </p>
                <!-- <p class="sub" :style="{color: state.errMessage === '비밀번호가 일치하지 않습니다.'?'yellow': ''}">{{ state. errMessage }}</p> -->
            </div>
            <div style="text-align: left; padding-left: 16px;" v-else-if="store.PurchaseState.ready">
                <p class="title" >{{formatWon(store.PurchaseState.amount)}}원으로
                    <span>{{store.PurchaseState.symbolAmount}} {{store.PurchaseState.symbol}}</span>
                    <span>구매신청을 진행합니다.</span>
                </p>
                <!-- <p class="sub" :style="{color: state.errMessage === '비밀번호가 일치하지 않습니다.'?'yellow': ''}">{{ state. errMessage }}</p> -->
            </div>
            <div style="text-align: left; padding-left: 16px;" v-else-if="store.TradeState.ready">
                <p class="title" >받으실 분: {{store.TradeState.to}}
                    <span>{{store.PurchaseState.symbolAmount}} {{store.PurchaseState.symbol}}</span>
                    <span>전송을 진행합니다.</span>
                </p>
                <!-- <p class="sub" :style="{color: state.errMessage === '비밀번호가 일치하지 않습니다.'?'yellow': ''}">{{ state. errMessage }}</p> -->
            </div>
            <div v-else></div>
            <div class="newNumberBox">
                <input class="newNumber" placeholder="비밀번호를 입력해주세요" type="password" v-model="state.newNumber" @keyup.enter="pwConfirm">
                <!-- <ul class="flex">
                    <li>
                        <img src="@/assets/images/dot.svg" alt="" v-if="state.newNumber.length > 0">
                    </li>
                    <li>
                        <img src="@/assets/images/dot.svg" alt="" v-if="state.newNumber.length > 1">
                    </li>
                    <li>
                        <img src="@/assets/images/dot.svg" alt="" v-if="state.newNumber.length > 2">
                    </li>
                    <li>
                        <img src="@/assets/images/dot.svg" alt="" v-if="state.newNumber.length > 3">
                    </li>
                    <li>
                        <img src="@/assets/images/dot.svg" alt="" v-if="state.newNumber.length > 4">
                    </li>
                    <li>
                        <img src="@/assets/images/dot.svg" alt="" v-if="state.newNumber.length > 5">
                    </li>
                </ul> -->
                <!-- <div class="keypad">
                    <div class="row">
                        <div class="col-4 num0" @click="newNumber(state.numberPad[0])"><p>{{ state.numberPad[0] }}</p></div>
                        <div class="col-4 num1" @click="newNumber(state.numberPad[1])"><p>{{ state.numberPad[1] }}</p></div>
                        <div class="col-4 num2" @click="newNumber(state.numberPad[2])"><p>{{ state.numberPad[2] }}</p></div>
                        <div class="col-4 num3" @click="newNumber(state.numberPad[3])"><p>{{ state.numberPad[3] }}</p></div>
                        <div class="col-4 num4" @click="newNumber(state.numberPad[4])"><p>{{ state.numberPad[4] }}</p></div>
                        <div class="col-4 num5" @click="newNumber(state.numberPad[5])"><p>{{ state.numberPad[5] }}</p></div>
                        <div class="col-4 num6" @click="newNumber(state.numberPad[6])"><p>{{ state.numberPad[6] }}</p></div>
                        <div class="col-4 num7" @click="newNumber(state.numberPad[7])"><p>{{ state.numberPad[7] }}</p></div>
                        <div class="col-4 num8" @click="newNumber(state.numberPad[8])"><p>{{ state.numberPad[8] }}</p></div>
                        <div class="col-4 num9"><img src="@/assets/images/fingerprint.svg"></div>
                        <div class="col-4 num10" @click="newNumber(state.numberPad[9])"><p>{{ state.numberPad[9] }}</p></div>
                        <div class="col-4" @click="deleteNumber"><img src="@/assets/images/del.svg" alt="del"></div>
                    </div>
                </div> -->
            </div>
            <button class="login-btn on" @click="pwConfirm">완료</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, store,axios,defineProps, onMounted, reactive,watch ,formatWon} = useBaseComponent();


let state = reactive({
    // setState:false, // 이미있다면 true
    errMessage:'비밀번호를 입력하세요.' as any,
    step:0,
    numberPad:['0','1','2','3','4','5','6','7','8','9'],

    newNumber:'' as any,
    saveNumber:'' as any, 
})

onMounted(()=> {
    // checkOtpSet();
    mixPad();
    // newNumber('');
})


const shuffle = (array:any) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));  
        [array[i], array[j]] = [array[j], array[i]];   
    }
};

const mixPad = () => {
    shuffle(state.numberPad)
}

const pwConfirm = () =>{
    //구매
    if(store.PurchaseState.ready){
        //purchaseCoinDeposit();
    }
    // 전송
    else if(store.TradeState.ready){
        purchaseCoinSend();
    }
    //탈퇴
    else if(store.AccountState.secession) {
        userDelete();
    }
}

const userDelete = async() => {
    let res = await  axios.axiosMethods.userDelete({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        otp:state.newNumber,
    })
    if(res.returnCode === 0) {
        localStorage.clear();
        sessionStorage.clear();
        router.push('/')
    } else if(res.returnCode === 72){
        store.setVisibleDialog({ title: '', message:'이미 탈퇴 처리 되었습니다.',okCancel:false, callback: () => { router.push('/')}})
    } else {
        store.setVisibleDialog({ title: '실패', message:res.description,okCancel:false, callback: () => { router.push('/')}})
    }
} 

// const newNumber = (pin: any) => {
//     if (state.newNumber.length >= 6) {
//         return; 
//     }

//     state.newNumber += pin;

//     if (state.setState) {
//         if (state.newNumber.length === 6) {
//            // 이미설정했고, 6자리입력시 
//            //구매루트
//            if(store.PurchaseState.ready){
//                 purchaseCoinDeposit();
//            }
//            //
//            if(store.TradeState.ready){
//                 purchaseCoinSend();
//            }
//         }
//     } else {
//         if (state.step === 0) {
//             if (state.newNumber.length === 6) {
//                 state.saveNumber = state.newNumber;
//                 state.step = 1; 
//                 state.newNumber = '';
//                 state.errMessage = 'OTP 번호를 다시 입력하세요.';
//             }
//         } else if (state.step === 1) {
//             //재확인
//             if (state.newNumber.length === 6) {
//                 if (state.saveNumber === state.newNumber) {
//                     userOtpSet(state.newNumber);
//                 } else {
//                     state.errMessage = 'OTP 번호가 일치하지 않습니다.'; 
//                     state.newNumber = ''; 
//                 }
//             }
//         }
//     }
// };

// const userOtpSet = async(otp:any) => {
//     let res = axios.axiosMethods.userOtpSet({
//         id:store.AccountState.id,
//         ticketId:store.AccountState.ticketId,
//         data:otp
//     })
//     if(res.returnCode === 0) {
//         router.push(store.RouteState.beforePage)
//     } else {
//         //
//     }
// }

const purchaseCoinSend = async () => {
    let res = await axios.axiosMethods.purchaseCoinSend({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        idx:store.TradeState.withdrawData.idx,
        to:store.TradeState.to,
        otp:state.newNumber,
    })
    if(res.returnCode === 0 ){
        store.setVisibleDialog({ title: '전송', message:`전송이 완료되었습니다.`,okCancel:false, callback: () => {
            store.TradeState.ready = false;
            router.push('/Wallet')
        }})
    } else {
        store.setVisibleDialog({ title: '전송 실패', message:res.description,okCancel:false, callback: () => {}})
    }
}

// const deleteNumber = () => {
//      state.newNumber = state.newNumber.slice(0, -1); 
// };

// const checkOtpSet = () => {
//     if(store.AccountState.userInfo.otp) {
//         state.setState = true;
//     } else {
//         state.setState = false;
//     }
// }
 

</script>
