import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/EPUSDT.png'
import _imports_1 from '@/assets/images/ETHUSDT.png'


const _hoisted_1 = {
  class: "wrap detail",
  style: {"padding":"100px 0 0"}
}
const _hoisted_2 = { class: "cover" }
const _hoisted_3 = {
  key: 0,
  class: "chartBox"
}
const _hoisted_4 = { class: "canvas-box" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "krw" }
const _hoisted_8 = { class: "money" }
const _hoisted_9 = { class: "cyber" }
const _hoisted_10 = { class: "tool-tip" }
const _hoisted_11 = { class: "transaction" }
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = {
  key: 0,
  class: "container",
  style: {"background-color":"transparent"}
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "flex" }
const _hoisted_16 = { class: "flex top" }
const _hoisted_17 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_18 = {
  key: 1,
  src: _imports_1,
  alt: ""
}
const _hoisted_19 = { class: "bottom" }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = { class: "flex" }
const _hoisted_22 = { class: "flex" }
const _hoisted_23 = { class: "flex" }
const _hoisted_24 = {
  key: 1,
  class: "container",
  style: {"background-color":"transparent"}
}
const _hoisted_25 = { class: "flex" }
const _hoisted_26 = { class: "bottom" }
const _hoisted_27 = { class: "flex" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 2 }
const _hoisted_31 = { class: "flex" }
const _hoisted_32 = { class: "flex" }
const _hoisted_33 = { class: "flex" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = {
  key: 0,
  class: "flex"
}
const _hoisted_36 = ["onClick"]

import useBaseComponent from "@/baseComponent";
import { Chart, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend,LineController,PointElement} from 'chart.js';

export default /*@__PURE__*/_defineComponent({
  __name: 'DetailPage',
  setup(__props) {

const { route, router, defineEmits,axios,store,onMounted, defineProps,  reactive, ref,watch,nextTick} = useBaseComponent();

Chart.register(CategoryScale, LinearScale, LineController, LineElement, Title, Tooltip, Legend,PointElement);


const chartCanvas = ref<HTMLCanvasElement | null>(null);

let state = reactive({
    symbol: route.query.symbol,
    tabIdx:0,
    purchaseList:[] as any,
    userLog:[] as any,
    selectedPurchase:'' as any,
    tab0PageData:{ } as any,
    tab1PageData:{ } as any,
    currentPage:1, //pageNo
    
    tokenInfo:[]as any,
    tokenPrice:0 as any,

    totalPrice:'',
    totalPoint:'',
    totalAmount:'',
    totalWalletInfo:[] as any,

    chartData:[] as any,
})

onMounted(() => {
    state.symbol = route.query.symbol;
    store.setWithdrawData('');
    getPurchaseOwner();
    getUserToken();
    getUserLog();
    getUserTokenInfo();
    store.setBeforePage(route.path);
    nextTick(()=> {

        getTokenChart();
    })
})

const getPurchaseOwner = async ()=> {
    let res = await axios.axiosMethods.purchaseCoinOwner({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:999,
        symbol:state.symbol,
    })
    // console.log(res)
    if(res.returnCode === 0){
        state.purchaseList = res.list;
        state.tab0PageData = res.page;
    } else {
        //
    }
}

const getUserLog = async () => {
    let res = await axios.axiosMethods.userLog({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        pageNo:state.currentPage,
        pageSize:5,
        symbol:state.symbol,
    })
    if(res.returnCode === 0) {
        state.userLog = res.list;
        state.tab1PageData = res.page;
    }
}

const selectPurchase = (item:any) => {
    state.selectedPurchase = item;
    store.setWithdrawData(item);
    // 바로 전송으로
    router.push('/Withdraw')
}

const tabChange = (no:any) => {
    state.tabIdx = no;
    state.currentPage = 1;
}

const checkWithdraw = () => {
    if(store.TradeState.withdrawData) {
        router.push('/Withdraw')
    } else {
        store.setVisibleDialog({ title: '아래 전송할 상품을 선택하여주세요.', message: '', okCancel: false, callback: () => { } })
    }
}

const onClickHandler = (pageNo:number) => {
    state.currentPage = pageNo;
    if(state.tabIdx === 0) {
        getPurchaseOwner();
    } else {
        getUserLog();
    }

}

const goHash = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/tx/`+add)
}

const goAddress = (add:string) => {
    if(add) 
    window.open(`https://etherscan.io/address/`+add)
}

const getUserTokenInfo = async() => {
    let res =await axios.axiosMethods.tokenInfo({
        symbol:state.symbol,
    })
    if(res.returnCode === 0) {
        state.tokenInfo = res.data;

        state.tokenPrice = parseFloat(res.data.reduce((sum: any, item: any) => {
            return sum + (parseFloat(item.price));
        }, 0)).toFixed(0);
    }
}

const getUserToken = async() => {
    let res =await axios.axiosMethods.userToken({
        id:store.AccountState.id,
        ticketId:store.AccountState.ticketId,
        symbol:state.symbol,
    })
    if(res.returnCode === 0) {
        state.totalWalletInfo = res.list;
        state.totalPrice = parseFloat(res.list.reduce((sum: any, item: any) => {
            return sum + (parseFloat(item.price) * item.point);
        }, 0)).toFixed(0);
        state.totalWalletInfo.forEach((v:any) => {
            if(state.totalPrice && Number(state.totalPrice) > 0) {
                const itemTotal = parseFloat(v.price) * v.point;
                const percentage = (itemTotal / parseFloat(state.totalPrice)) * 100;
                // console.log(state.totalPrice);
                state.totalPoint = v.point;
                if(isNaN(v.percentage)){
                    v.percentage = percentage.toFixed(2); 
                } else {
                    v.percentage = '0';
                }
                v.requestKrw = itemTotal;
            }
            else {
                v.percentage = '0';
            }
        });
    }
}

const getTokenChart = async () => {
    let res = await axios.axiosMethods.tokenChart({
        symbol: state.symbol,
    });

    if (res.returnCode === 0) {
        let data = res.data[0].chart;
        if( typeof data !== 'object'){
            data= JSON.parse(res.data[0].chart);
            state.chartData = data.c
        }
    }
};


const createChart = () => {
  if (chartCanvas.value) {
    new Chart(chartCanvas.value, {
      type: 'line',
      data: {
        labels: state.chartData.map((_, index:any) => index.toString()), // X축 레이블 예시
        datasets: [
          {
            label: '',
            
            data: state.chartData, // Y축 데이터
            fill: false,
            borderColor: 'rgba(250,45,0, 1)',
            tension: 0.1,
            pointRadius: 0, // 포인트 제거
            pointHoverRadius: 0,
          },
        ],
      },
      options: {
        plugins:{
            legend:{
                display:false,
            }
        },
        responsive: true,  // 반응형 차트
        maintainAspectRatio: false,  // 비율 고정하지 않음
        scales: {
          x: {
            type: 'category', // CategoryScale 사용
            ticks: {
                display:false,
              autoSkip: true,
            },
            grid: {
              color: 'rgba(200, 200, 200, 0.0)', // X축 grid 선 색상 설정
            },
          },
          y: {
            type: 'linear', // LinearScale 사용
            beginAtZero: false,
            ticks: {
              font: {
                size: 11, // Y축 폰트 크기 설정
              },
            },
            grid: {
              color: 'rgba(200, 200, 200, 0.2)', // X축 grid 선 색상 설정
            },
          },
        },
      },
    });
  }
};

watch(() => state.chartData, () => {
  if (state.chartData.length > 0) {
    createChart();
  }
},{deep:true});


const formatPrice = (price: any) => {
  if (isNaN(price)) return ''; 
  return new Intl.NumberFormat().format(price);
}



return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_vue_awesome_paginate = _resolveComponent("vue-awesome-paginate")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type1',
      initTitle: _unref(state).symbol + ' ' + 'Wallet'
    }, null, 8, ["initTitle"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_unref(state).symbol !== 'EPIC')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("canvas", {
                ref_key: "chartCanvas",
                ref: chartCanvas
              }, null, 512)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(state).symbol), 1),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", null, _toDisplayString(formatPrice(_unref(state).totalPoint)), 1)
              ])
            ]),
            _createElementVNode("p", _hoisted_9, [
              _createTextVNode(_toDisplayString(formatPrice(_unref(state).totalPrice)), 1),
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "KRW", -1))
            ])
          ]),
          _createElementVNode("p", _hoisted_10, _toDisplayString(formatPrice(_unref(state).tokenPrice)) + "원", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("h6", {
            class: "title",
            style: _normalizeStyle({color:_unref(state).tabIdx === 0 ? '#fff':''}),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (tabChange(0)))
          }, "전송 가능 상품", 4),
          _createElementVNode("h6", {
            class: "title",
            style: _normalizeStyle({color:_unref(state).tabIdx === 1 ? '#fff':''}),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (tabChange(1)))
          }, "전송 내역", 4)
        ]),
        (_unref(state).tabIdx === 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).purchaseList, (item, idx) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["product", {'selected': item === _unref(state).selectedPurchase}]),
                  key: idx,
                  onClick: ($event: any) => (selectPurchase(item))
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      (item.symbol === 'EPIC')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_17))
                        : _createCommentVNode("", true),
                      (item.symbol === 'ETH')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[5] || (_cache[5] = _createElementVNode("p", null, "전송 가능 수량", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.amountTotal) + " " + _toDisplayString(item.symbol), 1)
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _cache[6] || (_cache[6] = _createElementVNode("p", null, "입금 금액", -1)),
                        _createElementVNode("p", null, _toDisplayString(formatPrice(item.requestKrw)) + " KRW", 1)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[7] || (_cache[7] = _createElementVNode("p", null, "신청일:", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.createDate), 1)
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _cache[8] || (_cache[8] = _createElementVNode("p", null, "승인일:", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.acceptDate ? item.acceptDate : '-'), 1)
                      ])
                    ])
                  ])
                ], 10, _hoisted_14))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("ul", _hoisted_24, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).userLog, (item, idx) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "product",
                  key: idx
                }, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _cache[9] || (_cache[9] = _createElementVNode("p", null, "타입", -1)),
                        (item.type === 21)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_28, "[구매] " + _toDisplayString(formatPrice(item.data3)) + " 원", 1))
                          : (item.type === 22)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_29, "[출금] " + _toDisplayString(item.data3), 1))
                            : (item.type === 23)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_30, "[입금] " + _toDisplayString(item.data3), 1))
                              : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[10] || (_cache[10] = _createElementVNode("p", null, "수량", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.amount) + " " + _toDisplayString(item.symbol), 1)
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _cache[11] || (_cache[11] = _createElementVNode("p", null, "일자", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.createDate), 1)
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _cache[12] || (_cache[12] = _createElementVNode("p", null, "주소", -1)),
                        _createElementVNode("p", {
                          onClick: ($event: any) => (item.data2 ?  goAddress(item.data2):''),
                          style: {"cursor":"pointer","text-decoration":"underline"}
                        }, _toDisplayString(item.data2 ? item.data2.substring(0,16):'') + "...", 9, _hoisted_34)
                      ]),
                      (item.type === 21)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            _cache[13] || (_cache[13] = _createElementVNode("p", null, "Hash", -1)),
                            _createElementVNode("p", {
                              onClick: ($event: any) => (item.data4 ?  goHash(item.data4):''),
                              style: {"cursor":"pointer","text-decoration":"underline"}
                            }, _toDisplayString(item.data4 ? item.data4.substring(0,16):'') + "...", 9, _hoisted_36)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ])),
        (_unref(state).tabIdx === 1)
          ? (_openBlock(), _createBlock(_component_vue_awesome_paginate, {
              key: 2,
              "total-items": _unref(state).tabIdx === 0 ? _unref(state).tab0PageData.totalRecordSize:_unref(state).tab1PageData.totalRecordSize,
              "items-per-page": _unref(state).tabIdx === 0 ? _unref(state).tab0PageData.pageSize:_unref(state).tab1PageData.pageSize,
              "max-pages-shown": 5,
              modelValue: _unref(state).currentPage,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).currentPage) = $event)),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (onClickHandler(_unref(state).currentPage)))
            }, null, 8, ["total-items", "items-per-page", "modelValue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}
}

})