<template>
    <Header :initType="'type2'" :initTitle="'추가정보 변경'" />
    <div class="wrap secession-area flex">
        <div class="box"><img src="@/assets/images/secession.svg" alt="secession">
            <p>회원 탈퇴 시 회원 정보를 비롯한 보유 중인 모든 자산(투자 상품에 가입된 자산을 비롯하여 해지가 진행 중인 상품의 코인 수량 포함)이 삭제됩니다. 삭제된 자산에 대한 책임은 사용자에게
                있으며 삭제된 자산은 복구되지 않는 점 유의하시기 바랍니다. 남은 자산이 있는지 다시 한번 확인하시기 바랍니다.</p>
        </div>
        <button @click="goConfirm">탈퇴하기</button>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits,axios,store, defineProps, onMounted, reactive } = useBaseComponent();


let state = reactive({})

const goConfirm = async() => {
    store.AccountState.secession = true;
    router.push('/Pin');
    // let res = await  axios.axiosMethods.userDelete({
    //     id:store.AccountState.id,
    //     ticketId:store.AccountState.ticketId,
    //     otp:'',
    // })
} 
</script>
