import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrap withdraw login" }
const _hoisted_2 = { class: "login-center" }
const _hoisted_3 = { class: "title" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'WithdrawPage',
  setup(__props) {

const { route, router, defineEmits, axios, store, onMounted, defineProps, reactive } = useBaseComponent();


let state = reactive({
    symbol: '' as any,
    signImg: '' as any,
    data: { } as any,
    addressInput: '' as any
})

onMounted(() => {
    if (!store.TradeState.withdrawData) {
        store.setVisibleDialog({ message: '', title: '잘못된 접근입니다.', okCancel: false, callback: () => { router.push('/') } })
    } else {
        state.data = store.TradeState.withdrawData;
    }

    // console.log(store.TradeState.withdrawData)
    store.setBeforePage(route.path);
})

const goWithdraw = () => {
    if(state.addressInput.length <= 0) {
        store.setVisibleDialog({ message: '', title: '주소 혹은 아이디를 입력해주세요.', okCancel: false, callback: () => { } })
    } else {
        store.TradeState.to = state.addressInput;
        store.TradeState.ready = true;
        router.push('/Pin')
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      initType: 'type2',
      initTitle: `전송 ${_unref(state).data.symbol}`
    }, null, 8, ["initTitle"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_unref(state).data.amountTotal) + " ", 1),
          _createElementVNode("span", null, _toDisplayString(_unref(state).data.symbol), 1),
          _cache[1] || (_cache[1] = _createTextVNode(" Available"))
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "error" }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("h6", null, "받으실 분", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "받으실 분 주소나 아이디를 입력하세요.",
          class: "ad",
          style: {"background":"rgb(45, 45, 45)"},
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).addressInput) = $event))
        }, null, 512), [
          [_vModelText, _unref(state).addressInput]
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ps" }, [
          _createElementVNode("p", null, "전송 전 꼭 알아두세요!"),
          _createElementVNode("p", null, "-본인인증을 한 계정에 한해서 출금이 가능합니다."),
          _createElementVNode("p", null, "- 암호 화폐의 특정상 출근 신청이 완료되면 취소할 없으며, EPIC은 지갑으로만 송금 가능합니다."),
          _createElementVNode("p", null, "- 출금 신청 완료 이후의 과정은 블록체인 네트워크에서 처리되며, 발생할 수 있는 지연 등의 문제는 EPIC에서 처리가 불가능합니다."),
          _createElementVNode("p", null, "- 전송이 완료 되면, 구매했던 상품 지갑의 소유권이 전송받은 수신자로 이전 됩니다.")
        ], -1))
      ]),
      _createElementVNode("button", {
        class: "login-btn on",
        onClick: goWithdraw
      }, "출금하기")
    ])
  ], 64))
}
}

})